import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import cache from 'i18next-localstorage-cache';

import moment from 'moment';
import momentHu from 'moment/locale/hu';
import momentEn from 'moment/locale/en-il';

import en from './locales/en.json';
import hu from './locales/hu.json';
import { DEFAULT_LANGUAGE } from './redux/app/app.utils';

// the translations
const resources = {
  [DEFAULT_LANGUAGE]: {
    translate: en,
    moment: momentEn,
  },
  en: {
    translation: en,
    moment: momentEn,
  },
  hu: {
    translation: hu,
    moment: momentHu,
  },
};

export const countryCodes = {
  en: 'gb',
};

const options = {
  whitelist: ['hu', 'en'],
  resources,
  // lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
};

i18n.on('languageChanged', function (lng) {
  moment.updateLocale(lng, resources[lng].moment);
});

i18n.use(reactI18nextModule).use(detector).use(cache).init(options);

export const locales = Object.keys(options.resources);
export const getCurrentLocale = () => {
  return i18n.languages.find((lng) => locales.indexOf(lng) !== -1);
};

export const isFallbackLanguage = () => {
  return i18n.options.fallbackLng.includes(getCurrentLocale());
};

export default i18n;
