import React from 'react';
import PropTypes from 'prop-types';
import Style from './Button.module.scss';
import Hammer from 'hammerjs';
import { withRouter } from 'react-router-dom';

class Button extends React.Component {
  button = React.createRef();

  componentDidMount() {
    if (this.button.current) {
      this.manager = new Hammer.Manager(this.button.current);
      const LongTap = new Hammer.Press({
        event: 'long_tap',
        taps: 1,
        time: 500,
      });

      this.manager.add(LongTap);
      this.manager.on('long_tap', (...props) => this.props.onLongTap(...props));
    }
  }

  onClick(...props) {
    if (this.props.linkTo) {
      this.props.history.push(this.props.linkTo);
    }

    this.props.onClick(...props);
  }

  onDoubleClick(...props) {
    if (this.props.linkTo) {
      this.props.history.push(this.props.linkTo);
    }

    this.props.onDoubleClick(...props);
  }

  render() {
    const clicks = {};
    if (this.props.onClick) {
      clicks.onClick = (...props) => this.onClick(...props);
    } else if (this.props.onDoubleClick) {
      clicks.onDoubleClick = (...props) => this.onDoubleClick(...props);
    }
    return (
      <button
        {...clicks}
        onBlur={(...props) => this.props.onBlur(...props)}
        ref={this.props.forwardRef || this.button}
        disabled={this.props.disabled}
        className={`${Style.Button} ${
          this.props.fakeDisabled ? Style.disabled : ''
        } ${this.props.className}`}
      >
        {this.props.children}
      </button>
    );
  }
}

export default withRouter(Button);

Button.defaultProps = {
  fakeDisabled: false,
  disabled: false,
  onLongTap: () => {},
  onBlur: () => {},
};

Button.propTypes = {
  history: PropTypes.object,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onLongTap: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  fakeDisabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  forwardRef: PropTypes.object,
};
