import React from 'react';
import PropTypes from 'prop-types';
import Style from './Chargers.module.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectChargers,
  selectIsChargersLoading,
} from '../redux/app/app.selectors';
import { fetchChargersStart } from '../redux/app/app.actions';
import Map from './Map';
import { Link } from 'react-router-dom';
import Loader from './Loader';
import BatteryIcon from './BatteryIcon';
import { translate } from 'react-i18next';
import LabeledItem from './LabeledItem';
import { isEmpty } from 'lodash';

class Chargers extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { chargers } = this.props;
    return (
      <div className={Style.Main}>
        {this.props.isLoading ? (
          <Loader label="Loading" />
        ) : (
          <div className={Style.list}>
            {chargers && chargers.length
              ? chargers.map((charger, index) => {
                  const weatherData = charger.weatherData;
                  const hasWeather =
                    !isEmpty(weatherData) && weatherData.dayZeroIcon;

                  return (
                    <Link
                      key={`Link${index}`}
                      to={`/${charger.id}`}
                      className={`${Style.charger} ${
                        !!charger.charging ? Style.used : ''
                      }`}
                      title={!!charger.charging ? translate('In use') : ''}
                    >
                      <div className={Style.title}>
                        {charger.name}

                        {!!charger.charging ? (
                          <BatteryIcon soc={55} className={Style.icon} />
                        ) : null}

                        {hasWeather ? (
                          <LabeledItem
                            forwardRef={this.weatherRef}
                            className={`${Style.iconPack}`}
                            iconClassName={Style.icon}
                            icon={`Weather${weatherData.dayZeroIcon}`}
                            label="{{unit}} {{value}}"
                            variables={{
                              unit: 'C°',
                              value: weatherData.dayZeroTemperature,
                            }}
                          />
                        ) : null}
                      </div>
                      <div className={Style.map}>
                        <Map
                          lat={charger.location.lat}
                          lng={charger.location.lng}
                          navigateTo
                        />
                      </div>
                    </Link>
                  );
                })
              : null}
          </div>
        )}
      </div>
    );
  }
}

Chargers.propTypes = {
  fetch: PropTypes.func,
  chargers: PropTypes.array,
  isLoading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsChargersLoading,
  chargers: selectChargers,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (hidden = false) => dispatch(fetchChargersStart(hidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chargers);
