import PropTypes from 'prop-types';
import Icon from './Icon';
import Style from './Loader.module.scss';

export function Loader({
  label,
  hidden = false,
  className = '',
  iconClassName = '',
}) {
  return hidden ? null : (
    <div className={`${Style.loader} ${className}`}>
      <span className={Style.title}>{label}</span>
      <Icon
        icon="RiLoader3Line"
        className={`${Style.loaderIcon} ${iconClassName}`}
      />
    </div>
  );
}

Loader.propTypes = {
  label: PropTypes.string,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default Loader;
