import React from 'react';
import PropTypes from 'prop-types';
import Style from './Header.module.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectCharger, selectMode } from '../redux/app/app.selectors';
import { Link, withRouter } from 'react-router-dom';
import Icon from './Icon';
import { isEmpty } from 'lodash';
import Flags from 'country-flag-icons/react/1x1';
import i18n, { getCurrentLocale, locales, countryCodes } from '../i18n';
import LocalizedText, { translate } from './LocalizedText';
import { isTrue, moment } from '../utils';
import { setDefaultLang } from '../redux/user/user.actions';
import { selectDefaultLang } from '../redux/user/user.selectors';
import { SITE_URL } from '../redux/app/app.utils';

class Header extends React.Component {
  header = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.scroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scroll);
  }

  componentDidUpdate() {
    if (
      this.isChargerPage() &&
      !isEmpty(this.props.charger) &&
      this.props.charger.name
    ) {
      document.title = `${this.props.charger.name} | ${translate(
        'EV chargers'
      )}`;
    } else {
      document.title = `${translate('Available chargers')} | ${translate(
        'EV chargers'
      )}`;
    }
  }

  isChargerPage() {
    return this.props.location.pathname !== '/' && !isEmpty(this.props.charger);
  }

  scroll = (e) => {
    if (this.header.current) {
      if (window.scrollY < 10) {
        this.header.current.classList.remove(Style.fixed);
      } else {
        this.header.current.classList.add(Style.fixed);
      }
    }
  };

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng, () => {
      if (lng !== this.props.defaultLang) {
        this.props.setLanguage(lng);
      }
    });
  };

  render() {
    const { charger, mode } = this.props;

    let statusClass = '';
    if (this.isChargerPage() && !!charger.temperatureStatus) {
      statusClass = Style[charger.temperatureStatus.replace(/[^A-Za-z]/, '')];
    }

    return (
      <>
        {/* {this.isChargerPage() && !!charger.temperatureStatus && charger.temperatureStatus !== 'Normal' ? (
          <div className={`temperatureStatus ${Style.temperatureStatus} ${Style[charger.temperatureStatus.replace(/[^A-Za-z]/, '')]}`}>
            <LocalizedText children='Charger temperatureStatus {{temp}}'
              variables={{ temp: translate(charger.temperatureStatus) }} />
            
          </div>
        ) : null} */}
        {mode === 'debug' ? (
          <div className={`debugDiv ${Style.debugDiv}`}>
            <LocalizedText>Running in demo mode</LocalizedText>
          </div>
        ) : this.isChargerPage() &&
          charger.devices &&
          charger.devices.length ? (
          <div className={`debugDiv ${Style.debugDiv}`}>
            {charger.devices.map((device) => {
              return (
                <span className={Style.debugItem} key={`device-${device.id}`}>
                  <a
                    className={Style.debugItemLabel}
                    href={`${SITE_URL}api/redirectToDevice/${device.name}?chargerId=${this.props.charger.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translate(device.name)}
                  </a>
                  :
                  {/* {device.active === '2' ? device.secondaryWifi : device.primaryWifi} */}
                  ({translate(isTrue(device.online) ? 'Online' : 'Offline')},{' '}
                  {translate('{{day}} {{month}} {{time}}', {
                    day: moment(device.lastReboot).format('Do'),
                    month: moment(device.lastReboot).format('MMMM'),
                    time: moment(device.lastReboot).format('HH:mm'),
                  })}
                  )
                </span>
              );
            })}
          </div>
        ) : null}
        <div className={`Header ${Style.Header}`} ref={this.header}>
          {this.props.location.pathname !== '/' &&
          !isEmpty(charger) &&
          charger.temperature ? (
            <div className={`${Style.globalStatus} ${statusClass}`}>
              <LocalizedText
                tag="span"
                children="{{unit}} {{value}}"
                variables={{
                  unit: 'C°',
                  value: charger.temperature,
                }}
              />
            </div>
          ) : null}
          {this.props.location.pathname !== '/' &&
          !isEmpty(charger) &&
          charger.name ? (
            <Link className={Style.back} to="/">
              <Icon icon="IoArrowBackOutline" />
            </Link>
          ) : null}
          <img src="/charging.svg" alt="Application icon" />
          {this.props.location.pathname !== '/' &&
          !isEmpty(charger) &&
          charger.name
            ? charger.name
            : translate('Available chargers')}
          <div className={Style.langs}>
            {locales.map((lng) => {
              const Flag = Flags[(countryCodes[lng] || lng).toUpperCase()];

              return (
                <button
                  key={lng}
                  onClick={() => this.changeLanguage(lng)}
                  className={Style.flagButton}
                  disabled={getCurrentLocale() === lng}
                >
                  <Flag
                    title={translate(`language.${lng}`)}
                    className={Style.flag}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

Header.propTypes = {
  charger: PropTypes.object,
  location: PropTypes.object,
  mode: PropTypes.string,
  setLanguage: PropTypes.func,
  defaultLang: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  charger: selectCharger,
  mode: selectMode,
  defaultLang: selectDefaultLang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lng) => dispatch(setDefaultLang(lng)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
