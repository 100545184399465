import { createSelector } from 'reselect';
import { selectCombinedState } from '../root-selector';

const selectUser = selectCombinedState('user');

export const selectUserState = createSelector([selectUser], (user) => user);

export const selectLoadingState = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const selectUserProfile = createSelector(
  [selectUser],
  (user) => user.userProfile
);

export const selectEditProfile = createSelector(
  [selectUser],
  (user) => user.editProfile
);

export const selectUserToken = createSelector(
  [selectUserProfile],
  (profile) => profile.token
);

export const selectDefaultCar = createSelector(
  [selectUserProfile],
  (profile) => profile.carId
);

export const selectLastSOC = createSelector(
  [selectUserProfile],
  (profile) => profile.carSOC
);

export const selectDefaultLang = createSelector(
  [selectUserProfile],
  (profile) => profile.lang
);

export const selectDeviceLang = createSelector(
  [selectUser],
  (user) => user.lang
);

export const selectIsTokenExpired = createSelector(
  [selectUserProfile],
  (profile) => profile.expires <= Date.now() / 1000
);

export const selectIsUserLoggedIn = createSelector(
  [selectUser, selectIsTokenExpired],
  (user, expired) => user.isLoggedIn && !expired
);
