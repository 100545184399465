import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Cell,
  LabelList,
} from 'recharts';

import {
  abbrConsumption,
  chartColors,
  colors,
  getDoc,
  isMobileWidth,
  rangeLabels,
  getPolygons,
  getSvg,
  moment,
  inRange,
} from '../../utils';

import { find, findKey, get, maxBy, sumBy, range as lRange } from 'lodash';
import { CustomTooltip } from './CustomTooltip';
import ComboFlyout from '../../components/ComboFlyout';
import CommonChart from './CommonChart';

export default class CalendarBar extends CommonChart {
  container = 'calendar';

  getOpacity(index) {
    return 1 - index / (this.state.keys.length * 2);
  }

  prepare(isActive = false, givenRange = null, givenZoomedRange = null) {
    if (this.props.data.length) {
      const filter = this.props.filter;
      const givenData = this.props.data.map((stat) => getDoc(stat));
      const data = [];
      const labels = {};
      const labelValues = {};

      // fake
      // const givenData = docata.reduce((acc, curr) => {
      //   const prev2020 = { ...curr };
      //   const prev2019 = { ...curr };

      //   prev2019.actual = moment(prev2020.actual * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.started = moment(prev2020.started * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.ended = moment(prev2020.ended * 1000)
      //     .year(2019)
      //     .subtract(1, 'days')
      //     .unix();
      //   prev2020.actual = moment(prev2020.actual * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.started = moment(prev2020.started * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.ended = moment(prev2020.ended * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   return [...acc, curr, prev2019, prev2020];
      //   // return [...acc, curr];
      // }, []);

      givenData.sort((a, b) => {
        if (a.started < b.started) {
          return 1;
        }
        if (a.started > b.started) {
          return -1;
        }
        return 0;
      });

      let currentMonth = findKey(filter[this.container], (item) => !!item);
      const year = this.getYear();
      const range = givenRange || this.getRange();
      const zoomedRange = givenZoomedRange || range;
      const firstChargingDay = moment(
        givenData[givenData.length - 1].started * 1000
      ).startOf(zoomedRange);
      const lastChargingDay = moment(givenData[0].started * 1000).endOf(
        zoomedRange
      );

      const diffDays =
        lastChargingDay.diff(firstChargingDay, this.ranges[zoomedRange][0]) + 1;

      const diffYears = [
        firstChargingDay.format('YYYY'),
        lastChargingDay.format('YYYY'),
      ];

      currentMonth =
        currentMonth ||
        (`${year}` !== diffYears[1] ? `${year}/12/31` : Date.now());

      if (moment(currentMonth).endOf(range).diff(lastChargingDay, 'days') > 0) {
        currentMonth = lastChargingDay.clone();
      }

      const firstDay = moment(currentMonth)
        .year(year)
        .startOf(range === 'all' ? 'year' : range);
      const lastDay = moment(currentMonth)
        .year(year)
        .endOf(range === 'all' ? 'year' : range);

      const chargingRange = moment.range(firstDay, lastDay);

      const years = lRange(diffYears[0], parseInt(diffYears[1], 10) + 1);

      for (let i = 0; i < diffDays; i++) {
        const name = firstChargingDay.local();

        if (name.format('YYYY') === `${year}`) {
          const label = moment(name).startOf(zoomedRange).format('YYYY/MM/DD');

          labelValues[label] = translate(rangeLabels[range], {
            year: name.format('YYYY'),
            month: name.format('MMMM'),
            week: name.isoWeek(),
            quarter: name.quarter(),
          });

          labels[label] =
            labels[label] ||
            (filter[this.container] &&
            filter[this.container][label] !== undefined
              ? filter[this.container][label]
              : false);

          const colorIndex =
            Object.keys(labels).indexOf(label) % chartColors.length;
          const current = {
            name: label,
            exactName:
              this.ranges[zoomedRange][0] === 'days'
                ? name.format('YYYY/MM/DD')
                : // eslint-disable-next-line max-len
                  `${moment(name)
                    .startOf(this.ranges[zoomedRange][1])
                    .format('YYYY/MM/DD')}-${moment(name)
                    .endOf(this.ranges[zoomedRange][1])
                    .format('YYYY/MM/DD')}`,
            highLightColor: chartColors[colorIndex],
            highLightSolarColor: colors.darkGreen,
            highLightPattern: `url(#multiDay-${colorIndex})`,
            highLightSolarPattern: 'url(#solar)',
            stacks: [],
            payload: [],
            hidden: true,
          };

          if (chargingRange.contains(firstChargingDay)) {
            current.hidden = false;
          }

          data.push(current);
        }
        firstChargingDay.add(1, this.ranges[zoomedRange][0]);
      }

      const keys = [];
      givenData.reverse().forEach((item) => {
        const { consumption, reactiveConsumption, actual } = item;
        const name = moment(actual * 1000)
          .local()
          .format('YYYY/MM/DD');

        const current = find(data, inRange(name));

        if (current) {
          this.addItemToStack(
            current,
            item,
            { consumption, reactiveConsumption },
            keys,
            this.ranges[zoomedRange][0] !== 'days'
          );

          // if(consumption > reactiveConsumption) {
          //   this.addItemToStack(current, {
          //     ...item,
          //     consumption: 0,
          //     reactiveConsumption: consumption - reactiveConsumption,
          //     isPlaceHolder: true,
          //   }, {
          //     consumption: 0,
          //     reactiveConsumption: consumption - reactiveConsumption,
          //   }, keys, true);
          // }
        }
      });

      if (isActive || !this.state.rendered) {
        this.props.onHoverChart(this.container, data, range);
      }

      this.setState({
        data,
        keys,
        range,
        years,
        year: year || years[years.length - 1],
        zoomedRange,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  addItemToStack(
    datum,
    item,
    { consumption, reactiveConsumption },
    keys = [],
    avoidStack = false
  ) {
    const newIndex = avoidStack ? 0 : datum.stacks.length;
    const dataKey = `c${newIndex}`;

    if (!keys.includes(dataKey)) {
      keys.push(dataKey);
    }

    datum.stacks.push(dataKey);
    datum[dataKey] = (datum[dataKey] || 0) + consumption;
    datum[`r${dataKey}`] = (datum[`r${dataKey}`] || 0) + reactiveConsumption;

    item.dataKey = `${item.isPlaceHolder ? 'p' : ''}${dataKey}`;
    item.dataIndex = newIndex;

    datum.payload.push(item);
  }

  // zoomIn() {
  //   const { range, zoomedRange } = this.state;

  //   const ranges = Object.keys(this.ranges);
  //   const currentRange = ranges.indexOf(zoomedRange);

  //   if(this.ranges[range][0] !== 'days' && this.ranges[zoomedRange][0] !== 'days') {
  //     this.prepare(true, range, ranges[currentRange - 1]);
  //   }
  // }

  // zoomOut() {
  //   const { range, zoomedRange } = this.state;

  //   const ranges = Object.keys(this.ranges);
  //   const currentZoomedRange = ranges.indexOf(zoomedRange);
  //   const currentRange = ranges.indexOf(range);

  //   if(currentRange > currentZoomedRange) {
  //     this.prepare(true, range, range);
  //   }
  // }

  // onLongClick() {
  //   this.zoomIn();
  // }

  // onDoubleClick() {
  //   this.zoomOut();
  // }

  render() {
    const data = this.state.data.filter((datum) => !datum.hidden);
    const max = maxBy(data, (datum) => {
      return datum.payload.reduce((accu, curr) => accu + curr.consumption, 0);
    });
    const maxValue =
      max && max.payload
        ? max.payload.reduce((accu, curr) => {
            return accu + curr.consumption;
          }, 0)
        : 0;

    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">
                Charged energy/used green energy
              </LocalizedText>
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                disabled={this.state.years?.length <= 1}
                id="range"
                label={translate('Year')}
                value={this.state.year}
                onChange={(e) => this.onYearChange(e)}
                options={this.state.years}
              />
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                id="range"
                label={translate('Range')}
                value={this.state.range}
                onChange={(e) => this.onRangeChange(e)}
                options={{
                  isoWeek: translate('Weekly'),
                  month: translate('Monthly'),
                  quarter: translate('Quarterly'),
                  year: translate('Yearly'),
                  all: translate('All'),
                }}
              />
            </h3>
            <ResponsiveContainer
              className={`${Style.responsiveContainer} ${Style.calendarBar}`}
            >
              <BarChart
                className={Style.mainChart}
                width="80%"
                height="100%"
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
              >
                <defs>
                  <pattern
                    id="solar"
                    x="0"
                    y="0"
                    patternUnits="userSpaceOnUse"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    {getPolygons(colors.darkGreen)}
                  </pattern>
                  {chartColors.map((color, cIndex) => (
                    <pattern
                      key={cIndex}
                      id={`multiDay-${cIndex}`}
                      x="0"
                      y="0"
                      patternUnits="userSpaceOnUse"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      {getPolygons(color)}
                    </pattern>
                  ))}
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="exactName" />
                <YAxis
                  hide={isMobileWidth()}
                  tickFormatter={(value) => {
                    return abbrConsumption(value > 0 ? value : 0);
                  }}
                  axisLine={false}
                  tickLine={false}
                  orientation="left"
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label) =>
                    translate('Charged energy/used green energy {{label}}', {
                      label,
                    })
                  }
                  content={
                    <CustomTooltip
                      prependPayload={[
                        {
                          name: 'Total',
                          validator: (payload) => {
                            return payload && payload.length; // > 1;
                          },
                        },
                      ]}
                    />
                  }
                  itemStyle={{ textAlign: 'left' }}
                  formatter={(value, name, entry) => {
                    if (name === 'Total') {
                      const total = sumBy(
                        entry.payload.filter(
                          (item) => item.name.indexOf('c') === 0
                        ),
                        'value'
                      );
                      const reactiveTotal = sumBy(
                        entry.payload.filter(
                          (item) => item.name.indexOf('r') === 0
                        ),
                        'value'
                      );
                      return [
                        `${abbrConsumption(total)}${
                          reactiveTotal > 0
                            ? ` / ${abbrConsumption(reactiveTotal)}`
                            : ''
                        }`,
                        translate(name),
                      ];
                    } else if (this.ranges[this.state.range][0] === 'days') {
                      const entryPayload = find(
                        get(entry, 'payload.payload'),
                        (payload) => {
                          return payload.dataKey === name;
                        }
                      );

                      if (entryPayload) {
                        const subValue =
                          entryPayload.reactiveConsumption > 0
                            ? abbrConsumption(entryPayload.reactiveConsumption)
                            : '';
                        const subReactiveTotal =
                          entryPayload.reactiveTotal > 0 &&
                          entryPayload.reactiveTotal !==
                            entryPayload.reactiveConsumption
                            ? `(${abbrConsumption(entryPayload.reactiveTotal)})`
                            : '';
                        const subTotal =
                          entryPayload.total !== value
                            ? ` (${abbrConsumption(entryPayload.total)})`
                            : '';

                        const icons = {
                          pattern: getSvg(
                            entry.payload.highLightColor,
                            true,
                            this.getOpacity(entryPayload.dataIndex)
                          ),
                          color: getSvg(
                            entry.payload.highLightColor,
                            false,
                            this.getOpacity(entryPayload.dataIndex)
                          ),
                          solarPattern: getSvg(
                            entry.payload.highLightSolarColor,
                            true,
                            this.getOpacity(entryPayload.dataIndex)
                          ),
                          solarColor: getSvg(
                            entry.payload.highLightSolarColor,
                            false,
                            this.getOpacity(entryPayload.dataIndex)
                          ),
                        };

                        return [
                          <>
                            {entryPayload.notOverday
                              ? icons.pattern
                              : icons.color}
                            {abbrConsumption(value)}
                            <b>{subTotal}</b>
                            {subValue !== ''
                              ? [
                                  ' / ',
                                  entryPayload.notOverday
                                    ? icons.solarPattern
                                    : icons.solarColor,
                                  <span key="subvalue">
                                    {subValue}
                                    <b>{subReactiveTotal}</b>
                                  </span>,
                                ]
                              : ''}
                          </>,
                          // eslint-disable-next-line max-len
                          `${moment(entryPayload.started * 1000)
                            .local()
                            .format('MM/DD HH:mm')} - ${moment(
                            entryPayload.ended * 1000
                          )
                            .local()
                            .format('MM/DD HH:mm')}`,
                        ];
                      }
                    }

                    return [];
                  }}
                />
                {!['all', 'year'].includes(this.state.range) && (
                  <Legend
                    iconSize={10}
                    layout="vertical"
                    verticalAlign="bottom"
                    wrapperStyle={{
                      display: 'inline-block',
                      margin: '1em auto auto',
                      fontWeight: 'bold',
                      fontSize: '1.2em',
                      cursor: 'pointer',
                    }}
                    payload={Object.entries(this.state.labelValues || {}).map(
                      (entry, index) => ({
                        label: entry[1],
                        value: entry[0],
                        type: 'square',
                        color: chartColors[index % chartColors.length],
                        container: this.container,
                      })
                    )}
                    onClick={(o) =>
                      this.props.onClick(o, this.state.labels, true)
                    }
                    // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                    formatter={(value, entry) => {
                      const { color, container, label } = entry;
                      return (
                        <span
                          className={`${Style.legend} legendClick`}
                          style={{
                            color,
                            opacity: !this.props.isChecked(
                              value,
                              container,
                              true,
                              this.state.labelValues
                            )
                              ? 0.3
                              : 1,
                          }}
                        >
                          {label}
                        </span>
                      );
                    }}
                  />
                )}
                {this.state.keys.map((key, index) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId="consumption"
                    opacity={this.getOpacity(index)}
                    cursor="pointer"
                  >
                    <LabelList
                      dataKey={key}
                      position="center"
                      angle="-90"
                      fill={colors.ownWhite}
                      stroke={colors.darkGrey}
                      strokeWidth={3}
                      paintOrder="stroke"
                      formatter={(value, entry) => {
                        if (
                          !value ||
                          value < maxValue / (isMobileWidth() ? 3 : 3.5)
                        ) {
                          return '';
                        }

                        return abbrConsumption(value);
                      }}
                    />
                    {data.map((datum, cellIndex) => {
                      const notOverday = get(
                        datum,
                        `payload.${index}.notOverday`
                      );
                      const isPlaceHolder = get(
                        datum,
                        `payload.${index}.isPlaceHolder`
                      );

                      return (
                        <Cell
                          key={`cell-${cellIndex}`}
                          fill={
                            isPlaceHolder
                              ? 'transparent'
                              : notOverday &&
                                this.ranges[this.state.range][0] === 'days'
                              ? datum.highLightPattern
                              : datum.highLightColor
                          }
                        />
                      );
                    })}
                  </Bar>
                ))}
                {this.props.green
                  ? this.state.keys.map((key, index) => (
                      <Bar
                        key={`r${key}`}
                        dataKey={`r${key}`}
                        stackId="reactiveConsumption"
                        opacity={this.getOpacity(index)}
                        cursor="pointer"
                      >
                        <LabelList
                          // dataKey={`r${key}`}
                          position="center"
                          angle="-90"
                          fill={colors.ownWhite}
                          stroke={colors.darkGrey}
                          strokeWidth={3}
                          paintOrder="stroke"
                          valueAccessor={(entry, index) => {
                            const rDataKey = `r${key}`;
                            const pDataKey = `p${key}`;
                            const foundPlaceHolder = entry.payload.payload.find(
                              (item) => item.dataKey === pDataKey
                            );
                            if (foundPlaceHolder) {
                              return '';
                            }

                            return entry[rDataKey];
                          }}
                          formatter={(value) => {
                            if (
                              !value ||
                              value < maxValue / (isMobileWidth() ? 3 : 3.5)
                            ) {
                              return '';
                            }

                            return abbrConsumption(value);
                          }}
                        />
                        {data.map((datum, cellIndex) => {
                          const notOverday = get(
                            datum,
                            `payload.${index}.notOverday`
                          );
                          const isPlaceHolder = get(
                            datum,
                            `payload.${index}.isPlaceHolder`
                          );

                          return (
                            <Cell
                              key={`cell-${cellIndex}`}
                              fill={
                                isPlaceHolder
                                  ? 'transparent'
                                  : notOverday &&
                                    this.ranges[this.state.range][0] === 'days'
                                  ? datum.highLightSolarPattern
                                  : datum.highLightSolarColor
                              }
                            />
                          );
                        })}
                      </Bar>
                    ))
                  : null}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

CalendarBar.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  onRangeChange: () => {},
  isChecked: () => false,
};

CalendarBar.propTypes = {
  containerClassName: PropTypes.string,
  green: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  // onHover: PropTypes.func,
  onHoverChart: PropTypes.func,
  onRangeChange: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
  range: PropTypes.object,
};
