export const selectCombinedState = (key) => (state) => {
  const temporary = {};
  const permanent = state[`${key}Permanent`] || {};

  Object.entries(state[key] || {}, (entry) => {
    const [prop, val] = entry;

    if (val !== undefined) {
      temporary[prop] = val;
    }
  });

  return {
    ...permanent,
    ...temporary,
  };
};
