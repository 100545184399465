import React from 'react';
import PropTypes from 'prop-types';
import Style from './Charging.module.scss';
import Icon, { Gradient } from './Icon';
import { get, isEqual, min, meanBy } from 'lodash';
import Chart from '../containers/charts/Charging';
import moment from 'moment';
import BatteryIcon from './BatteryIcon';
import { removeHistoryStart } from '../redux/app/app.actions';
import { connect } from 'react-redux';
import ConfirmationDialog from './ConfirmationDialog';
import {
  abbrConsumption,
  abbrPower,
  abbrVoltage,
  abbrCurrent,
  isTrue,
  colors,
  scheduledOptions,
  isEmptyOrNull,
  parseScheduledKey,
} from '../utils';
import LocalizedText from './LocalizedText';
import LabeledItem from './LabeledItem';
import Car from './Car';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DEFAULT_CAR_CAPACITY } from '../redux/app/app.utils';
// import { SITE_URL } from '../redux/app/app.utils';

class Charging extends React.Component {
  duration = new React.createRef();
  durationScheduled = new React.createRef();
  durationEstimated = new React.createRef();

  state = {
    open: false,
    shownEstimated: 'none',
    dialog: {},
  };
  estimated = null;

  componentDidMount() {
    this.setTimer();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setTimer();
    }
  }

  setTimer() {
    const { isHistoryItem } = this.props;

    if (!this.timerTick && !isHistoryItem) {
      this.timerTick = setInterval(() => {
        const { charging } = this.props;

        if (charging) {
          const started = moment(charging && charging.started).utc();
          const stopped = moment(
            (charging && charging.stopped) || new Date()
          ).utc();
          const scheduledEnd = moment(charging && charging.scheduledEnd).utc();
          const lastData =
            charging.data && charging.data.length
              ? charging.data[charging.data.length - 1]
              : {};
          const maxConsumption = this.getMaxConsumption(charging, lastData);
          let estimated = null;

          if (maxConsumption && lastData.power > 0) {
            estimated = this.getEstimatedTime(
              charging,
              lastData.consumption,
              maxConsumption,
              lastData.power
            );
          }

          if (this.duration.current) {
            this.duration.current.innerHTML = moment(
              moment(stopped).utc().diff(started)
            )
              .utc()
              .format('HH:mm:ss');
          }

          if (this.durationScheduled.current) {
            this.durationScheduled.current.innerHTML = moment(
              moment(scheduledEnd).utc().diff(stopped)
            )
              .utc()
              .format('HH:mm:ss');
          }

          if (this.durationEstimated.current && estimated !== null) {
            if (estimated === false) {
            } else {
              this.estimated = estimated.utc().format('HH:mm:ss');
              if (estimated.utc().format('HH:mm:ss') !== this.lastEstimated) {
                this.estimatedCount = Date.now();
              }

              this.lastEstimated = this.estimated;
              this.durationEstimated.current.innerHTML = estimated
                .utc()
                .add(this.estimatedCount - Date.now(), 'milliseconds')
                .format('HH:mm:ss');

              if (this.state.shownEstimated !== 'flex') {
                this.setState({ shownEstimated: 'flex' });
              }
            }
          }
        }
      }, 1000);
    }
  }

  getCarCapacity(charging, defaultCapacity = null) {
    return charging.startingSOC > 0
      ? charging.car && charging.car.capacity
      : defaultCapacity;
  }

  getMaxConsumption(charging) {
    const consumptionLimit = charging.consumptionLimit;
    const capacity = this.getCarCapacity(charging);

    if (consumptionLimit && capacity) {
      return min([consumptionLimit, capacity]);
    }

    return consumptionLimit || capacity;
  }

  getEstimatedTime(charging, last, max, power) {
    const starting = charging.startingSOC;
    const ending = charging.endingSOC;
    const capacity = this.getCarCapacity(charging, DEFAULT_CAR_CAPACITY);
    const all = max && max < capacity ? max : capacity;
    const leftover =
      all * (1 - (starting ?? 0) / 100) -
      all * (1 - (ending ?? 0) / 100) -
      last;

    if (leftover < 0) {
      return false;
    }

    const estimated = moment(new Date(0)).add(
      (leftover / power) * 3600,
      'seconds'
    );

    return estimated;
  }

  componentWillUnmount() {
    clearInterval(this.timerTick);
  }

  onRemove(id) {
    this.props.remove(id);
    this.setState({ open: false, [`notification${id}`]: true });
  }

  onShowShareDialog(open, id, text) {
    this.setState({
      open: !!open,
      dialog: !!open
        ? {
            title: 'Info',
            cancelText: 'Ok',
            text: 'Share link copied to clipboard',
            cancel: () => this.onShowShareDialog(false),
          }
        : {},
    });
  }

  onShowRemoveDialog(open, id) {
    this.setState({
      open: !!open,
      dialog: !!open
        ? {
            text: 'Are you sure you want to remove?',
            confirm: () => this.onRemove(id),
            cancel: () => this.onShowRemoveDialog(false),
          }
        : {},
    });
  }

  render() {
    const {
      eligibleToStop,
      isRequested,
      charging,
      isHistoryItem,
      isOpened,
      isAdmin,
      onOpenChart,
      solar,
      solarBoost,
      isShared,
    } = this.props;

    if (
      (eligibleToStop === false && !isShared) ||
      isRequested === true ||
      isEmptyOrNull(charging)
    ) {
      return null;
    }

    const lastData =
      charging.data && charging.data.length
        ? charging.data[charging.data.length - 1]
        : {};
    const greenBoosted =
      isTrue(solar) && solarBoost > 0 && get(charging, 'data.length')
        ? !!charging.data.find((datum) => isTrue(datum.boosted))
        : false;
    const averageVoltage =
      meanBy(charging.data, (n) => parseFloat(n.voltage, 10)) || 0;
    const averagePower =
      meanBy(charging.data, (n) => parseFloat(n.power, 10)) || 0;
    const scheduled =
      parseScheduledKey(get(charging, 'scheduled')) ??
      moment(get(charging, 'scheduled')).utc();
    const paused =
      get(charging, 'pausedUntil') === '0000-00-00 00:00:00'
        ? true
        : get(charging, 'pausedUntil') && moment(charging.pausedUntil).utc();
    const scheduledEnd = get(charging, 'scheduledEnd')
      ? moment(charging.scheduledEnd).utc()
      : null;
    const started = moment(get(charging, 'started')).utc();
    const stopped = moment(get(charging, 'stopped') || new Date()).utc();
    const maxConsumption = this.getMaxConsumption(charging, lastData);

    const cost = charging.cost;
    //  || getCost(
    //   moment(stopped.diff(started)).utc().valueOf() / 60000,
    //   lastData.consumption / 1000,
    //   charging.costPerMinute,
    //   charging.costPerConsumption,
    //   lastData.reactiveConsumption / 1000,
    // );

    return (
      <>
        <ConfirmationDialog
          open={this.state.open}
          onConfirm={this.state.dialog.confirm}
          onCancel={this.state.dialog.cancel}
          okText={this.state.dialog.okText || 'Ok'}
          cancelText={this.state.dialog.cancelText || 'Cancel'}
          title={this.state.dialog.title || 'Confirmation'}
        >
          <LocalizedText>{this.state.dialog.text}</LocalizedText>
        </ConfirmationDialog>
        <div
          className={`${Style.detailsContainer} ${
            Style.detailsContainerCharging
          } ${isHistoryItem ? Style.detailsContainerHistory : ''}`}
        >
          <div className={`${Style.details} ${Style.detailsHalf}`}>
            <div className={`${Style.details} ${Style.detailsFull}`}>
              {!isHistoryItem ? (
                <h2>
                  <LocalizedText>
                    {isShared ? 'Shared charging' : 'Current charging'}
                  </LocalizedText>
                  {charging.connector ? (
                    <>
                      <Icon
                        className={Style.titleIcon}
                        icon={`Charger/${charging.connector.type.replace(
                          /[^a-zA-Z0-9]/g,
                          ''
                        )}`}
                      />
                      <Icon
                        className={`${Style.titleIcon} ${Style.supIcon}`}
                        icon={`Charger/${charging.connector.current}`}
                      />
                    </>
                  ) : null}
                </h2>
              ) : null}
              {isHistoryItem && charging.connector ? (
                <LabeledItem
                  className={`${Style.detail} ${Style.detailOnlyIcon}`}
                  iconProps={{
                    useInRow: true,
                    iconClassName: [null, Style.supIcon],
                  }}
                  icons={[
                    `Charger/${charging.connector.type.replace(
                      /[^a-zA-Z0-9]/g,
                      ''
                    )}`,
                    `Charger/${charging.connector.current}`,
                  ]}
                  label={`${charging.connector.current} - ${charging.connector.type}`}
                  noTranslate
                />
              ) : null}
              <LabeledItem
                className={Style.detail}
                icon="FcCalendar"
                label={null}
              >
                {started && started.isValid() ? (
                  <>
                    <LocalizedText tag="span">Started</LocalizedText>
                    <time format="YYYY/MM/DD HH:mm:ss">
                      {started.local().format('YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </>
                ) : (
                  <>
                    <LocalizedText tag="span">
                      {scheduled === scheduledOptions.byCar
                        ? 'Start'
                        : 'Scheduled start'}
                    </LocalizedText>
                    {scheduled === scheduledOptions.solar ? (
                      <LocalizedText tag="span">
                        When green energy available
                      </LocalizedText>
                    ) : scheduled === scheduledOptions.byCar ? (
                      <LocalizedText tag="span">
                        When car will start
                      </LocalizedText>
                    ) : (
                      <time format="YYYY/MM/DD HH:mm:ss">
                        {scheduled.local().format('YYYY/MM/DD HH:mm:ss')}
                      </time>
                    )}
                  </>
                )}
              </LabeledItem>
              <LabeledItem className={Style.detail} icon="FcClock" label={null}>
                {!!paused ? (
                  <>
                    <LocalizedText tag="span">Paused until</LocalizedText>
                    {paused !== true ? (
                      <time format="YYYY/MM/DD HH:mm:ss">
                        {paused.local().format('YYYY/MM/DD HH:mm:ss')}
                      </time>
                    ) : (
                      <LocalizedText tag="span">
                        Restarted manually
                      </LocalizedText>
                    )}
                  </>
                ) : (
                  <>
                    <LocalizedText tag="span">Duration</LocalizedText>
                    {started && started.isValid() ? (
                      <time
                        ref={this.duration}
                        format="hh:mm:ss"
                        duration={charging.started}
                      >
                        {moment(stopped.diff(started)).utc().format('HH:mm:ss')}
                      </time>
                    ) : (
                      <LocalizedText tag="span">Not started yet</LocalizedText>
                    )}
                  </>
                )}
              </LabeledItem>
              {!isHistoryItem && !paused ? (
                <LabeledItem
                  hidden={this.estimated === false}
                  style={{ display: this.state.shownEstimated }}
                  className={Style.detail}
                  icon="FcClock"
                  label={null}
                >
                  <LocalizedText tag="span">
                    Estimated remaining time
                  </LocalizedText>
                  <time ref={this.durationEstimated} format="hh:mm:ss">
                    {this.estimated ? (
                      this.estimated
                    ) : (
                      <LocalizedText tag="span">
                        Not calculated yet
                      </LocalizedText>
                    )}
                  </time>
                </LabeledItem>
              ) : null}
              {!isHistoryItem &&
              started &&
              started.isValid() &&
              scheduledEnd &&
              scheduledEnd.isValid() ? (
                <LabeledItem
                  className={Style.detail}
                  icon="FcClock"
                  label="Scheduled stop"
                >
                  <time
                    ref={this.durationScheduled}
                    format="hh:mm:ss"
                    duration={charging.scheduledEnd}
                  >
                    {moment(scheduledEnd.diff(stopped))
                      .utc()
                      .format('HH:mm:ss')}
                  </time>
                </LabeledItem>
              ) : null}
              {charging.car && isHistoryItem ? (
                <LabeledItem
                  className={Style.detail}
                  icon="IoCarSportSharp"
                  label="Car"
                >
                  {charging.car.name}
                  <Car data={charging.car} className={Style.smallCar} />
                </LabeledItem>
              ) : null}
              {charging.consumptionLimit > 0 ? (
                <LabeledItem
                  className={Style.detail}
                  icon="FcHighBattery"
                  label="Energy limit"
                  children={abbrConsumption(charging.consumptionLimit)}
                />
              ) : null}
              <LabeledItem
                className={Style.detail}
                icon="FcChargeBattery"
                label="Energy charged"
                children={abbrConsumption(
                  !lastData.consumption ? 0 : lastData.consumption
                )}
              />
              {isTrue(solar) ? (
                <>
                  {lastData.reactiveConsumption > 0 ? (
                    <LabeledItem
                      className={Style.detail}
                      icon="FaLeaf"
                      iconClassName={Style.green}
                      label="Green energy used"
                      children={abbrConsumption(lastData.reactiveConsumption)}
                    />
                  ) : null}
                  {solarBoost > 0 ? (
                    <LabeledItem
                      className={Style.detail}
                      icons={['GiUpgrade', 'FaLeaf']}
                      label="Green boost"
                      iconProps={{
                        useSups: true,
                        iconClassName: [Style.yellowRed, Style.green],
                        children: (
                          <Gradient
                            colors={{ '0%': colors.red, '100%': colors.orange }}
                          />
                        ),
                      }}
                    >
                      {!isHistoryItem ? (
                        <LocalizedText tag="span">
                          {isTrue(lastData.boosted) ? 'Yes' : 'No'}
                        </LocalizedText>
                      ) : (
                        <LocalizedText tag="span">
                          {greenBoosted ? 'Yes' : 'No'}
                        </LocalizedText>
                      )}
                    </LabeledItem>
                  ) : null}
                </>
              ) : null}
              {/* {isHistoryItem && !isEmpty(charging.connector) ? (
                <>
                  <LabeledItem className={`${Style.detail} ${Style.detailOnlyIcon}`} 
                    icon={`Charger/${charging.connector.current}`}
                    label={charging.connector.current}
                    noTranslate />
                  <LabeledItem className={`${Style.detail} ${Style.detailOnlyIcon}`} 
                    icon={`Charger/${charging.connector.type.replace(/[^a-zA-Z0-9]/g, '')}`} 
                    label={charging.connector.type}
                    noTranslate/>
                </>
              ) : null}  */}
              <LabeledItem
                className={Style.detail}
                icon={'FcFlashOn'}
                label={null}
              >
                {!isHistoryItem && !isShared ? (
                  <>
                    <LocalizedText tag="span">Power</LocalizedText>
                    <span>{abbrPower(lastData.power || 0)}</span>
                  </>
                ) : (
                  <>
                    <LocalizedText tag="span">Average power</LocalizedText>
                    <span>{abbrPower(averagePower || 0)}</span>
                  </>
                )}
              </LabeledItem>
              <LabeledItem
                className={Style.detail}
                icon="FcFlashOn"
                label={null}
              >
                {!isHistoryItem && !isShared ? (
                  <>
                    <LocalizedText tag="span">Voltage</LocalizedText>
                    <span>{abbrVoltage(lastData.voltage || 0)}</span>
                  </>
                ) : (
                  <>
                    <LocalizedText tag="span">Average voltage</LocalizedText>
                    <span>{abbrVoltage(averageVoltage || 0)}</span>
                  </>
                )}
              </LabeledItem>
              <LabeledItem
                className={Style.detail}
                icon="FcFlashOn"
                label={null}
              >
                {!isHistoryItem && !isShared ? (
                  <>
                    <LocalizedText tag="span">Amperage</LocalizedText>
                    <span>
                      {abbrCurrent(
                        !lastData.power ? 0 : lastData.power / lastData.voltage
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <LocalizedText tag="span">Average amperage</LocalizedText>
                    <span>{abbrCurrent(averagePower / averageVoltage)}</span>
                  </>
                )}
              </LabeledItem>
              {cost > 0 ? (
                <LabeledItem
                  className={Style.detail}
                  icon="FcCurrencyExchange"
                  label="Cost"
                >
                  <LocalizedText
                    tag="span"
                    variables={{
                      value: cost.toFixed(cost % 1 ? 2 : 0),
                      unit: charging.costUnit,
                    }}
                    children="{{unit}} {{value}}"
                  />
                </LabeledItem>
              ) : null}
              {isHistoryItem &&
              charging.data &&
              charging.data.length &&
              charging.data.length > 1 ? (
                <LabeledItem
                  className={`${Style.detail} ${Style.clickable}`}
                  onClick={(e) => onOpenChart(e)}
                  icon="FcComboChart"
                >
                  <LocalizedText tag="span">
                    {isOpened ? 'Close chart' : 'Open chart'}
                  </LocalizedText>
                </LabeledItem>
              ) : null}
              {isHistoryItem && charging.sharedHash ? (
                <LabeledItem
                  className={`${Style.detail} ${Style.clickable}`}
                  icon="FcShare"
                >
                  <CopyToClipboard
                    text={`${window.location.origin}/${charging.chargerId}/shared/${charging.sharedHash}`}
                    onCopy={(e) => this.onShowShareDialog(true, charging.id, e)}
                  >
                    <LocalizedText tag="span">Share</LocalizedText>
                  </CopyToClipboard>
                </LabeledItem>
              ) : null}
              {isAdmin && isHistoryItem ? (
                <LabeledItem
                  className={`${Style.detail} ${Style.clickable}`}
                  onClick={() => this.onShowRemoveDialog(true, charging.id)}
                  icon="FcCancel"
                >
                  <LocalizedText tag="span">Remove</LocalizedText>
                </LabeledItem>
              ) : null}
            </div>
          </div>
          {isHistoryItem ? null : (
            <div
              className={`${Style.details} ${Style.detailsHalf} ${Style.batteryAndCar}`}
            >
              <div className={`${Style.details} ${Style.detailsFull}`}>
                <div className={`${Style.detail} ${Style.battery}`}>
                  <BatteryIcon
                    stopped={!started || !started.isValid()}
                    startingSOC={charging.car && charging.startingSOC}
                    soc={
                      lastData.consumption
                        ? parseFloat(lastData.consumption, 10)
                        : 0
                    }
                    max={
                      lastData.consumption &&
                      parseFloat(lastData.consumption, 10) >
                        parseFloat(maxConsumption, 10)
                        ? null
                        : maxConsumption
                    }
                    animated={!isShared}
                  />
                </div>
                {charging.car ? (
                  <div className={`${Style.detail} ${Style.car}`}>
                    <Car data={charging.car} className={Style.bigCar} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {charging.data &&
          charging.data.length &&
          (!isHistoryItem || isOpened) ? (
            <div className={`${Style.details} ${Style.chart}`}>
              <div className={Style.detail}>
                <Chart
                  data={charging.data}
                  maxConsumption={maxConsumption}
                  startingSOC={charging.car && charging.startingSOC}
                  green={isTrue(solar)}
                  boost={isTrue(solar) && solarBoost > 0}
                  boostPower={
                    charging.solarBoost > 0 ? charging.solarBoost : solarBoost
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

Charging.propTypes = {
  onOpenChart: PropTypes.func,
  eligibleToStop: PropTypes.bool,
  isRequested: PropTypes.bool,
  isShared: PropTypes.bool,
  charging: PropTypes.object,
  isHistoryItem: PropTypes.bool,
  isOpened: PropTypes.bool,
  isAdmin: PropTypes.bool,
  remove: PropTypes.func,
  solar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  solarBoost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(removeHistoryStart({ body: { id } })),
});

export default connect(null, mapDispatchToProps)(Charging);
