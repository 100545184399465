import React from 'react';
import PropTypes from 'prop-types';
import Style from './Users.module.scss';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsUsersLoading, selectUsers } from '../redux/app/app.selectors';
import { fetchUsersStart } from '../redux/app/app.actions';
import Loader from './Loader';
import { selectIsUserLoggedIn } from '../redux/user/user.selectors';
import LocalizedText from './LocalizedText';
import LabeledItem from './LabeledItem';
import ConfirmationDialog from './ConfirmationDialog';
import { setEdit, removeStart } from '../redux/user/user.actions';

class Users extends React.Component {
  list = React.createRef();
  state = {
    styles: {},
    id: null,
    open: false,
    user: null,
  };

  componentDidMount() {
    this.chargerId = this.props.match.params.chargerId;

    this.props.fetch({
      params: {
        chargerId: this.chargerId,
      },
    });
  }

  onRemove(id) {
    this.props.remove(id);
    this.setState({ open: false, id: null });
  }

  onShow(open, id) {
    this.setState({ open: !!open, id });
  }

  onEdit(user) {
    this.props.edit(user);
  }

  render() {
    if (!this.props.isAdmin) {
      return null;
    }

    const { data: users } = this.props;

    return (
      <>
        <ConfirmationDialog
          open={this.state.open}
          onConfirm={() => this.onRemove(this.state.id)}
          onCancel={() => this.onShow(false)}
        >
          <LocalizedText>Are you sure you want to remove?</LocalizedText>
        </ConfirmationDialog>
        <div className={Style.Users}>
          <LocalizedText tag="h2">Users</LocalizedText>
          <div className={Style.list} ref={this.list}>
            {users && users.length
              ? users.map((user, index) => {
                  return (
                    <div
                      key={`Link${index}`}
                      className={Style.user}
                      style={{
                        animationDelay: `${index * 0.1}s`,
                      }}
                    >
                      <LabeledItem
                        className={Style.detail}
                        icon="SiMinutemailer"
                        label="E-mail"
                      >
                        {user.username}
                      </LabeledItem>
                      <LabeledItem
                        className={`${Style.detail} ${Style.clickable}`}
                        icon="FiEdit"
                        onClick={() => this.onEdit(user)}
                      >
                        <LocalizedText tag="span">Edit</LocalizedText>
                      </LabeledItem>
                      <LabeledItem
                        className={`${Style.detail} ${Style.clickable}`}
                        icon="FcCancel"
                        onClick={() => this.onShow(true, user.id)}
                      >
                        <LocalizedText tag="span">Remove</LocalizedText>
                      </LabeledItem>
                    </div>
                  );
                })
              : null}
          </div>
          {this.props.isLoading ? <Loader /> : null}
        </div>
      </>
    );
  }
}

Users.propTypes = {
  match: PropTypes.object,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  fetch: PropTypes.func,
  isAdmin: PropTypes.bool,
  edit: PropTypes.func,
  remove: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsUsersLoading,
  isLoggedIn: selectIsUserLoggedIn,
  data: selectUsers,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options) => dispatch(fetchUsersStart(options)),
  edit: (user) => dispatch(setEdit(user)),
  remove: (id) => dispatch(removeStart({ body: { id } })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
