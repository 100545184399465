import React from 'react';
import PropTypes from 'prop-types';
import { Form as RForm, Field, reduxForm } from 'redux-form';
import Style from './Form.module.scss';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectAsyncErrors,
  selectChangedState,
  selectErrors,
  selectCustomErrors,
  selectLoadingState,
} from '../../redux/form/form.selectors';
import Loader from '../../components/Loader';
import { isEmpty, isEqual, isObject, map } from 'lodash';
import { required } from './Form.validators';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

// import { ReactRadioButtonsGroup, ReactRadioButton } from 'react-radio-buttons-group';
// import { RadioButton, RadioGroup } from 'react-radio-group-context';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import LocalizedText, { translate } from '../../components/LocalizedText';
import ComboFlyout from '../../components/ComboFlyout';

export const FieldToggle = (field) => (
  <Toggle
    checked={field.input.value}
    onChange={field.input.onChange}
    icons={false}
  />
);

export const RadioButtons = (field) => {
  return (
    <RadioGroup
      aria-label="autoStart"
      name="autoStart"
      className={`${Style.RadioButtons} ${field.input.className}`}
      value={field.input.value}
      onChange={field.input.onChange}
    >
      {map(field.options || [], (option, key) => {
        const label = isObject(option) ? option.label || key : option;

        return (
          <FormControlLabel
            className={`${Style.RadioButton} ${field.input.itemClassName}`}
            value={`${key}`}
            control={<Radio />}
            l
            label={translate(label)}
            labelPlacement={field.labelPlacement || 'bottom'}
          />
        );
      })}
    </RadioGroup>
  );
};

export const Select = (field) => {
  return (
    <ComboFlyout
      shownLabel
      autoOpening
      id={field.input.id}
      label={field.input.label || field.label}
      className={`${Style.Select} ${field.input.className}`}
      selectClassName={Style.SelectRoot}
      value={field.input.value}
      onChange={field.input.onChange}
      options={field.options}
    />
  );
};

class Form extends React.Component {
  form = React.createRef();

  componentDidMount() {
    if (!isEmpty(this.props.initialValues)) {
      this.props.initialize(this.props.initialValues);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
      this.props.initialize(this.props.initialValues);
    }
  }

  render() {
    const {
      id: formId,
      title,
      fields,
      isLoading,
      children,
      handleSubmit,
      submitLabel,
      submitButtonStyle,
      submitContainerStyle,
      forwardRef,
      errors,
      asyncErrors,
      customErrors,
      changed,
      autoComplete,
    } = this.props;

    let globalError = !!changed && errors && errors.global;
    globalError = (asyncErrors && asyncErrors.global) || globalError;
    globalError = (customErrors && customErrors.global) || globalError;

    return (
      <div className={`Form ${Style.container}`}>
        <RForm
          autoComplete={autoComplete}
          onSubmit={handleSubmit((...args) => this.props.onSubmit(...args))}
          ref={forwardRef || this.form}
        >
          {title ? <h2>{title}</h2> : null}
          {isLoading ? (
            <Loader label="Loading" />
          ) : (
            <div className={Style.fields}>
              {map(fields, (field, key) => {
                const name = field.name || field.id || key;
                const id = field.name || field.id || key;
                const options = field.options;

                let Element = 'input';

                if (isObject(field.type)) {
                  Element = field.type;
                }
                if (field.type === 'textarea') {
                  Element = 'textarea';
                } else if (field.type === 'select') {
                  Element = 'select';
                }

                const validators = field.validators || [];
                if (field.required && !validators.includes(required)) {
                  validators.push(required);
                }

                let error =
                  !!changed &&
                  errors &&
                  (errors[id] || errors[`${formId}_${id}`]);
                error =
                  (asyncErrors &&
                    (asyncErrors[id] || asyncErrors[`${formId}_${id}`])) ||
                  error;
                error =
                  (customErrors &&
                    (customErrors[id] || customErrors[`${formId}_${id}`])) ||
                  error;

                return (
                  <div
                    key={`${formId}_${id}`}
                    className={`${Style.field} ${field.style.container || ''} ${
                      error ? Style.error : ''
                    }`}
                  >
                    {field.label ? (
                      <label
                        htmlFor={`${formId}_${id}`}
                        className={`${Style.label} ${field.style.label || ''}`}
                      >
                        {field.label}
                      </label>
                    ) : null}

                    <Field
                      {...field}
                      component={Element}
                      validate={validators}
                      required={field.required}
                      id={`${formId}_${id}`}
                      className={`${Style.element} ${
                        field.style.element || ''
                      }`}
                      name={`${formId}_${name}`}
                      options={options}
                    />
                    {error ? (
                      <LocalizedText tag="div" className={Style.errorMSG}>
                        {error}
                      </LocalizedText>
                    ) : null}
                  </div>
                );
              })}
              {globalError ? (
                <LocalizedText tag="div" className={Style.errorMSG}>
                  {globalError}
                </LocalizedText>
              ) : null}
              {children ? (
                children
              ) : (
                <div
                  className={`${Style.submitContainer} ${submitContainerStyle}`}
                >
                  <Field
                    name="submit"
                    className={`${Style.submitButton} ${submitButtonStyle}`}
                    children={submitLabel || 'Submit'}
                    component={'button'}
                    type="submit"
                    disabled={isLoading}
                  />
                </div>
              )}
            </div>
          )}
        </RForm>
      </div>
    );
  }
}

Form.defaultProps = {
  fields: [],
  onSubmit: () => {},
};

Form.propTypes = {
  changed: PropTypes.object,
  errors: PropTypes.object,
  asyncErrors: PropTypes.object,
  customErrors: PropTypes.object,
  submitContainerStyle: PropTypes.any,
  submitButtonStyle: PropTypes.any,
  submitLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
  id: PropTypes.any,
  initial: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onSubmit: PropTypes.func,
  forwardRef: PropTypes.object,
  initialValues: PropTypes.object,
  initialize: PropTypes.func,
  autoComplete: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingState,
  errors: selectErrors,
  asyncErrors: selectAsyncErrors,
  customErrors: selectCustomErrors,
  changed: selectChangedState,
});

const mapDispatchToProps = (dispatch) => ({});

const formWrapper = (props) => {
  const WrappedForm = reduxForm({
    form: props.id,
    touchOnBlur: true,
    touchOnChange: true,
    destroyOnUnmount: false,
    enableReinitialize: true,
  })(connect(mapStateToProps, mapDispatchToProps)(Form));

  return <WrappedForm {...props} />;
};

export default formWrapper;
