import { isArray, isFunction } from 'lodash';
import { createSelector } from 'reselect';
import { selectCombinedState } from '../root-selector';
import { ClearChargerKeys } from './app.utils';

export const selectApp = selectCombinedState('app');

export const selectLearning = createSelector(
  [selectApp],
  (app) => app.learning
);

export const selectMode = createSelector([selectApp], (app) => app.mode);
export const selectError = createSelector([selectApp], (app) => app.error);
export const selectIsLoadingAll = createSelector(
  [selectApp],
  (app) => app.isLoading
);
export const selectIsLoadingHiddenAll = createSelector(
  [selectApp],
  (app) => app.isLoadingHidden
);
export const selectIsLoadingAny = createSelector(
  [selectApp],
  (app) => !!Object.values(app.isLoading).filter(Boolean).length
);
export const selectIsLoadingHiddenAny = createSelector(
  [selectApp],
  (app) => !!Object.values(app.isLoadingHidden).filter(Boolean).length
);
export const selectIsAppLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.status
);
export const selectIsSharedLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.shared
);

export const selectIsAppLoadingHidden = createSelector(
  [selectIsLoadingHiddenAll],
  (loading) => loading.status
);

export const selectIsChargingLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.request || loading.stop
);

export const selectIsHistoryLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.history
);

export const selectIsUsersLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.users
);

export const selectIsChargersLoading = createSelector(
  [selectIsLoadingAll],
  (loading) => loading.chargers
);

export const selectClientId = createSelector(
  [selectApp],
  (app) => app.clientId
);

export const selectChargerId = createSelector(
  [selectApp],
  (app) => app.chargerId
);

export const selectCar = createSelector([selectApp], (app) => app.storedCar);

export const selectEligibleToStart = createSelector(
  [selectApp],
  (app) => app.eligibleToStart
);

export const selectIsRequested = createSelector(
  [selectApp],
  (app) => app.isRequested
);

export const selectChartRanges = createSelector(
  [selectApp],
  (app) => app.chartRanges || {}
);

export const selectSelectedChart = createSelector(
  [selectApp],
  (app) => app.selectedChart || 0
);

export const selectCharging = createSelector(
  [selectApp],
  (app) => app.charging || {}
);

export const selectCharger = createSelector(
  [selectApp],
  (app) => app.charger || {}
);

export const selectClearCharger = createSelector([selectCharger], (charger) => {
  const clearCharger = {};

  ClearChargerKeys.forEach((key) => {
    if (isArray(key) && key.length === 2) {
      clearCharger[key[0]] = isFunction(key[1])
        ? key[1](charger[key[0]])
        : charger[key[1]];
    }

    clearCharger[key] = charger[key];
  });

  return clearCharger;
});

export const selectChargerAddress = createSelector(
  [selectCharger],
  (charger) => charger.address
);

export const selectChargerTemperature = createSelector(
  [selectCharger],
  (charger) => charger.temperature
);

export const selectChargerTemperatureStatus = createSelector(
  [selectCharger],
  (charger) => charger.temperatureStatus
);

export const selectChargerPower = createSelector(
  [selectCharger],
  (charger) => charger.power
);

export const selectChargerAutoStart = createSelector(
  [selectCharger],
  (charger) => charger.autoStart
);

export const selectChargerTurnedOn = createSelector(
  [selectCharger],
  (charger) => charger.turnedOn
);

export const selectChargers = createSelector(
  [selectApp],
  (app) => app.chargers || []
);

export const selectHistory = createSelector(
  [selectApp],
  (app) => app.history || []
);

export const selectUsers = createSelector(
  [selectApp],
  (app) => app.users || []
);

export const selectHistoryFilter = createSelector(
  [selectApp],
  (app) => app.historyFilter || {}
);

export const selectHistoryFilterData = createSelector(
  [selectHistoryFilter],
  (historyFilter) => historyFilter.data || []
);

export const selectStat = createSelector([selectApp], (app) => app.stat || []);

export const selectSolarStat = createSelector(
  [selectApp],
  (app) => app.solarStat || []
);

export const selectDeviceStat = createSelector(
  [selectApp],
  (app) => app.deviceStat || []
);

export const selectDeviceTemperatureStat = createSelector(
  [selectApp],
  (app) => app.deviceTemperatureStat || []
);

export const selectPage = createSelector([selectApp], (app) => app.page);

export const selectLanguage = createSelector(
  [selectApp],
  (app) => app.language
);

export const selectHistoryPage = createSelector(
  [selectPage],
  (page) => page.history || {}
);

export const selectHistoryPrevPage = createSelector(
  [selectHistoryPage],
  (page) => page.prev
);

export const selectHistoryNextPage = createSelector(
  [selectHistoryPage],
  (page) => page.next
);

export const selectHistoryTotal = createSelector([selectCharger], (charger) =>
  parseInt(charger.totalCountOfChargings, 10)
);
export const selectVideo = createSelector(
  [selectCharger],
  (charger) => charger.video
);
export const selectTotalConsumption = createSelector(
  [selectCharger],
  (charger) => charger.totalConsumption
);
export const selectTotalReactiveConsumption = createSelector(
  [selectCharger],
  (charger) => charger.totalReactiveConsumption
);

export const selectConsumptionLimit = createSelector(
  [selectCharger],
  (charger) => charger.consumptionLimit
);

export const selectIsAuthRequired = createSelector(
  [selectCharger],
  (charger) => charger.authRequired
);

export const selectCostUnit = createSelector(
  [selectCharger],
  (charger) => charger.costUnit
);

export const selectSolar = createSelector(
  [selectCharger],
  (charger) => charger.solar
);

export const selectSolarBoost = createSelector(
  [selectCharger],
  (charger) => charger.solarBoost
);

export const selectSolarMeter = createSelector(
  [selectCharger],
  (charger) => charger.solarMeter
);

export const selectSolarPower = createSelector(
  [selectSolarMeter],
  (solarMeter) => solarMeter.power
);

export const selectCostPerConsumption = createSelector(
  [selectCharger],
  (charger) => charger.costPerConsumption
);

export const selectCostPerMinute = createSelector(
  [selectCharger],
  (charger) => charger.costPerMinute
);

export const selectScheduling = createSelector(
  [selectCharger],
  (charger) => charger.schedule
);

export const selectLocation = createSelector(
  [selectCharger],
  (charger) => charger.location || {}
);

export const selectLatitude = createSelector(
  [selectLocation],
  (location) => location.lat
);

export const selectLongitude = createSelector(
  [selectLocation],
  (location) => location.lng
);

export const selectScheduled = createSelector(
  [selectCharging],
  (charging) => charging.scheduled
);

export const selectScheduledEnd = createSelector(
  [selectCharging],
  (charging) => charging.scheduledEnd
);

export const selectPaused = createSelector([selectCharging], (charging) =>
  charging.pausedUntil === '0000-00-00 00:00:00' ? true : charging.pausedUntil
);

export const selectStarted = createSelector(
  [selectCharging],
  (charging) => charging.started
);

export const selectStopped = createSelector(
  [selectCharging],
  (charging) => charging.stopped
);

export const selectConsumptionAtStart = createSelector(
  [selectCharging],
  (charging) => charging.consumptionAtStart
);

export const selectEligibleToStop = createSelector(
  [selectApp],
  (app) => app.eligibleToStop
);
