import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Style from './ComboFlyout.module.scss';
import { isObject, map } from 'lodash';

export default class ComboFlyout extends React.Component {
  button = React.createRef();

  render() {
    const { autoOpening, shownLabel } = this.props;
    const additionalProps = {};

    if (!autoOpening) {
      additionalProps.open = this.props.opened;
    }

    return (
      <FormControl
        className={`${Style.formControl} ${
          autoOpening ? Style.formControlOpened : ''
        } ${this.props.className}`}
      >
        <InputLabel
          id={this.props.id}
          className={`${Style.label} ${shownLabel ? Style.shownLabel : ''} ${
            this.props.labelClassName
          }`}
          shrink={false}
        >
          {this.props.label}
        </InputLabel>
        <Select
          labelId={this.props.id}
          className={`${Style.select} ${this.props.selectClassName}`}
          value={`${this.props.value}`}
          onClose={(e) => this.props.onClose(e)}
          onChange={(e) => this.props.onChange(e)}
          disabled={this.props.disabled}
          MenuProps={{
            classes: {
              root: this.props.rootClassName,
              paper: this.props.paperClassName,
            },
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          {...additionalProps}
        >
          {this.props.children
            ? this.props.children
            : map(this.props.options, (givenObj, value) => {
                let obj = givenObj;
                if (!isObject(obj)) {
                  obj = { label: givenObj };
                }

                return (
                  <MenuItem
                    key={value}
                    value={`${obj.value || value}`}
                    className={this.props.menuClassName}
                    disabled={!!obj.disabled}
                  >
                    {obj.label || value}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
    );
  }
}

ComboFlyout.defaultProps = {
  onClose: () => {},
  onChange: () => {},
  options: [],
};

ComboFlyout.propTypes = {
  children: PropTypes.object,
  autoOpening: PropTypes.bool,
  disabled: PropTypes.bool,
  shownLabel: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  paperClassName: PropTypes.string,
  rootClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
