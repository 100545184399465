import React from 'react';
import PropTypes from 'prop-types';
import HEREMap, { Marker } from 'here-maps-react';
import Style from './Map.module.scss';
import Icon from './Icon';
import { change, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import LocalizedText from './LocalizedText';

class Map extends React.Component {
  state = {
    loaded: !!window.H,
  };

  componentDidMount() {
    const tick = () => {
      if (window.H) {
        this.setState({ loaded: true });
        clearInterval(this.ticker);
      } else {
        // eslint-disable-next-line no-console
        console.error('Here maps not loaded');
      }
    };
    this.ticker = setInterval(tick, 500);
  }

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  shouldComponentUpdate() {
    return false;
  }

  moveStart(e) {
    if (!e.target.setPosition) {
      e.target.setPosition = e.target.setGeometry;
    }
  }

  moveEnd(e) {
    if (this.props.isAdmin) {
      this.props.change(Object.values(e.target.getGeometry()).join(','));
    }
  }

  onChange(e) {
    e.preventDefault();

    const { lat, lng } = this.props;
    let url;

    switch (e.target.value) {
      case 'waze': {
        url = `https://www.waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=17&navigate=yes`;
        break;
      }

      case 'here': {
        url = `https://share.here.com/l/${lat},${lng}`;
        break;
      }

      case 'apple': {
        url = `http://maps.apple.com/?ll=${lat},${lng}`;
        break;
      }

      case 'google': {
        url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        break;
      }

      default: {
        break;
      }
    }

    if (url) {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }

  render() {
    const {
      className,
      lat,
      lng,
      isAdmin = false,
      interactive = false,
      navigateTo = false,
    } = this.props;

    return (
      <div className={`${Style.Map} ${className}`}>
        {isAdmin ? (
          <Field name="location" component={'input'} type="hidden" />
        ) : null}
        {lat && lng && this.state.loaded ? (
          <HEREMap
            appId="KNkmreO6fETmZbaD5Jl8"
            apikey="exSvc_R6iAPMow4vb_uF1Kr_SyYQVbCiW3nV3YT2S_Y"
            center={{ lat, lng }}
            zoom={12}
            interactive={interactive}
          >
            <Marker
              lat={lat}
              lng={lng}
              draggable={isAdmin}
              onDragStart={(e) => this.moveStart(e)}
              onDragEnd={(e) => this.moveEnd(e)}
            />
          </HEREMap>
        ) : null}
        {navigateTo ? (
          <FormControl className={Style.formControl}>
            <InputLabel id="navigation" className={Style.label} shrink={false}>
              <LocalizedText>Navigation</LocalizedText>
            </InputLabel>
            <Select
              labelId="navigation"
              className={Style.select}
              value={''}
              onChange={(e) => this.onChange(e)}
            >
              <MenuItem value={'waze'} className={Style.waze}>
                <Icon className={Style.icon} icon="FaWaze" />
                Waze
              </MenuItem>
              <MenuItem value={'here'} className={Style.here}>
                <Icon className={Style.icon} icon="SiHere" />
                Here
              </MenuItem>
              <MenuItem value={'google'} className={Style.google}>
                <Icon className={Style.icon} icon="FaMapMarkerAlt" />
                Google Maps
              </MenuItem>
              <MenuItem value={'apple'} className={Style.apple}>
                <Icon className={Style.icon} icon="FaApple" />
                Apple Maps
              </MenuItem>
            </Select>
          </FormControl>
        ) : null}
      </div>
    );
  }
}

Map.propTypes = {
  interactive: PropTypes.bool,
  className: PropTypes.string,
  navigateTo: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  isAdmin: PropTypes.bool,
  change: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  change: (value) => dispatch(change('updateCharger', 'location', value)),
});

export default connect(null, mapDispatchToProps)(Map);
