import { isArray } from 'lodash';

const allowedTypesObj = {
  videos: {
    'video/mp4': '.mp4',
    'video/quicktime': '.mpv',
    'video/mpg': '.mpg',
    'video/mpeg': '.mpeg',
    'video/webm': '.webm',
    'video/wmv': '.wmv',
    'video/avi': '.avi',
    'video/flv': '.flv',
    'video/x-flv': '.flv',
    'video/x-ms-wmv': '.wmv',
  },
  pictures: {
    'image/png': '.png',
    'image/jpg': '.jpg',
    'image/jpeg': '.jpeg',
    'image/bmp': '.bmp',
  },
};

export const allowedTypes = {
  videos: Object.keys(allowedTypesObj.videos),
  pictures: Object.keys(allowedTypesObj.pictures),
};

export const allowedTypesExt = {
  videos: Object.values(allowedTypesObj.videos),
  pictures: Object.values(allowedTypesObj.pictures),
};

export const maxSize = {
  videos: 2048,
  pictures: 2048,
};

export const file = (type) => (value) => {
  if (!value || !value.file) {
    return undefined;
  }

  const selectedTypes = allowedTypes[type];
  const selectedExtension = allowedTypesExt[type];

  const { file } = value;

  const fileSize = Math.round(file.size / 1000);
  const validFile = !!selectedTypes.find((type) => file.type.includes(type));
  if (!validFile) {
    return `You can only upload only ${selectedExtension.join(', ')}`;
  }

  if (fileSize > maxSize) {
    return `Uploaded file must be lower than ${maxSize[type]} bytes in size!`;
  }

  return undefined;
};
export const video = file('videos');
export const picture = file('pictures');

export const required = (value) => (value ? undefined : 'Required');
export const maxLength = (max) => (value) => {
  return value && value.length > max
    ? `Must be shorter than ${max}`
    : undefined;
};
export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const minValue = (min) => (value) => {
  return value && value < min
    ? `Must be greater than or equal to ${min}`
    : undefined;
};
export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid format'
    : undefined;
};

export const passwordsMatch = (field) => (value, allValues) => {
  return value !== allValues[field] ? "Passwords don't match" : undefined;
};

export const requiredIf =
  (field, exact = false) =>
  (value, allValues) => {
    let fields = field;
    if (!isArray(field)) {
      fields = [field];
    }

    if (
      !!fields.find(
        (key) =>
          !!allValues[key] && (!exact || (exact && allValues[key] !== '0'))
      )
    ) {
      return required(value);
    }

    return undefined;
  };
