import React from 'react';
import PropTypes from 'prop-types';
import Style from './Admin.module.scss';
import { withI18n } from 'react-i18next';
import sha1 from 'sha1';
import { submit } from 'redux-form';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectIsUserLoggedIn,
  selectUserToken,
} from '../redux/user/user.selectors';
import Loader from './Loader';
import Dialog from './Dialog';
import Form from '../containers/form/Form';
import {
  selectClearCharger,
  selectChargerTurnedOn,
  selectIsAppLoading,
  selectIsAuthRequired,
} from '../redux/app/app.selectors';
import { login, logout } from '../redux/user/user.actions';
import Main from './Main';
import Button from './Button';
import { fetchStatusStart, updateStart } from '../redux/app/app.actions';
import { difference, isTrue } from '../utils';
import Profile from './Profile';
import { translate } from './LocalizedText';
import Users from './Users';
import NewUser from './NewUser';
import LabeledItem from './LabeledItem';
import { isEqual } from 'lodash';
import { Redirect } from 'react-router-dom';

const Wrapper = (props) => {
  if (props.isAdmin) {
    return <Form {...props} />;
  }

  return props.children || null;
};

class Admin extends React.Component {
  state = {
    editOverlay: null,
  };

  componentDidMount() {
    this.chargerId = this.props.match.params.chargerId;

    this.props.fetch({
      params: {
        chargerId: this.chargerId,
      },
    });

    this.props.setAdmin(this.props.isAdmin);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.charger.id !== undefined &&
      prevProps.charger.id !== this.props.charger.id &&
      this.props.match.params.username &&
      this.props.match.params.key &&
      ['start', 'stop', 'check'].includes(this.props.smart) &&
      !this.state.smartLoggedStarted
    ) {
      this.props.login({
        username: this.props.match.params.username,
        key: this.props.match.params.key,
        action: this.props.smart,
      });
      this.setState({ smartLoggedStarted: true });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    const should =
      this.shouldUpdate ||
      !isEqual(prevProps, this.props) ||
      !isEqual(prevState, this.state);
    this.shouldUpdate = false;
    return should;
  }

  closeOverlay(e) {
    this.setState({ editOverlay: null });
  }

  openOverlay(e, type) {
    //compionentre cserelni a vegen amiben redux form van
    e.preventDefault();
    if (type) {
      let content = null;
      if (type === 'profile') {
        content = (
          <Profile
            callback={() => this.closeOverlay()}
            chargerId={this.chargerId}
          />
        );
      } else if (type === 'users') {
        content = (
          <>
            <NewUser isAdmin={this.props.isAdmin} />
            <Users isAdmin={this.props.isAdmin} match={this.props.match} />
          </>
        );
      }

      this.setState({
        editOverlay: {
          opened: true,
          title: `Edit ${type}`,
          content,
        },
      });
    }
  }

  onSubmit(values) {
    this.props.login({
      username: values.login_username,
      password: sha1(values.login_password),
    });
  }

  onSave(values) {
    const editedValues = difference(values, this.getInitialValues());
    editedValues.connectors = values.connectors;

    this.props.save(editedValues);
  }

  turn(e, turned) {
    e.preventDefault();
    this.props.save({ turnedOn: turned });
  }

  getInitialValues() {
    return this.props.charger;
  }

  render() {
    const {
      isAppLoading,
      isLoggedIn,
      isAdmin,
      turnedOn,
      authRequired,
      match,
      smart,
    } = this.props;

    const { editOverlay } = this.state;

    if (
      this.chargerId &&
      match.params.username &&
      match.params.key &&
      ['start', 'stop', 'check'].includes(smart) &&
      isLoggedIn
    ) {
      return <Redirect to={`/${this.chargerId}`} />;
    }

    if (isLoggedIn || (authRequired !== undefined && !isTrue(authRequired))) {
      return (
        <>
          {editOverlay && editOverlay.opened ? (
            <Dialog
              title={editOverlay.title}
              okText={false}
              open={editOverlay.opened}
              onClose={() => this.closeOverlay()}
            >
              {editOverlay.content}
            </Dialog>
          ) : null}
          <Wrapper
            id="updateCharger"
            submitButtonStyle={Style.submitButton}
            submitContainerStyle={Style.submitContainer}
            onSubmit={(...props) => {
              this.enabled && this.onSave(...props);
              this.enabled = false;
            }}
            isAdmin={isAdmin}
            initialValues={this.getInitialValues()}
          >
            <Main
              match={this.props.match}
              className={`Main ${Style.Main} ${isAdmin ? Style.Admin : ''}`}
              isAdmin={isAdmin}
            >
              {isAdmin || isTrue(authRequired) ? (
                <div className={Style.mainButtonContainer}>
                  <div className={Style.leftButtonContainer}>
                    {isAdmin ? (
                      <>
                        <LabeledItem
                          component={Button}
                          type="submit"
                          onClick={() => {
                            this.enabled = true;
                            this.shouldUpdate = true;
                          }}
                          className={Style.saveButton}
                          icon="MdDone"
                          label="Save"
                          labelClassName={Style.buttonLabel}
                          children={null}
                        />
                        <LabeledItem
                          component={Button}
                          type="button"
                          onClick={(e) =>
                            this.turn(e, !turnedOn || turnedOn !== '1')
                          }
                          className={Style.shutDownButton}
                          icon="RiShutDownLine"
                          label={isTrue(turnedOn) ? 'Shut down' : 'Turn on'}
                          labelClassName={Style.buttonLabel}
                          children={null}
                        />
                        <LabeledItem
                          component={Button}
                          type="submit"
                          onClick={(e) => this.openOverlay(e, 'users')}
                          icon="FaUsers"
                          label="Users"
                          labelClassName={Style.buttonLabel}
                          children={null}
                        />
                      </>
                    ) : null}
                  </div>
                  <div className={Style.rightButtonContainer}>
                    <LabeledItem
                      component={Button}
                      type="submit"
                      onClick={(e) => this.openOverlay(e, 'profile')}
                      icon="FaUser"
                      label="Profile"
                      labelClassName={Style.buttonLabel}
                      children={null}
                    />
                    <LabeledItem
                      component={Button}
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ smartLoggedStarted: false });
                        this.props.logout();
                      }}
                      className={Style.logoutButton}
                      icon="FiLogOut"
                      label="Logout"
                      labelClassName={Style.buttonLabel}
                      children={null}
                    />
                  </div>
                </div>
              ) : null}
            </Main>
          </Wrapper>
        </>
      );
    }

    return (
      <div className={`Main ${Style.Main} ${isAdmin ? Style.Admin : ''}`}>
        {isAppLoading || authRequired === undefined ? (
          <Loader label={translate('Loading')} />
        ) : (
          <div className={Style.controller}>
            <Form
              id="login"
              title={translate('Login')}
              submitLabel={translate('Login')}
              submitButtonStyle={Style.submitButton}
              submitContainerStyle={Style.submitContainer}
              onSubmit={(...props) => this.onSubmit(...props)}
              fields={[
                {
                  id: 'username',
                  label: translate('Username'),
                  required: true,
                  style: {
                    container: Style.loginContainer,
                    label: Style.label,
                    element: Style.element,
                  },
                  placeholder: translate('Username'),
                },
                {
                  id: 'password',
                  label: translate('Password'),
                  required: true,
                  type: 'password',
                  style: {
                    container: Style.loginContainer,
                    label: Style.label,
                    element: Style.element,
                  },
                  placeholder: translate('Password'),
                },
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

Admin.propTypes = {
  match: PropTypes.object,
  isAppLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  setAdmin: PropTypes.func,
  fetch: PropTypes.func,
  login: PropTypes.func,
  logout: PropTypes.func,
  save: PropTypes.func,
  charger: PropTypes.object,
  turnedOn: PropTypes.any,
  authRequired: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  smart: PropTypes.oneOf < PropTypes.string > ['start', 'stop', 'check'],
};

const mapStateToProps = createStructuredSelector({
  isAppLoading: selectIsAppLoading,
  isLoggedIn: selectIsUserLoggedIn,
  token: selectUserToken,
  charger: selectClearCharger,
  turnedOn: selectChargerTurnedOn,
  authRequired: selectIsAuthRequired,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options = {}, hidden = false) =>
    dispatch(fetchStatusStart(options, hidden)),
  login: (body) => dispatch(login({ options: { body } })),
  logout: () => dispatch(logout()),
  save: (body) => dispatch(updateStart({ body })),
  submit: (form) => dispatch(submit(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Admin));
