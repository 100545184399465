import { useEffect, useRef } from 'react';
import { getDoc, getZip } from '../../utils';

export const DEFAULT_CAR_CAPACITY = 28000;
export const DEFAULT_LANGUAGE = 'en';
export const IS_DEBUG = window.location.search.includes('debug=on');
export const IS_ADMIN = /^\/?\d+\/admin/.test(window.location.pathname);
export const IS_DEV = /localhost/.test(window.location.href);

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const ConnectorTypes = {
  Schuko: 'Schuko',
  'CEE 3P': 'CEE 3P',
  'Type-2 1P': 'Type-2 1P',
  'Type-2 3P': 'Type-2 3P',
  Chademo: 'Chademo',
  CCS: 'CCS',
};

export const ClearChargerKeys = [
  'id',
  'address',
  ['location', (value) => (value ? Object.values(value).join(',') : '')],
  'autoStart',
  'anonymusStop',
  'consumptionLimit',
  'authRequired',
  'schedule',
  'costPerMinute',
  'costPerConsumption',
  'costUnit',
  'solar',
  'solarBoost',
  'connectors',
];

export const SITE_URL = `${window.location.protocol}//ev.idavid.hu/`;
export const API_URL = `${SITE_URL}api/`;
export const CARS_URL = `${SITE_URL}cars/`;

export const fetchAPI = (endpoint, options = {}) => {
  const url = new URL(`${API_URL}${endpoint}`);
  const params = options.params || {};
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return fetch(url, {
    ...options,
  }).then(async (res) => {
    if (res.status !== 200) {
      throw new Error(`${res.status} ${res.statusText}. Please try again.`);
    }

    if (/application\/json/.test(res.headers.get('content-type'))) {
      return res.json();
    }

    if (/application\/zip/.test(res.headers.get('content-type'))) {
      return new Promise((resolve) => {
        res.text().then((text) => resolve(getZip(text)));
      });
    }

    return new Promise((resolve) => {
      res.text().then((text) => resolve(getDoc(text)));
    });
  });
};

export const postAPI = (endpoint, options = {}, data = {}) => {
  return fetchAPI(endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const getCallbackName = (type, append = '') => {
  const scope = type.replace(/^([^\/]+).*$/, '$1');
  return (
    type.replace(/[\/_]?([^\/_]+)/g, (m, p, o) => {
      if (p === scope || p === 'START') {
        return '';
      }

      if (o <= scope.length) {
        return p.toLowerCase();
      }

      return m.substr(1, 1) + m.substr(2).toLowerCase();
    }) + append
  );
};
