import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, isNumber, isString } from 'lodash';
import Icon from './Icon';
import LocalizedText from './LocalizedText';
import { Context } from '../utils';

class LabeledItem extends React.Component {
  checkIfTrue(component) {
    const { check } = this.props;
    if (check !== undefined) {
      let isTrue = check;
      if (isFunction(check)) {
        isTrue = check();
      }

      return (!!component && !!isTrue) || (!!!component && !!!isTrue);
    }

    return false;
  }

  render() {
    const {
      component: Component,
      children,
      className,
      label,
      labelClassName,
      icons,
      icon,
      iconComponent,
      iconClassName,
      iconProps = {},
      hidden,
      variables,
      forwardRef,
      noTranslate,
      ...props
    } = this.props;

    if (hidden) {
      return null;
    }

    const Container =
      Component || ((props) => <div ref={forwardRef} {...props} />);
    const IconContainer = icons
      ? (groupProps) => <Icon.Group {...groupProps} icons={icons} />
      : (singleProps) => <Icon {...singleProps} icon={icon} />;

    return (
      <Container className={className} {...props}>
        {iconComponent ? iconComponent : null}
        {!iconComponent ? (
          <IconContainer className={iconClassName} {...iconProps} />
        ) : null}
        {label ? (
          noTranslate ? (
            <span className={labelClassName}>{label}</span>
          ) : (
            <LocalizedText
              tag="span"
              className={labelClassName}
              variables={variables}
            >
              {label}
            </LocalizedText>
          )
        ) : label !== null ? (
          <span />
        ) : null}
        {isString(children) || isNumber(children) ? (
          <span>{children}</span>
        ) : (
          React.Children.map(children, (child) => {
            let newChild = child;
            if (
              child &&
              child.type &&
              (child.type.displayName === 'LabeledItem.Statement.True' ||
                child.type.displayName === 'LabeledItem.Statement.False')
            ) {
              const shown = this.checkIfTrue(
                child.type.displayName === 'LabeledItem.Statement.True'
              );

              newChild = React.cloneElement(child, {
                ...child.props,
                shown,
              });
            }

            return newChild;
          })
        )}
      </Container>
    );
  }
}

LabeledItem.propTypes = {
  forwardRef: PropTypes.any,
  hidden: PropTypes.bool,
  noTranslate: PropTypes.bool,
  component: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  icon: PropTypes.string,
  icons: PropTypes.array,
  variables: PropTypes.object,
  iconComponent: PropTypes.object,
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconProps: PropTypes.object,
  check: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

class Admin extends React.Component {
  render() {
    if (!this.context.isAdmin) {
      return null;
    }

    const { children } = this.props;

    return isString(children) || isNumber(children) ? (
      <span>{children}</span>
    ) : (
      children
    );
  }
}

Admin.propTypes = {
  children: PropTypes.any,
};
Admin.contextType = Context;
class User extends React.Component {
  render() {
    if (!!this.context.isAdmin) {
      return null;
    }

    const { children } = this.props;

    return isString(children) || isNumber(children) ? (
      <span>{children}</span>
    ) : (
      children
    );
  }
}

User.propTypes = {
  children: PropTypes.any,
};
User.contextType = Context;

class IfTrue extends React.Component {
  render() {
    const { children, shown } = this.props;

    return <>{shown ? children : null}</>;
  }
}
IfTrue.propTypes = {
  children: PropTypes.any,
  shown: PropTypes.bool,
};
IfTrue.displayName = 'LabeledItem.Statement.True';

class IfFalse extends React.Component {
  render() {
    const { children, shown } = this.props;

    return <>{shown ? children : null}</>;
  }
}
IfFalse.propTypes = {
  children: PropTypes.any,
  shown: PropTypes.bool,
};
IfFalse.displayName = 'LabeledItem.Statement.False';

LabeledItem.Admin = Admin;
LabeledItem.User = User;
LabeledItem.True = IfTrue;
LabeledItem.False = IfFalse;

export default LabeledItem;
