import { reducer } from 'redux-form';
import FormActionTypes from './form.types';

const INITIAL_STATE = {
  values: {},
  loading: false,
};

const customFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FormActionTypes.SUBMIT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case FormActionTypes.SUBMIT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    }

    case FormActionTypes.SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case FormActionTypes.SET_FORM_ERROR: {
      return {
        ...state,
        customErrors: {
          ...state.customErrors,
          ...action.payload,
        },
      };
    }

    case FormActionTypes.CHANGE: {
      return {
        ...state,
        customErrors: {},
      };
    }

    default:
      return state;
  }
};

const formReducer = reducer.plugin({
  login: customFormReducer,
  updateCharger: customFormReducer,
  profile: customFormReducer,
});

formReducer.never = ['values'];

export default formReducer;
