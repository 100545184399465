import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CARS_URL } from '../redux/app/app.utils';
import Style from './Car.module.scss';
import PKG from '../../package.json';
import LocalizedText from './LocalizedText';

function Car({ data, className, soc }) {
  if (isEmpty(data)) {
    return null;
  }

  soc = soc?.length === 2 && (soc[0] > 0 || soc[1] < 100) ? soc : undefined;

  return (
    <>
      <img
        className={`${Style.car} ${className}`}
        src={`${CARS_URL}${data.name}.png?v=${PKG.version}`}
        alt={data.name}
      />
      {soc && soc.length === 2 ? (
        <LocalizedText
          tag="span"
          className={Style.detail}
          variables={{ starting: soc[0], ending: soc[1] }}
          children="From {{starting}}% to {{ending}}%"
        />
      ) : null}
    </>
  );
}

Car.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  soc: PropTypes.array,
};

export default Car;
