const UserActionTypes = {
  LOGIN_START: 'USER/LOGIN_START',
  LOGIN_SUCCESS: 'USER/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER/LOGIN_FAILURE',

  LOGOUT_START: 'USER/LOGOUT_START',
  LOGOUT_SUCCESS: 'USER/LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USER/LOGOUT_FAILURE',

  UPDATE_START: 'USER/UPDATE_START',
  UPDATE_SUCCESS: 'USER/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER/UPDATE_FAILURE',

  REMOVE_START: 'USER/REMOVE_START',
  REMOVE_SUCCESS: 'USER/REMOVE_SUCCESS',
  REMOVE_FAILURE: 'USER/REMOVE_FAILURE',

  SET_EDIT: 'USER/SET_EDIT',
  SET_DEFAULT_CAR: 'USER/SET_DEFAULT_CAR',
  SET_DEFAULT_LANG: 'USER/SET_DEFAULT_LANG',
};

export default UserActionTypes;
