/* eslint-disable no-extend-native */
import {
  debounce,
  isEmpty,
  isEqual,
  isObject,
  isString,
  transform,
} from 'lodash';
import cssVariables from './variables.module.scss';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React from 'react';
import zlib from 'zlib';
import { unserialize } from 'php-serialize';

export const moment = extendMoment(Moment);

export const firstYear = 2020;

export const scheduledOptions = {
  immediately: 'immediately',
  until: 'until',
  solar: 'solar',
  date: 'date',
  byCar: 'byCar',
};

export const scheduledKeys = {
  '4000-01-01 00:00:00': scheduledOptions.solar,
  '3000-01-01 00:00:00': scheduledOptions.byCar,
};

export const parseScheduledKey = (key) => scheduledKeys[key];

export const inRange = (name) => (datum) => {
  const testingRegExp = /\d{4}\/\d{2}\/\d{2}\-\d{4}\/\d{2}\/\d{2}/;
  const testingMonthRangeRegExp = /\d{2}\/\d{2}\-\d{2}\/\d{2}/;
  const testerDatum = isString(datum) ? datum : datum.exactName;

  const test = {};
  if (
    testingRegExp.test(testerDatum) ||
    testingMonthRangeRegExp.test(testerDatum)
  ) {
    test.range = testerDatum;
    test.value = name;
  } else if (testingRegExp.test(name) || testingMonthRangeRegExp.test(name)) {
    test.range = name;
    test.value = testerDatum;
  }

  if (!isEmpty(test)) {
    const nameRange = test.range.split('-');
    const exactRange = moment.range(moment(nameRange[0]), moment(nameRange[1]));

    return exactRange.contains(moment(test.value));
  }

  return datum.exactName === name;
};

export const chartColors = [
  cssVariables.chart1,
  cssVariables.chart2,
  cssVariables.chart3,
  cssVariables.chart4,
  cssVariables.chart5,
  cssVariables.chart6,
  cssVariables.chart7,
  cssVariables.chart8,
];

export const colors = cssVariables;

export const rangeLabels = {
  day: '{{day}} {{month}}',
  isoWeek: 'Week {{week}}, {{year}}',
  month: '{{month}} {{year}}',
  only_month: '{{month}}',
  quarter: 'Quarter {{quarter}}, {{year}}',
  year: '{{year}}',
  all: '{{year}}',
};

String.prototype.reverse = function () {
  const splitString = this.split('');
  const reverseArray = splitString.reverse();
  const joinArray = reverseArray.join('');

  return joinArray;
};

let isMobileWidthValue = window.innerWidth <= 768;
let resizeSet = false;
export const isMobileWidth = () => {
  if (!resizeSet) {
    window.addEventListener(
      'resize',
      debounce(() => {
        isMobileWidthValue = window.innerWidth <= 768;
      }, 100)
    );

    resizeSet = true;
  }

  return isMobileWidthValue;
};

export const getCost = (
  time,
  givenConsumption,
  costPerMinute,
  costPerConsumption,
  reactiveConsumption
) => {
  let cost = 0;
  if (!!costPerMinute && costPerMinute !== '0') {
    cost += parseFloat(costPerMinute, 10) * time;
  }

  const consumption = givenConsumption - (reactiveConsumption || 0);

  if (!!costPerConsumption && costPerConsumption !== '0') {
    cost += parseFloat(costPerConsumption, 10) * consumption;
  }

  cost = Math.round(cost * 100) / 100;

  return cost;
};

export const getZip = (stat) => {
  const inflated = zlib.inflateSync(Buffer.from(stat, 'base64')).toString();

  return unserialize(inflated);
};

export const getDoc = (stat) => {
  let data = {};
  try {
    const keyChar = stat.substring(0, 1);
    const key = keyChar.charCodeAt(0);
    const loop = stat.substring(1).match(/^vd(\d+)aH/);
    const loopLength = loop[0].length;
    const loopId = parseInt(loop[1], 10);

    const stat1 = stat
      .substring(loopLength + 1, loopLength + key + 1)
      .reverse();
    const stat2 = stat.substring(loopLength + key + 1, stat.length - 20);
    const stat3 = stat.substring(stat.length - 20, stat.length - 10).reverse();
    const stat4 = stat.substring(stat.length - 10);

    let leftStat = `${stat1}${stat2}${stat3}${stat4}`;
    leftStat = leftStat.substring(0, leftStat.length / loopId);

    data = JSON.parse(window.atob(leftStat));

    return data;
  } catch (err) {
    // console.error('Cannot parse doc', err.message);
  }

  return stat;
};

export const camelCase = (s) => {
  return s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
};

export const abbrValue = (
  givenValue = 0,
  precision = 1,
  suffixes = ['', 'K', 'M']
) => {
  const value = parseFloat(givenValue);
  const flooredValue = Math.floor(value) || 0;
  const suffixNum = Math.floor(
    (`${flooredValue}`.length - (flooredValue < 0 ? 2 : 1)) / 3
  );
  let shortValue =
    suffixNum !== 0
      ? parseFloat((value / Math.pow(1000, suffixNum)).toPrecision(3))
      : value;
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(
      shortValue < 10 ? precision + 1 : precision
    );
  }
  return `${shortValue}${
    typeof suffixes === 'string' ? suffixes : suffixes[suffixNum]
  }`;
};

export const abbrPower = (value, precision) =>
  abbrValue(value, precision, ['W', 'kW', 'MW']);
export const abbrConsumption = (value, precision) =>
  abbrValue(value, precision, ['Wh', 'kWh', 'MWh']);
export const abbrVoltage = (value, precision) =>
  abbrValue(value, precision, ['V', 'kV', 'MV']);
export const abbrCurrent = (value, precision) =>
  abbrValue(value, precision, ['A', 'kA', 'MA']);

export const getScrollTop = () => {
  if (typeof window.pageYOffset != 'undefined') {
    //most browsers except IE before #9
    return window.pageYOffset;
  } else {
    const B = document.body; //IE 'quirks'
    let D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
};

export const cumulateArrayOfObjects = (data, prop) =>
  data.map((datum) => {
    datum.total = datum[prop];
    datum[prop] = datum[prop] - data[0].total;
    return datum;
  });

export const difference = (object, base) => {
  const changes = (object, base) => {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  };

  return changes(object, base);
};

export const isTrue = (value) => value === true || value === '1';
export const isFalse = (value) => value === '0' || !value;

export const getPolygons = (color = null) => {
  return (
    <g id="chevron" fill={color}>
      <rect x="0" y="0" width={1000} height={1000} opacity=".6" />
      <polygon id="Path-1" points="0 10 10 0 5 0 0 5"></polygon>
      <polygon id="Path-2" points="10 10 10 5 5 10"></polygon>
    </g>
  );
};

export const getSvg = (color, pattern = false, opacity = 1) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" style={{ opacity }}>
      {pattern ? (
        getPolygons(color)
      ) : (
        <rect x="0" y="0" width="10" height="10" fill={color} />
      )}
    </svg>
  );
};

const clickLimit = 2;
const clickTimeLimit = 300;
let clicks = 0;
let lastClickTime = 0;
let clickTick;

export const dbClick = (f) => checkClicks(f);
export const resetClicks = () => {
  clicks = 0;
  lastClickTime = 0;
};
export const checkClicks = (f) => {
  clearTimeout(clickTick);
  if (
    clicks === 0 ||
    (Date.now() - lastClickTime <= clickTimeLimit && clicks <= clickLimit)
  ) {
    clicks++;
    lastClickTime = Date.now();

    if (clicks === clickLimit) {
      typeof f === 'function' && f();
      resetClicks();
    }
  }

  clickTick = setTimeout(() => resetClicks(), clickTimeLimit + 2);
};

export const canPlayVideo = (videoElement) => {
  const mpeg4 =
    '' !== videoElement.canPlayType('video/mp4; codecs="mp4v.20.8"');
  const h264 =
    '' !==
    (videoElement.canPlayType('video/mp4; codecs="avc1.42E01E"') ||
      videoElement.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"'));
  const ogg = '' !== videoElement.canPlayType('video/ogg; codecs="theora"');
  const webm =
    '' !== videoElement.canPlayType('video/webm; codecs="vp8, vorbis"');

  return {
    mpeg4,
    h264,
    ogg,
    webm,
  };
};

export const Context = React.createContext({
  admin: false,
  toggleAdmin: () => {},
});

export const isEmptyOrNull = (obj) => {
  return isEmpty(obj) || !!Object.values(obj).every((o) => !o);
};

export const shortHexToLongHex = (value) => {
  if (value?.match(/^#[0-9a-fA-F]{3}$/)) {
    return value.replace(/[0-9a-fA-F]{1}/g, (m) => m + m);
  }

  return value;
};
