import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Cell,
  LabelList,
} from 'recharts';

import {
  abbrConsumption,
  chartColors,
  colors,
  getDoc,
  isMobileWidth,
  rangeLabels,
  getPolygons,
  getSvg,
  moment,
  inRange,
  firstYear,
} from '../../utils';

import { find, findKey, get, maxBy, sumBy, range as lRange } from 'lodash';
import { CustomTooltip } from './CustomTooltip';
import ComboFlyout from '../../components/ComboFlyout';
import CommonChart from './CommonChart';

export default class CompareBar extends CommonChart {
  container = 'compare';

  state = {
    data: [],
    rendered: false,
    keys: {},
    years: [],
    range: 'year',
    zoomedRange: 'year',
  };

  ranges = {
    year: ['months', 'month'],
    all: ['years', 'year'],
  };

  getOpacity(index, year = 0) {
    return 1 - index / (this.state.keys[year].length * 2);
  }

  prepare(isActive = false, givenRange = null, givenZoomedRange = null) {
    if (this.props.data.length) {
      const filter = this.props.filter;
      const givenData = this.props.data.map((stat) => getDoc(stat));
      const data = [];
      const labels = {};
      const labelValues = {};

      // fake
      // const givenData = docata.reduce((acc, curr) => {
      //   const prev2020 = { ...curr };
      //   const prev2019 = { ...curr };

      //   prev2019.actual = moment(prev2020.actual * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.started = moment(prev2020.started * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.ended = moment(prev2020.ended * 1000)
      //     .year(2019)
      //     .subtract(1, 'days')
      //     .unix();
      //   prev2020.actual = moment(prev2020.actual * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.started = moment(prev2020.started * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.ended = moment(prev2020.ended * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   return [...acc, curr, prev2019, prev2020];
      //   // return [...acc, curr];
      // }, []);

      givenData.sort((a, b) => {
        if (a.started < b.started) {
          return 1;
        }
        if (a.started > b.started) {
          return -1;
        }
        return 0;
      });

      let currentMonth = findKey(filter[this.container], (item) => !!item);
      const range = givenRange || this.getRange();
      const zoomedRange = givenZoomedRange || range;
      const firstChargingDayTotal = moment(
        givenData[givenData.length - 1].started * 1000
      ).startOf(zoomedRange);
      const lastChargingDayTotal = moment(givenData[0].started * 1000).endOf(
        zoomedRange
      );

      const firstChargingDay = moment(
        givenData[givenData.length - 1].started * 1000
      )
        .year(firstYear)
        .startOf(zoomedRange);
      const lastChargingDay = moment(givenData[0].started * 1000)
        .year(firstYear)
        .endOf(zoomedRange);

      currentMonth = currentMonth || Date.now();

      if (
        moment(currentMonth)
          .year(firstYear)
          .endOf(range)
          .diff(lastChargingDay, 'days') > 0
      ) {
        currentMonth = lastChargingDay.clone();
      }

      const firstDay = moment(currentMonth)
        .year(firstYear)
        .startOf(range === 'all' ? 'year' : range);
      const lastDay = moment(currentMonth)
        .year(firstYear)
        .endOf(range === 'all' ? 'year' : range);

      const chargingRange = moment.range(firstDay, lastDay);

      const diffDays =
        lastChargingDay.diff(firstChargingDay, this.ranges[zoomedRange][0]) + 1;

      const diffYears = [
        firstChargingDayTotal.format('YYYY'),
        lastChargingDayTotal.format('YYYY'),
      ];
      const years = lRange(diffYears[0], parseInt(diffYears[1], 10) + 1);

      years.forEach((year, i) => {
        labelValues[i] = translate(rangeLabels.year, {
          year,
        });
      });

      for (let i = 0; i < diffDays; i++) {
        const name = firstChargingDay.local();
        const label = moment(name).startOf(zoomedRange).format('MM/DD');

        labels[label] =
          labels[label] ||
          (filter[this.container] && filter[this.container][label] !== undefined
            ? filter[this.container][label]
            : false);

        const current = {
          name: label,
          exactName:
            this.ranges[zoomedRange][0] === 'days'
              ? name.format('MM/DD')
              : // eslint-disable-next-line max-len
                `${moment(name)
                  .startOf(this.ranges[zoomedRange][1])
                  .format('MM/DD')}-${moment(name)
                  .endOf(this.ranges[zoomedRange][1])
                  .format('MM/DD')}`,
          hidden: true,
          groups: [],
        };

        if (chargingRange.contains(firstChargingDay)) {
          current.hidden = false;
        }

        // const globalColorIndex = Object.keys(labels).indexOf(label);
        years.forEach((year, index) => {
          const payloadKey = `payload${year}`;
          const stacksKey = `stacks${year}`;
          const highLightColor = `highLightColor${year}`;
          const highLightPattern = `highLightPattern${year}`;
          current.groups.push(year);
          if (!current[payloadKey]) {
            current[payloadKey] = [];
          }
          if (!current[stacksKey]) {
            current[stacksKey] = [];
          }
          // const colorIndex = (globalColorIndex + index) % chartColors.length;
          const colorIndex = index % chartColors.length;
          current[highLightColor] = chartColors[colorIndex];
          current[highLightPattern] = `url(#multiDay-${colorIndex})`;
        });

        data.push(current);

        firstChargingDay.add(1, this.ranges[zoomedRange][0]);
      }

      const keys = {};
      givenData.reverse().forEach((item) => {
        const { consumption, actual } = item;
        const name = moment(actual * 1000)
          .local()
          .format('MM/DD');

        const year = moment(actual * 1000)
          .local()
          .format('YYYY');

        const current = find(data, inRange(name));

        if (current) {
          this.addItemToStack(
            current,
            item,
            { consumption, group: year },
            keys,
            this.ranges[zoomedRange][0] !== 'days'
          );
        }
      });

      this.setState({
        data,
        keys,
        range,
        years,
        zoomedRange,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  addItemToStack(
    datum,
    item,
    { consumption, group },
    keys = {},
    avoidStack = false
  ) {
    const payloadKey = `payload${group}`;
    const stacksKey = `stacks${group}`;
    const newIndex = avoidStack ? 0 : datum[stacksKey].length;
    const dataKey = `c${group}${newIndex}`;

    if (!keys[group]) {
      keys[group] = [];
    }

    if (!keys[group].includes(dataKey)) {
      keys[group].push(dataKey);
    }

    datum[stacksKey].push(dataKey);
    datum[dataKey] = (datum[dataKey] || 0) + consumption;

    item.dataKey = `${item.isPlaceHolder ? 'p' : ''}${dataKey}`;
    item.dataIndex = newIndex;

    datum[payloadKey].push(item);
  }

  render() {
    const data = this.state.data.filter((datum) => !datum.hidden);
    const max = maxBy(data, (datum) => {
      return datum.groups
        .map((group) => datum[`payload${group}`])
        .flat()
        .reduce((accu, curr) => accu + curr.consumption, 0);
    });
    const maxValue =
      max && max.groups
        ? max.groups.reduce(
            (keyAccu, key) =>
              keyAccu +
              max[`payload${key}`].reduce((accu, curr) => {
                return accu + curr.consumption;
              }, 0),
            0
          )
        : 0;
    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">Charged energy comparer</LocalizedText>
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                id="range"
                label={translate('Range')}
                value={this.state.range}
                onChange={(e) => this.onRangeChange(e)}
                options={{
                  year: translate('Yearly'),
                  all: translate('All'),
                }}
              />
            </h3>
            <ResponsiveContainer
              className={`${Style.responsiveContainer} ${Style.calendarBar}`}
            >
              <BarChart
                className={Style.mainChart}
                width="80%"
                height="100%"
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
              >
                <defs>
                  {chartColors.map((color, cIndex) => (
                    <pattern
                      key={cIndex}
                      id={`multiDay-${cIndex}`}
                      x="0"
                      y="0"
                      patternUnits="userSpaceOnUse"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      {getPolygons(color)}
                    </pattern>
                  ))}
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="exactName" />
                <YAxis
                  hide={isMobileWidth()}
                  tickFormatter={(value) => {
                    return abbrConsumption(value > 0 ? value : 0);
                  }}
                  axisLine={false}
                  tickLine={false}
                  orientation="left"
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label) =>
                    translate('Charged energy {{label}}', {
                      label,
                    })
                  }
                  content={
                    <CustomTooltip
                      prependPayload={[
                        {
                          name: 'Total',
                          validator: (payload) => {
                            return payload && payload.length; // > 1;
                          },
                        },
                        // ...this.state.years.map((year) => ({
                        //   name: year,
                        // })),
                      ]}
                    />
                  }
                  itemStyle={{ textAlign: 'left' }}
                  formatter={(value, name, entry) => {
                    if (name === 'Total') {
                      const total = sumBy(
                        entry.payload.filter(
                          (item) => item.name.indexOf('c') === 0
                        ),
                        'value'
                      );
                      return [abbrConsumption(total), translate(name)];
                    } else if (this.ranges[this.state.range][0] === 'days') {
                      const year = name.substr(1, 4);

                      const entryPayload = find(
                        get(entry, `payload.payload${year}`),
                        (payload) => {
                          return payload.dataKey === name;
                        }
                      );

                      if (entryPayload) {
                        const subTotal =
                          entryPayload.total !== value
                            ? ` (${abbrConsumption(entryPayload.total)})`
                            : '';

                        const icons = {
                          pattern: getSvg(
                            entry.payload[`highLightColor${year}`],
                            true,
                            this.getOpacity(entryPayload.dataIndex, year)
                          ),
                          color: getSvg(
                            entry.payload[`highLightColor${year}`],
                            false,
                            this.getOpacity(entryPayload.dataIndex, year)
                          ),
                        };

                        return [
                          <>
                            {entryPayload.notOverday
                              ? icons.pattern
                              : icons.color}
                            {abbrConsumption(value)}
                            <b>{subTotal}</b>
                          </>,
                          // eslint-disable-next-line max-len
                          `${moment(entryPayload.started * 1000)
                            .local()
                            .format('YYYY/MM/DD HH:mm')} - ${moment(
                            entryPayload.ended * 1000
                          )
                            .local()
                            .format('YYYY/MM/DD HH:mm')}`,
                        ];
                      }
                    }

                    return [];
                  }}
                />

                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '1em auto auto',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.entries(this.state.labelValues || {}).map(
                    (entry, index) => ({
                      label: entry[1],
                      value: entry[0],
                      type: 'square',
                      color: chartColors[index % chartColors.length],
                      container: this.container,
                    })
                  )}
                  // onClick={(o) =>
                  //   this.props.onClick(o, this.state.labels, true)
                  // }
                  // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, label } = entry;
                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                />

                {Object.entries(this.state.keys).map(([year, keys]) =>
                  keys.map((key, index) => (
                    <Bar
                      key={key}
                      dataKey={key}
                      stackId={`consumption${year}`}
                      opacity={this.getOpacity(index, year)}
                      cursor="pointer"
                    >
                      <LabelList
                        dataKey={key}
                        position="center"
                        angle="-90"
                        fill={colors.ownWhite}
                        stroke={colors.darkGrey}
                        strokeWidth={3}
                        paintOrder="stroke"
                        formatter={(value, entry) => {
                          if (
                            !value ||
                            value < maxValue / (isMobileWidth() ? 3 : 3.5)
                          ) {
                            return '';
                          }

                          return abbrConsumption(value);
                        }}
                      />
                      {data.map((datum, cellIndex) => {
                        const notOverday = get(
                          datum,
                          `payload${year}.${index}.notOverday`
                        );
                        const isPlaceHolder = get(
                          datum,
                          `payload${year}.${index}.isPlaceHolder`
                        );

                        return (
                          <Cell
                            key={`cell-${cellIndex}`}
                            fill={
                              isPlaceHolder
                                ? 'transparent'
                                : notOverday &&
                                  this.ranges[this.state.range][0] === 'days'
                                ? datum[`highLightPattern${year}`]
                                : datum[`highLightColor${year}`]
                            }
                          />
                        );
                      })}
                    </Bar>
                  ))
                )}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

CompareBar.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  onRangeChange: () => {},
  isChecked: () => false,
};

CompareBar.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
  // onHover: PropTypes.func,
  onHoverChart: PropTypes.func,
  onRangeChange: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
  range: PropTypes.object,
};
