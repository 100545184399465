import PropTypes from 'prop-types';
import {
  FcEmptyBattery,
  FcLowBattery,
  FcMiddleBattery,
  FcHighBattery,
  FcFullBattery,
} from 'react-icons/fc';
import Icon from './Icon';
import Style from './BatteryIcon.module.scss';
// import { translate } from './LocalizedText';
import { abbrConsumption } from '../utils';
import LabeledItem from './LabeledItem';
import { DEFAULT_CAR_CAPACITY } from '../redux/app/app.utils';

function BatteryIcon({
  stopped,
  soc: givenSoc,
  startingSOC: givenStartingSoc,
  max: givenMax,
  className = '',
  animated = true,
}) {
  const max = givenMax > 0 ? givenMax : null;
  const startingSOC = givenStartingSoc > 0 ? givenStartingSoc / 100 : 0;
  let soc = givenSoc;
  let socPercent = soc / (max || DEFAULT_CAR_CAPACITY);

  let ignorePercentage = true;
  if (startingSOC > 0 && socPercent + startingSOC <= 1) {
    socPercent += startingSOC;
    soc = parseFloat(soc, 10) + max * startingSOC;
    // max = parseFloat(max, 10) + max * startingSOC;
    ignorePercentage = false;
  }

  let icon = FcEmptyBattery;
  let nextIcon = FcLowBattery;

  if (stopped) {
    icon = FcEmptyBattery;
    nextIcon = null;
  } else {
    if (socPercent > 0.25) {
      icon = FcLowBattery;
      nextIcon = FcMiddleBattery;
    }
    if (socPercent > 0.5) {
      icon = FcMiddleBattery;
      nextIcon = FcHighBattery;
    }
    if (socPercent > 0.75) {
      icon = FcHighBattery;
      nextIcon = FcFullBattery;
    }
    if (socPercent >= 1) {
      icon = FcFullBattery;
      nextIcon = null;
    }
  }

  const realSocPercent = socPercent * 100;

  return (
    <LabeledItem className={`${Style.batteryIcon} ${className}`} icon={icon}>
      {nextIcon ? (
        <Icon
          icon={nextIcon}
          className={`${Style.pulse} ${!animated ? Style.halved : ''}`}
        />
      ) : null}
      <span className={Style.energyInfo}>
        <span>{abbrConsumption(soc)}</span>
        {max && !ignorePercentage ? (
          <span>{realSocPercent.toFixed(realSocPercent % 1 ? 2 : 0)}%</span>
        ) : (
          ''
        )}
        {/* {abbrConsumption(soc)}{max ? translate('of {{nr}}', { nr: abbrConsumption(max) }) : ''} */}
      </span>
    </LabeledItem>
  );
}

BatteryIcon.propTypes = {
  className: PropTypes.string,
  stopped: PropTypes.bool,
  startingSOC: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  soc: PropTypes.number,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  animated: PropTypes.bool,
};

export default BatteryIcon;
