import { findIndex, get, isEmpty, merge } from 'lodash';
import UserActionTypes from '../user/user.types';
import AppActionTypes from './app.types';
import { DEFAULT_LANGUAGE } from './app.utils';

export const INITIAL_STATE = {
  clientId: null,
  chargerId: null,
  learning: 0,
  storedCar: null,
  editOverlay: null,
  language: DEFAULT_LANGUAGE,
  chartRanges: {},
  selectedChart: null,
  isLoading: {
    status: true,
    charging: false,
    chargers: false,
    chargings: false,
  },
  isLoadingHidden: {
    status: false,
    charging: false,
    chargers: false,
    chargings: false,
  },
  error: null,
  errorTime: null,
  eligibleToStart: null,
  eligibleToStop: null,
  isRequested: null,
  charger: {
    status: 'Connecting',
  },
  charging: {
    started: null,
    stopped: null,
    consumptionAtStart: null,
  },
  chargers: [],
  history: [],
  users: [],
  historyFilter: {},
  stat: [],
  solarStat: [],
  deviceTemperatureStat: [],
  deviceStat: {},
  page: {},
  response: {},
};

const appReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AppActionTypes.SET_UUID: {
      return {
        ...state,
        clientId: state.clientId || payload,
      };
    }

    case AppActionTypes.SET_CHART_RANGE: {
      return {
        ...state,
        chartRanges: merge({}, state.chartRanges, payload),
      };
    }

    case AppActionTypes.SET_SELECTED_CHART: {
      return {
        ...state,
        selectedChart: payload,
      };
    }

    case AppActionTypes.SET_CAR: {
      return {
        ...state,
        storedCar: payload,
      };
    }

    case AppActionTypes.PRE_FILTER_HISTORY: {
      if (
        payload.type &&
        state.historyFilter.type &&
        state.historyFilter.type === payload.type &&
        state.historyFilter.range &&
        state.historyFilter.range === payload.range
      ) {
        return state;
      }

      const data = (payload.data || []).map((item) => ({
        ...item,
        hidden: !(
          state.historyFilter.data === undefined ||
          state.historyFilter.data[item.name] === undefined ||
          !!state.historyFilter.data[item.name]
        ),
      }));

      return {
        ...state,
        historyFilter: {
          ...state.historyFilter,
          ...payload,
          data,
        },
      };
    }

    case AppActionTypes.FILTER_HISTORY: {
      const data = (state.historyFilter.data || []).map((item) => ({
        ...item,
        hidden: !(
          payload.filters[item.name] === undefined ||
          !!payload.filters[item.name]
        ),
      }));

      return {
        ...state,
        historyFilter: {
          type: payload.type,
          data,
        },
      };
    }

    case AppActionTypes.RESET_HISTORY: {
      return {
        ...state,
        history: [],
        historyFilter: {},
        stat: [],
        solarStat: [],
        deviceTemperatureStat: [],
        deviceStat: {},
        page: {
          ...state.page,
          history: {},
        },
      };
    }

    case AppActionTypes.CHARGING_START:
    case AppActionTypes.CHARGING_STOP:
    case AppActionTypes.FETCH_STATUS_START:
    case AppActionTypes.FETCH_HISTORY_START:
    case AppActionTypes.FETCH_STAT_START:
    case AppActionTypes.FETCH_USERS_START:
    case AppActionTypes.FETCH_CHARGERS_START: {
      return {
        ...state,
        learning: [
          AppActionTypes.CHARGING_START,
          AppActionTypes.CHARGING_STOP,
        ].includes(type)
          ? state.learning + 1
          : state.learning,
        isLoading: {
          ...state.isLoading,
          [payload.endpoint]:
            payload && payload.hidden
              ? state.isLoading[payload.endpoint]
              : true,
        },
        isLoadingHidden: {
          ...state.isLoadingHidden,
          [payload.endpoint]:
            payload && payload.hidden
              ? true
              : state.isLoadingHidden[payload.endpoint],
        },
        error: null,
      };
    }
    case AppActionTypes.REMOVE_HISTORY_SUCCESS: {
      const index = findIndex(state.history, { id: payload.response.removed });

      if (index > -1) {
        state.history.splice(index, 1);
      }

      return {
        ...state,
        history: [...state.history],
        page: {
          ...state.page,
          history: {
            ...(state.page.history || {}),
            total: (state.page.history || {}).total - 1,
          },
        },
      };
    }
    case AppActionTypes.FETCH_STATUS_SUCCESS:
    case AppActionTypes.FETCH_HISTORY_SUCCESS:
    case AppActionTypes.FETCH_STAT_SUCCESS:
    case AppActionTypes.FETCH_USERS_SUCCESS:
    case AppActionTypes.FETCH_CHARGERS_SUCCESS:
    case AppActionTypes.CHARGING_START_SUCCESS:
    case AppActionTypes.CHARGING_STOP_SUCCESS:
    case AppActionTypes.UPDATE_SUCCESS: {
      const { endpoint, response } = payload;
      const chargingId = get(response, 'charging.id');
      const sameCharging =
        state.charging.id === chargingId || !state.charging.id || !chargingId;

      return {
        ...state,
        ...response,
        isLoading: {
          ...state.isLoading,
          [endpoint]: false,
        },
        isLoadingHidden: {
          ...state.isLoadingHidden,
          [endpoint]: false,
        },
        eligibleToStart:
          response.eligibleToStart !== undefined
            ? !!response.eligibleToStart
            : state.eligibleToStart,
        eligibleToStop:
          response.eligibleToStop !== undefined
            ? !!response.eligibleToStop
            : state.eligibleToStop,
        isRequested:
          response.isRequested !== undefined
            ? !!response.isRequested
            : state.isRequested,
        [endpoint]: response[endpoint],
        charger: {
          ...state.charger,
          ...(response.charger || {}),
          location: get(response, 'charger.location')
            ? response.charger.location
            : state.charger.location,
          status: get(response, 'charger.status')
            ? response.charger.status
            : state.charger.status,
          active: get(response, 'charger.active')
            ? response.charger.active
            : state.charger.active,
          contactor: get(response, 'charger.contactor')
            ? response.charger.contactor
            : state.charger.contactor,
          emeter: null,
          // solarMeter: null,
        },
        charging: {
          ...state.charging,
          ...(response.charging || {}),
          scheduled: get(response, 'charging.scheduledStartAt')
            ? response.charging.scheduledStartAt
            : sameCharging && state.charging.scheduled,
          scheduledEnd: get(response, 'charging.scheduledEndAt')
            ? response.charging.scheduledEndAt
            : sameCharging && state.charging.scheduledEnd,
          pausedUntil:
            get(response, 'charging.pausedUntil') !== undefined
              ? response.charging.pausedUntil
              : sameCharging && state.charging.pausedUntil,
          started: get(response, 'charging.startedAt')
            ? response.charging.startedAt
            : sameCharging && state.charging.started,
          stopped: get(response, 'charging.endedAt')
            ? response.charging.endedAt
            : sameCharging && state.charging.stopped,
          consumptionAtStart: get(response, 'charging.consumptionAtStart')
            ? response.charging.consumptionAtStart
            : sameCharging && state.charging.consumptionAtStart,
        },
        history: [...state.history, ...(response.history || [])],
        users: response.users || state.users,
        stat:
          response.stat && response.stat.length ? response.stat : state.stat,
        solarStat:
          response.solarStat && !isEmpty(response.solarStat)
            ? response.solarStat
            : state.solarStat,
        deviceStat:
          response.deviceStat && !isEmpty(response.deviceStat)
            ? response.deviceStat
            : state.deviceStat,
        deviceTemperatureStat:
          response.deviceTemperatureStat &&
          !isEmpty(response.deviceTemperatureStat)
            ? response.deviceTemperatureStat
            : state.deviceTemperatureStat,
        page: {
          ...state.page,
          [payload.endpoint]: get(response, 'page')
            ? response.page
            : state.page[payload.endpoint],
        },
        error:
          state.errorTime > Date.now() - 10000 &&
          type === AppActionTypes.FETCH_STATUS_SUCCESS
            ? state.error
            : null,
      };
    }

    case AppActionTypes.FETCH_STATUS_FAILURE:
    case AppActionTypes.FETCH_HISTORY_FAILURE:
    case AppActionTypes.FETCH_STAT_FAILURE:
    case AppActionTypes.FETCH_USERS_FAILURE:
    case AppActionTypes.FETCH_CHARGERS_FAILURE:
    case AppActionTypes.CHARGING_START_FAILURE:
    case AppActionTypes.CHARGING_STOP_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [payload.endpoint]: false,
        },
        isLoadingHidden: {
          ...state.isLoadingHidden,
          [payload.endpoint]: false,
        },
        error: payload.error,
        errorTime: Date.now(),
      };
    }

    case UserActionTypes.REMOVE_SUCCESS: {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
      };
    }

    case AppActionTypes.ADD_HISTORY_ITEM: {
      return {
        ...state,
        history: [
          {
            ...payload,
            index: 60,
          },
          ...state.history,
        ],
        page: {
          ...state.page,
          history: {
            ...(state.page.history || {}),
            total: parseInt((state.page.history || {}).total, 10) + 1,
          },
        },
      };
    }

    default:
      return state;
  }
};

appReducer.permanent = [
  'clientId',
  'storedCar',
  'learning',
  'language',
  'chartRanges',
  'selectedChart',
];
appReducer.never = [
  'isLoading',
  'isLoadingHidden',
  'response',
  'history',
  'stat',
  'deviceTemperatureStat',
  'solarStat',
  'deviceStat',
  'charging',
  'charger',
];

export default appReducer;
