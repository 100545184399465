import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import LocalizedText from './LocalizedText';

import Style from './ConfirmationDialog.module.scss';

const ConfirmationDialog = ({
  onCancel,
  onConfirm,
  open,
  children,
  okText,
  cancelText,
  title,
  ...other
}) => {
  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      PaperProps={{
        classes: {
          root: Style.dialog,
        },
      }}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <LocalizedText>{title}</LocalizedText>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {onCancel ? (
          <Button autoFocus onClick={handleCancel} color="primary">
            <LocalizedText>{cancelText}</LocalizedText>
          </Button>
        ) : null}
        {onConfirm ? (
          <Button onClick={handleOk} color="primary">
            <LocalizedText>{okText}</LocalizedText>
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  onCancel: () => {},
};

ConfirmationDialog.propTypes = {
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default ConfirmationDialog;
