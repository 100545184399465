import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  fetchStatStart,
  filterHistory,
  preFilterHistory,
  setChartRange,
  setSelectedChart,
} from '../../redux/app/app.actions';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import ConsumptionBar from './ConsumptionBar';
import CalendarBar from './CalendarBar';
import { forEach, isEmpty } from 'lodash';
import UserPie from './UserPie';
import SolarBar from './SolarBar';
import {
  selectChartRanges,
  selectSelectedChart,
} from '../../redux/app/app.selectors';
import TemperatureLine from './TemperatureLine';
import SolarPowerLine from './SolarPowerLine';
import Loader from '../../components/Loader';
import DeviceStatus from './DeviceStatus';
import { colors, isTrue } from '../../utils';
import {
  selectDeviceLang,
  selectUserProfile,
} from '../../redux/user/user.selectors';
import CompareBar from './CompareBar';

SwiperCore.use([Navigation, Pagination]);

class SumChart extends React.Component {
  state = {
    labels: {},
    active: 0,
  };

  componentDidMount() {
    this.chargerId = this.props.match.params.chargerId;

    this.checkFetch(this.props.ready);
  }

  componentDidUpdate(prevProps) {
    this.checkFetch(prevProps.ready !== this.props.ready && this.props.ready);
  }

  checkFetch(ready = false) {
    if (!!ready) {
      this.props.fetch({
        params: {
          chargerId: this.chargerId,
          getStat: 1,
          colorPack: JSON.stringify(colors),
        },
      });
    }
  }

  toggleLine(
    id,
    container,
    givenLabels = {},
    single = false,
    enableFiltering = true
  ) {
    const labels = {
      [container]: {
        ...givenLabels,
        ...(this.state.labels[container] || {}),
      },
    };

    if (single) {
      if (!labels[container][id]) {
        forEach(labels[container], (label, loopId) => {
          labels[container][loopId] = id === loopId;
        });

        enableFiltering && this.props.onFilter(container, labels[container]);
        this.setState({ labels: labels });
      }
    } else {
      if (
        !labels[container][id] ||
        (labels[container][id] &&
          Object.values(labels[container]).filter(Boolean).length > 1)
      ) {
        const newLabels = {
          ...labels,
          [container]: {
            ...labels[container],
            [id]: !this.isChecked(id, container),
          },
        };

        enableFiltering && this.props.onFilter(container, newLabels[container]);
        this.setState({ labels: newLabels });
      }
    }
  }

  isChecked(value, container, single = false, values = {}) {
    const labels = {
      ...Object.keys(values).reduce(
        (acc, curr) => ({ ...acc, [curr]: false }),
        {}
      ),
      ...(this.state.labels[container] || {}),
    };
    if (!single && labels[value] === undefined) {
      return true;
    }

    const labelsAsEntry = Object.entries(labels);
    if (
      labelsAsEntry.length > 0 &&
      labelsAsEntry.filter((entry) => entry[1]).length <= 0
    ) {
      return value === labelsAsEntry[labelsAsEntry.length - 1][0];
    }

    return !!labels[value];
  }

  onHover(type, labels, range) {
    if (this.lastType !== type || this.lastRange !== range) {
      this.lastType = type;
      this.lastRange = range;
      this.props.onPreFilter(type, labels, range);
    }
  }

  isActive(index) {
    return this.state.active === index;
  }

  render() {
    const { data, solarData, deviceData, deviceLang } = this.props;

    return (
      <>
        <div className={Style.Chart}>
          {isEmpty(data) || isEmpty(solarData) || isEmpty(deviceData) ? (
            <Loader />
          ) : (
            <Swiper
              className={Style.swiper}
              spaceBetween={50}
              slidesPerView={1}
              allowSlideNext
              allowSlidePrev
              edgeSwipeDetection={false}
              passiveListeners={false}
              simulateTouch={false}
              navigation
              pagination={{ clickable: true }}
              touchStartPreventDefault={false}
              initialSlide={this.props.selectedChart}
              onSlideChange={(e) => this.props.onChartSelected(e.activeIndex)}
            >
              <SwiperSlide className={Style.slider}>
                {(props) => (
                  <CalendarBar
                    data={this.props.data}
                    range={this.props.chartRanges}
                    green={this.props.green}
                    index={0}
                    lang={deviceLang}
                    filter={this.state.labels}
                    isChecked={(value, type, single, values) =>
                      this.isChecked(value, type, single, values)
                    }
                    onRangeChange={this.props.onRangeChange}
                    onClick={(entry, labels, single) => {
                      this.toggleLine(
                        entry.value,
                        entry.container,
                        labels,
                        single
                      );
                    }}
                    onHoverChart={(type, labels, range) =>
                      this.onHover(type, labels, range)
                    }
                    containerClassName={Style.container}
                    {...props}
                    // isActive={this.isActive(0)}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide className={Style.slider}>
                {(props) => (
                  <CompareBar
                    data={this.props.data}
                    range={this.props.chartRanges}
                    green={this.props.green}
                    index={1}
                    lang={deviceLang}
                    filter={this.state.labels}
                    isChecked={(value, type, single, values) =>
                      this.isChecked(value, type, single, values)
                    }
                    onRangeChange={this.props.onRangeChange}
                    onClick={(entry, labels, single) => {
                      this.toggleLine(
                        entry.value,
                        entry.container,
                        labels,
                        single
                      );
                    }}
                    onHoverChart={(type, labels, range) =>
                      this.onHover(type, labels, range)
                    }
                    containerClassName={Style.container}
                    {...props}
                    // isActive={this.isActive(0)}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide className={Style.slider}>
                {(props) => (
                  <ConsumptionBar
                    data={this.props.data}
                    range={this.props.chartRanges}
                    green={this.props.green}
                    index={2}
                    lang={deviceLang}
                    filter={this.state.labels}
                    isChecked={(value, type, values) =>
                      this.isChecked(value, type, true, values)
                    }
                    onRangeChange={this.props.onRangeChange}
                    onClick={(entry, labels) => {
                      this.toggleLine(entry.value, entry.container, labels);
                    }}
                    onHoverChart={(type, labels, range) =>
                      this.onHover(type, labels, range)
                    }
                    containerClassName={Style.container}
                    {...props}
                    // isActive={this.isActive(1)}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide className={Style.slider}>
                {(props) => (
                  <UserPie
                    data={this.props.data}
                    range={this.props.chartRanges}
                    green={this.props.green}
                    index={3}
                    lang={deviceLang}
                    filter={this.state.labels}
                    isChecked={(value, type, values) =>
                      this.isChecked(value, type, true, values)
                    }
                    onRangeChange={this.props.onRangeChange}
                    onClick={(entry, labels) => {
                      this.toggleLine(entry.value, entry.container, labels);
                    }}
                    onHoverChart={(type, labels, range) =>
                      this.onHover(type, labels, range)
                    }
                    containerClassName={Style.container}
                    {...props}
                    // isActive={this.isActive(2)}
                  />
                )}
              </SwiperSlide>
              {isTrue(this.props.userProfile.efc) ? (
                <>
                  {this.props.green ? (
                    <>
                      <SwiperSlide className={Style.slider}>
                        {(props) => (
                          <SolarBar
                            data={this.props.solarData}
                            range={this.props.chartRanges}
                            chargings={this.props.data}
                            index={3}
                            lang={deviceLang}
                            filter={this.state.labels}
                            isChecked={(value, type, single) =>
                              this.isChecked(value, type, single)
                            }
                            onRangeChange={this.props.onRangeChange}
                            onClick={(entry, labels, single) => {
                              this.toggleLine(
                                entry.value,
                                entry.container,
                                labels,
                                single
                              );
                            }}
                            onHoverChart={(type, labels, range) =>
                              this.onHover(type, labels, range)
                            }
                            containerClassName={Style.container}
                            {...props}
                            // isActive={this.isActive(3)}
                          />
                        )}
                      </SwiperSlide>
                      <SwiperSlide className={Style.slider}>
                        {(props) => (
                          <SolarPowerLine
                            data={this.props.solarData}
                            index={4}
                            lang={deviceLang}
                            filter={this.state.labels}
                            isChecked={(value, type, single) =>
                              this.isChecked(value, type, single)
                            }
                            onClick={(
                              entry,
                              labels,
                              single,
                              enableFiltering
                            ) => {
                              this.toggleLine(
                                entry.value,
                                entry.container,
                                labels,
                                single,
                                enableFiltering
                              );
                            }}
                            boostPower={
                              this.props.green && this.props.solarBoost
                            }
                            containerClassName={Style.container}
                            {...props}
                            // isActive={this.isActive(4)}
                            // onHoverChart={(type, labels, range) => this.onHover(type, labels, range)}
                          />
                        )}
                      </SwiperSlide>
                    </>
                  ) : null}
                  <SwiperSlide className={Style.slider}>
                    {(props) => (
                      <TemperatureLine
                        data={this.props.deviceTemperatureData}
                        index={5}
                        lang={deviceLang}
                        filter={this.state.labels}
                        isChecked={(value, type, single, values) =>
                          this.isChecked(value, type, single, values)
                        }
                        onClick={(entry, labels, single, enableFiltering) => {
                          this.toggleLine(
                            entry.value,
                            entry.container,
                            labels,
                            single,
                            enableFiltering
                          );
                        }}
                        containerClassName={Style.container}
                        {...props}
                        // isActive={this.isActive(4)}
                        // onHoverChart={(type, labels, range) => this.onHover(type, labels, range)}
                      />
                    )}
                  </SwiperSlide>
                  <SwiperSlide className={Style.slider}>
                    {(props) => (
                      <DeviceStatus
                        data={this.props.deviceData}
                        index={6}
                        lang={deviceLang}
                        filter={this.state.labels}
                        isChecked={(value, type, single, values) =>
                          this.isChecked(value, type, single, values)
                        }
                        onClick={(entry, labels, single, enableFiltering) => {
                          this.toggleLine(
                            entry.value,
                            entry.container,
                            labels,
                            single,
                            enableFiltering
                          );
                        }}
                        containerClassName={Style.container}
                        {...props}
                        // isActive={this.isActive(5)}
                        // onHoverChart={(type, labels, range) => this.onHover(type, labels, range)}
                      />
                    )}
                  </SwiperSlide>
                </>
              ) : null}
            </Swiper>
          )}
        </div>
      </>
    );
  }
}

SumChart.defaultProps = {
  data: [],
};

SumChart.propTypes = {
  match: PropTypes.object,
  data: PropTypes.array,
  solarData: PropTypes.array,
  deviceTemperatureData: PropTypes.array,
  deviceData: PropTypes.array,
  onFilter: PropTypes.func,
  onPreFilter: PropTypes.func,
  green: PropTypes.bool,
  deviceLang: PropTypes.string,
  solarBoost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRangeChange: PropTypes.func,
  onChartSelected: PropTypes.func,
  fetch: PropTypes.func,
  chartRanges: PropTypes.object,
  userProfile: PropTypes.object,
  selectedChart: PropTypes.any,
  ready: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  chartRanges: selectChartRanges,
  selectedChart: selectSelectedChart,
  deviceLang: selectDeviceLang,
  userProfile: selectUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options) => dispatch(fetchStatStart(options)),
  onChartSelected: (index) => dispatch(setSelectedChart(index)),
  onFilter: (type, filters) => dispatch(filterHistory({ type, filters })),
  onPreFilter: (type, data, range) =>
    dispatch(preFilterHistory({ type, data, range })),
  onRangeChange: (type, value, group) =>
    dispatch(setChartRange({ [type]: { [group]: value } })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SumChart);
