import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import packageJSON from '../../package.json';

import app from './app/app.reducers';
import user from './user/user.reducers';
import form from './form/form.reducers';
import { each } from 'lodash';

const reducers = {
  app,
  user,
  form,
};

each(reducers, (reducer, name) => {
  const { permanent, always, never } = reducers[name];
  if (permanent || always) {
    const temporaryConfig = {
      key: `${name}-v${packageJSON.version}`,
      storage: sessionStorage,
      blacklist: [].concat(permanent || [], never || []),
    };
    const permanentConfig = {
      key: `${name}-permanent-v${packageJSON.version}`,
      storage,
      blacklist: never,
      whitelist: permanent,
    };
    reducers[`${name}Permanent`] = persistReducer(
      permanentConfig,
      reducers[name]
    );
    reducers[name] = persistReducer(temporaryConfig, reducers[name]);
  }
});

const rootPersistConfig = {
  key: `root-v${packageJSON.version}`,
  storage,
  whitelist: [],
  transforms: [],
  stateReconciler: autoMergeLevel1,
};

const rootRedcuer = combineReducers({
  ...reducers,
});

export default persistReducer(rootPersistConfig, rootRedcuer);
