import PropTypes from 'prop-types';
import { isArray, isString, map } from 'lodash';
import { useEffect, useState } from 'react';
import Style from './Icon.module.scss';
import * as Icons from '../containers/icons/all';

function Icon({
  children,
  icon,
  isSup,
  isInRow,
  className,
  duration = 2000,
  ...props
}) {
  const [currentIcon, setCurrentIcon] = useState(0);

  let icons = icon;
  if (!isArray(icons)) {
    icons = [icons];
  }

  icons = icons.map((iconData) => {
    let IconComponent = iconData;
    if (isString(iconData)) {
      const iconName = iconData.match(
        /^([A-Z][a-z]+)\/?([A-Z0-9][a-zA-Z0-9]*)/
      );

      if (iconName) {
        IconComponent =
          (Icons[iconName[1]] &&
            (Icons[iconName[1]][iconName[0]] ||
              Icons[iconName[1]][iconName[2]])) ||
          null;
      }
    }

    return IconComponent;
  });

  useEffect(() => {
    if (icons.length > 1) {
      setTimeout(
        () =>
          setCurrentIcon(currentIcon + 1 >= icons.length ? 0 : currentIcon + 1),
        duration
      );
    }
  }, [currentIcon, icons, duration]);

  const CustomIcon =
    icons[currentIcon] || icons[icons.length - 1] || (() => null);

  return (
    <span
      className={`icon ${Style.icon} ${isSup ? Style.superIcon : ''} ${
        isInRow ? Style.inRowIcon : ''
      } ${className}`}
      {...props}
    >
      {children}
      <CustomIcon />
    </span>
  );
}

Icon.propTypes = {
  children: PropTypes.any,
  isSup: PropTypes.bool,
  isInRow: PropTypes.bool,
  icon: PropTypes.any,
  className: PropTypes.string,
  duration: PropTypes.any,
};

export default Icon;

export const Group = ({
  children,
  icons,
  useSups,
  useInRow,
  className,
  iconClassName: givenIconClassName,
  duration = 2000,
}) => {
  return (
    <>
      <span className={`${Style.iconGroup} ${className}`}>
        {(icons || []).map((icon, index) => {
          const iconClassName = isArray(givenIconClassName)
            ? givenIconClassName[index]
            : givenIconClassName;
          return (
            <Icon
              key={index}
              icon={icon}
              isSup={index > 0 && useSups}
              isInRow={useInRow}
              className={iconClassName}
              duration={duration}
            >
              {children}
            </Icon>
          );
        })}
      </span>
    </>
  );
};

Group.propTypes = {
  children: PropTypes.any,
  useSups: PropTypes.bool,
  useInRow: PropTypes.bool,
  icons: PropTypes.array,
  className: PropTypes.string,
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  duration: PropTypes.any,
};

Icon.Group = Group;

export const Gradient = ({ colors = {} }) => (
  <svg style={{ width: 0, height: 0 }} aria-hidden="true" focusable="false">
    <linearGradient id="boostGradient" x2="1" y2="1">
      {map(colors, (value, key) => (
        <stop key={key} offset={key} stopColor={value} />
      ))}
    </linearGradient>
  </svg>
);

Gradient.propTypes = {
  colors: PropTypes.object,
};
