import { call, all, put, takeLatest, select, delay } from 'redux-saga/effects';

import { v4 as uuidv4 } from 'uuid';
import UserActionTypes from './user.types';
import * as UserActions from './user.actions';
import { selectChargerId, selectClientId } from '../app/app.selectors';
import AppActionTypes from '../app/app.types';
import { IS_ADMIN, IS_DEBUG, postAPI } from '../app/app.utils';
import { getAuthBody, getAuthHeader, parseJWT } from './user.utils';
import { selectIsUserLoggedIn, selectUserToken } from './user.selectors';
import { random } from 'lodash';
import i18n from '../../i18n';
import { fetchStatusSuccess } from '../app/app.actions';
import { setFormError } from '../form/form.actions';

export function* doUpdate({ payload }) {
  const { body = {}, create = false } = payload;

  try {
    const chargerId = yield select(selectChargerId);
    const token = yield select(selectUserToken);
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActionTypes.setUUID(clientId));
    }

    if (token) {
      const passingParams = {
        run: true,
        ts: IS_ADMIN ? Date.now() : `${Date.now()}${random(1, 9)}`,
        clientId,
        chargerId,
      };

      if (IS_DEBUG) {
        passingParams.debug = 'on';
      }

      const response = yield call(
        postAPI,
        'user/update',
        {
          headers: getAuthHeader(token),
          params: passingParams,
        },
        { ...body, create }
      );

      if (response.status !== 200) {
        throw new Error(
          `${response.status} ${response.statusText}. Please try again.`
        );
      }

      const successPayload = {};
      if (body.defaultCarId) {
        successPayload.carId = body.defaultCarId;
      }
      if (body.defaultLang) {
        successPayload.lang = body.defaultLang;
      }
      if (body.smartStartStop !== undefined) {
        successPayload.smart = body.smartStartStop ? '1' : '0';
      }
      if (body.smartKey) {
        successPayload.smartKey = body.smartKey;
      }
      if (response.token) {
        successPayload.token = response.token;
      }

      yield put(UserActions.updateSuccess(successPayload));
    } else {
      throw new Error('Missing token');
    }
  } catch (error) {
    yield put(
      UserActions.updateFailure(
        typeof error === 'string' ? error : error.message
      )
    );
  }
}

export function* doRemove({ payload }) {
  const { body = {} } = payload;

  try {
    const chargerId = yield select(selectChargerId);
    const token = yield select(selectUserToken);
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActionTypes.setUUID(clientId));
    }

    if (IS_ADMIN && token) {
      const passingParams = {
        run: true,
        ts: `${Date.now()}${random(1, 9)}`,
        clientId,
        chargerId,
      };

      if (IS_DEBUG) {
        passingParams.debug = 'on';
      }

      const response = yield call(
        postAPI,
        'user/remove',
        {
          headers: getAuthHeader(token),
          params: passingParams,
        },
        { ...body }
      );

      if (response.status !== 200) {
        throw new Error(
          `${response.status} ${response.statusText}. Please try again.`
        );
      }

      yield put(UserActions.removeSuccess(body.id));
    } else {
      throw new Error('Missing token');
    }
  } catch (error) {
    yield put(
      UserActions.removeFailure(
        typeof error === 'string' ? error : error.message
      )
    );
  }
}

export function* doLogout() {
  try {
    const chargerId = yield select(selectChargerId);
    const token = yield select(selectUserToken);
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActionTypes.setUUID(clientId));
    }

    if (token) {
      const passingParams = {
        run: true,
        ts: IS_ADMIN ? Date.now() : `${Date.now()}${random(1, 9)}`,
        clientId,
        chargerId,
        ...getAuthBody(token),
      };

      if (IS_DEBUG) {
        passingParams.debug = 'on';
      }

      const response = yield call(
        postAPI,
        'auth',
        {
          headers: getAuthHeader(token),
          params: passingParams,
        },
        { logout: true }
      );

      if (response.status !== 200) {
        throw new Error(
          `${response.status} ${response.statusText}. Please try again.`
        );
      }
    }

    yield put(UserActions.logoutSuccess());
  } catch (error) {
    yield put(
      UserActions.logoutFailure(
        typeof error === 'string' ? error : error.message
      )
    );
  }
}

export function* doLogin({ payload }) {
  const { options } = payload;
  const { headers = {}, body, params = {} } = options || {};
  try {
    const chargerId = yield select(selectChargerId);
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActionTypes.setUUID(clientId));
    }

    const passingParams = {
      run: true,
      ts: IS_ADMIN ? Date.now() : `${Date.now()}${random(1, 9)}`,
      clientId,
      chargerId,
      ...params,
    };

    if (IS_DEBUG) {
      passingParams.debug = 'on';
    }

    const response = yield call(
      postAPI,
      'auth',
      {
        params: passingParams,
        headers,
      },
      body
    );

    if (response.token) {
      const tokenData = parseJWT(response.token);

      i18n.changeLanguage(tokenData.lang);

      yield put(
        UserActions.loginSuccess({
          ...tokenData,
          token: response.token,
        })
      );
      yield put(fetchStatusSuccess({ endpoint: 'status', response }));
    } else {
      throw new Error('Token is missing');
    }
  } catch (error) {
    yield put(setFormError({ global: 'Invalid username or password' }));
    yield put(UserActions.loginFailure(error.message));
  }
}

export function* check() {
  const isUserLoggedIn = yield select(selectIsUserLoggedIn);
  const token = yield select(selectUserToken);

  if (isUserLoggedIn && token && document.hasFocus()) {
    yield put(
      UserActions.login({
        options: {
          headers: getAuthHeader(token),
          params: getAuthBody(token),
        },
      })
    );
    yield delay(300000);
  } else {
    yield delay(5000);
  }

  yield call(check);
}

function* onIntervalAuthInit() {
  yield call(check);
}

export function* onLogin() {
  yield takeLatest(
    [
      UserActionTypes.LOGIN_START,
      UserActionTypes.LOGOUT_FAILURE,
      UserActionTypes.LOGOUT_SUCCESS,
    ],
    doLogin
  );
}

export function* onLogout() {
  yield takeLatest(UserActionTypes.LOGOUT_START, doLogout);
}

export function* onUpdate() {
  yield takeLatest(
    [
      UserActionTypes.UPDATE_START,
      UserActionTypes.SET_DEFAULT_CAR,
      UserActionTypes.SET_DEFAULT_LANG,
    ],
    doUpdate
  );
}

export function* onRemove() {
  yield takeLatest(UserActionTypes.REMOVE_START, doRemove);
}

export function* userSagas() {
  yield all([
    call(onUpdate),
    call(onRemove),
    call(onLogin),
    call(onLogout),
    call(onIntervalAuthInit),
  ]);
}
