import React from 'react';
import PropTypes from 'prop-types';
import Hammer from 'hammerjs';
import { isEmpty, isEqual, isObject } from 'lodash';
import Loader from '../../components/Loader';

export default class CommonChart extends React.Component {
  state = {
    data: [],
    rendered: false,
    keys: [],
    years: [],
    year: new Date().getFullYear(),
    range: 'isoWeek',
    zoomedRange: 'isoWeek',
  };

  chart = React.createRef();
  chartComp = React.createRef();
  container = 'common';

  ranges = {
    day: ['hours', 'hour'],
    isoWeek: ['days', 'day'],
    month: ['days', 'day'],
    quarter: ['weeks', 'isoWeek'],
    year: ['months', 'month'],
    all: ['years', 'year'],
  };

  constructor(props) {
    super(props);

    this.state.range = this.getRange();
  }

  componentDidMount() {
    this.checkPrepare(
      this.props.isActive || this.props.index === 0,
      true,
      true
    );

    this.addHammer();
  }

  componentDidUpdate(prevProps) {
    const activeChange =
      (!isEqual(prevProps.isActive, this.props.isActive) ||
        !this.state.rendered) &&
      !!this.props.isActive;

    const isLangDifferent = prevProps.lang !== this.props.lang;
    const isDataDifferrent =
      !isEqual(prevProps.data, this.props.data) ||
      (!isEmpty(this.props.data) && !this.state.rendered);
    const isFilterDifferrent = !isEqual(prevProps.filter, this.props.filter);
    const isRangeDifferrent = !isEqual(prevProps.range, this.props.range);
    if (
      activeChange ||
      isDataDifferrent ||
      isFilterDifferrent ||
      isRangeDifferrent ||
      isLangDifferent
    ) {
      this.checkPrepare(
        activeChange,
        isDataDifferrent,
        isRangeDifferrent,
        isLangDifferent
      );
    }
  }

  addHammer() {
    clearTimeout(this.hammerTicker);

    if (this.chart.current) {
      this.manager = new Hammer.Manager(this.chart.current);
      const DoubleTab = new Hammer.Tap({
        event: 'double_tap',
        taps: 2,
        time: 300,
      });
      const LongTap = new Hammer.Press({
        event: 'long_tap',
        taps: 1,
        time: 500,
      });

      this.manager.add(DoubleTab);
      this.manager.add(LongTap);
      this.manager.on('double_tap', (...props) => this.onDoubleClick(...props));
      this.manager.on('long_tap', (...props) => this.onLongClick(...props));
    } else {
      this.hammerTicker = setTimeout(() => this.addHammer(), 200);
    }
  }

  onDoubleClick() {}

  onLongClick() {}

  zoomIn() {
    //ZoomIn needs to be defined in child chart
  }

  zoomOut() {
    //ZoomOut needs to be defined in child chart
  }

  prepare() {
    //Prepare needs to be defined in child chart
  }

  checkPrepare(isActive, isDataDifferrent, isRangeChange, isLangDifferent) {
    // if(this.props.isActive) {
    this.prepare(
      isActive,
      null,
      null,
      isDataDifferrent || isRangeChange || isLangDifferent
    );
    // }
  }

  getRange() {
    const propRange = this.props.range && this.props.range[this.container];

    if (isObject(propRange) && propRange.range) {
      return propRange.range;
    }

    return this.state.range;
  }

  onRangeChange(e) {
    this.checkPrepare(true);
    this.props.onRangeChange(this.container, e.target.value, 'range');
  }

  getYear() {
    const propRange = this.props.range && this.props.range[this.container];

    if (isObject(propRange) && propRange.year) {
      return propRange.year;
    }

    return this.state.year;
  }

  onYearChange(e) {
    this.checkPrepare(true);
    this.props.onRangeChange(this.container, e.target.value, 'year');
  }

  render() {
    if (!this.props.isActive) {
      return <Loader />;
    }

    return null;
  }
}

CommonChart.defaultProps = {
  data: [],
  onRangeChange: () => {},
};

CommonChart.propTypes = {
  isActive: PropTypes.bool,
  index: PropTypes.bool,
  data: PropTypes.array,
  onRangeChange: PropTypes.func,
  filter: PropTypes.object,
  range: PropTypes.object,
  lang: PropTypes.string,
};
