import UserActionTypes from './user.types';

const INITIAL_STATE = {
  userProfile: {},
  isLoggedIn: false,
  isLoading: false,
  editProfile: null,
  lang: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_START:
    case UserActionTypes.LOGIN_START:
    case UserActionTypes.LOGOUT_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserActionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
        editProfile: null,
        isLoading: false,
        error: null,
      };
    }
    case UserActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        editProfile: null,
        error: action.payload,
      };
    }
    case UserActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        isLoading: false,
        error: null,
        isLoggedIn: true,
      };
    }
    case UserActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        userProfile: {},
        isLoading: false,
        error: action.payload,
        isLoggedIn: false,
      };
    }
    case UserActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        userProfile: {},
        isLoggedIn: false,
        isLoading: false,
        error: action.payload,
      };
    }

    case UserActionTypes.SET_EDIT: {
      return {
        ...state,
        editProfile: action.payload,
      };
    }

    case UserActionTypes.SET_DEFAULT_LANG: {
      return {
        ...state,
        lang: action.lang,
      };
    }

    default:
      return state;
  }
};

userReducer.always = ['userProfile'];
userReducer.never = ['editProfile'];

export default userReducer;
