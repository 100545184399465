import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import {
  Legend,
  ResponsiveContainer,
  RadialBar,
  RadialBarChart,
  Tooltip,
} from 'recharts';
import {
  abbrConsumption,
  abbrValue,
  chartColors,
  colors,
  getDoc,
  getSvg,
  moment,
} from '../../utils';

import { find, uniqBy, range as lRange } from 'lodash';
import LocalizedText, { translate } from '../../components/LocalizedText';
import ComboFlyout from '../../components/ComboFlyout';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

const abbrevation = (value) => abbrValue(value, 1, ['', '', '', '', '']);

export default class ConsumptionBar extends CommonChart {
  state = {
    data: [],
    rendered: false,
    range: 5000,
  };

  container = 'consumption';

  prepare(isActive = false, givenRange = false) {
    if (this.props.data.length) {
      const year = this.getYear();
      const range = givenRange || this.getRange();
      const filter = this.props.filter;
      const givenData = uniqBy(
        this.props.data.map((stat) => getDoc(stat)),
        'id'
      );
      const data = [];
      const labels = {};

      // fake;
      // const givenData = docata.reduce((acc, curr) => {
      //   const prev2020 = { ...curr };
      //   const prev2019 = { ...curr };

      //   prev2019.actual = moment(prev2020.actual * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.started = moment(prev2020.started * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.ended = moment(prev2020.ended * 1000)
      //     .year(2019)
      //     .subtract(1, 'days')
      //     .unix();
      //   prev2020.actual = moment(prev2020.actual * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.started = moment(prev2020.started * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.ended = moment(prev2020.ended * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   return [...acc, curr, prev2019, prev2020];
      //   // return [...acc, curr];
      // }, []);

      givenData.sort((a, b) => {
        // if (a.total > b.total) {
        //   return 1;
        // }
        // if (a.total < b.total) {
        //   return -1;
        // }

        if (a.started > b.started) {
          return 1;
        }
        if (a.started < b.started) {
          return -1;
        }

        return 0;
      });

      const firstChargingDay = moment(givenData[0].started * 1000);
      const lastChargingDay = moment(
        givenData[givenData.length - 1].started * 1000
      );
      const diffYears = [
        firstChargingDay.format('YYYY'),
        lastChargingDay.format('YYYY'),
      ];
      const years = lRange(diffYears[0], parseInt(diffYears[1], 10) + 1);

      givenData.forEach((item) => {
        const { total, actual } = item;

        if (moment(actual * 1000).format('YYYY') === `${year}`) {
          const name =
            total < range
              ? `0-${abbrevation(range)} kWh`
              : `${abbrevation(
                  Math.floor(total / range) * range
                )}-${abbrevation(Math.ceil(total / range) * range - 1)} kWh`;
          labels[name] =
            labels[name] ||
            (filter[this.container] &&
            filter[this.container][name] !== undefined
              ? filter[this.container][name]
              : true);

          let current = find(data, (datum) => datum.name === name);

          if (!current) {
            current = {
              name,
              count: 0,
              fill: chartColors[
                Object.keys(labels).indexOf(name) % chartColors.length
              ],
              payload: [],
            };

            data.push(current);
          }

          current.payload.push(item);

          if (labels[name] !== false) {
            current.count++;
          }
        }
      });

      if (isActive) {
        this.props.onHoverChart(this.container, data, range);
      }

      this.setState({
        data,
        range,
        years,
        year: year || years[years.length - 1],
        labels,
        rendered: true,
      });
    }
  }

  render() {
    const all = this.state.data.reduce((accu, curr) => accu + curr.count, 0);
    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">
                Amounts of charged energy
              </LocalizedText>
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                disabled={this.state.years?.length <= 1}
                id="range"
                label={translate('Year')}
                value={this.state.year}
                onChange={(e) => this.onYearChange(e)}
                options={this.state.years}
              />
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                id="range"
                label={translate('Range')}
                value={this.state.range}
                onChange={(e) => this.onRangeChange(e)}
                options={{
                  5000: abbrConsumption(5000),
                  10000: abbrConsumption(10000),
                  20000: abbrConsumption(20000),
                  50000: abbrConsumption(50000),
                }}
              />
            </h3>
            <ResponsiveContainer className={Style.radialResponsiveContainer}>
              <RadialBarChart
                cx="50%"
                cy="50%"
                className={`${Style.mainChart} ${Style.svg}`}
                innerRadius="20%"
                outerRadius="80%"
                barGap={10}
                barSize={60}
                data={this.state.data}
                startAngle={180}
                endAngle={0}
                // onMouseMove={() => this.props.onHoverChart(this.container, this.state.data)}
                // onMouseLeave={() => this.props.onHoverChart(container, [])}
              >
                <RadialBar
                  minAngle={15}
                  label={{
                    position: 'insideStart',
                    fill: colors.ownWhite,
                    stroke: colors.darkGrey,
                    strokeWidth: 3,
                    paintOrder: 'stroke',
                    valueAccessor: (label) => {
                      return label;
                    },
                    formatter: (label) => {
                      if (!label.value) {
                        return '';
                      }

                      const percent = (label.value / all) * 100;
                      return `${label.name}: ${percent.toFixed(
                        percent % 1 ? 2 : 0
                      )}%`;
                    },
                  }}
                  clockWise
                  dataKey="count"
                  animationDuration={300}
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={() => translate('Charged energy ranges')}
                  content={<CustomTooltip />}
                  itemStyle={{ textAlign: 'left' }}
                  formatter={(value, name, { payload }) => {
                    return [
                      value,
                      translate('between {{range}}', { range: payload.name }),
                      getSvg(payload.fill),
                    ];
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '1em auto auto',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.keys(this.state.labels || []).map(
                    (label, index) => ({
                      value: label,
                      type: 'square',
                      color: chartColors[index % chartColors.length],
                      container: this.container,
                    })
                  )}
                  onClick={(o) => this.props.onClick(o, this.state.labels)}
                  onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, container } = entry;

                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                          opacity: !this.props.isChecked(
                            value,
                            container,
                            this.state.labelValues
                          )
                            ? 0.3
                            : 1,
                        }}
                      >
                        {value}
                      </span>
                    );
                  }}
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

ConsumptionBar.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

ConsumptionBar.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
};
