import AppActionTypes from './app.types';

export const setUUID = (payload) => ({
  type: AppActionTypes.SET_UUID,
  payload,
});

export const setLanguage = (payload) => ({
  type: AppActionTypes.SET_LANGUAGE,
  payload,
});

export const setChartRange = (payload) => ({
  type: AppActionTypes.SET_CHART_RANGE,
  payload,
});

export const setSelectedChart = (payload) => ({
  type: AppActionTypes.SET_SELECTED_CHART,
  payload,
});

export const setCar = (payload) => ({
  type: AppActionTypes.SET_CAR,
  payload,
});

export const addHistoryItem = (payload) => ({
  type: AppActionTypes.ADD_HISTORY_ITEM,
  payload,
});

export const resetHistory = () => ({
  type: AppActionTypes.RESET_HISTORY,
});

export const filterHistory = (payload) => ({
  type: AppActionTypes.FILTER_HISTORY,
  payload,
});

export const preFilterHistory = (payload) => ({
  type: AppActionTypes.PRE_FILTER_HISTORY,
  payload,
});

export const fetchWrapper = (type, endpoint, options = {}, hidden = false) => ({
  type,
  payload: {
    endpoint,
    options,
    hidden,
  },
});

export const fetchCancel = (type) => ({
  type: AppActionTypes.FETCH_CANCELED,
  payload: {
    type,
  },
});

export const fetchStatusStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_STATUS_START, 'status', ...props);

export const fetchSharedStatusStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_STATUS_START, 'shared', ...props);

export const fetchStatusSuccess = (payload) => ({
  type: AppActionTypes.FETCH_STATUS_SUCCESS,
  payload,
});

export const fetchStatusFailure = (payload) => ({
  type: AppActionTypes.FETCH_STATUS_FAILURE,
  payload,
});

export const updateStart = (...props) =>
  fetchWrapper(AppActionTypes.UPDATE_START, 'update', ...props);

export const updateSuccess = (payload) => ({
  type: AppActionTypes.UPDATE_SUCCESS,
  payload,
});

export const updateFailure = (payload) => ({
  type: AppActionTypes.UPDATE_FAILURE,
  payload,
});

export const updateCarOfChargingStart = (chargerId, car, soc) => ({
  type: AppActionTypes.UPDATE_CAR_OF_CHARGING_START,
  payload: {
    endpoint: 'updateCharging',
    options: {
      params: {
        chargerId,
        car,
        soc,
      },
    },
  },
});

export const updateCarOfChargingSuccess = (payload) => ({
  type: AppActionTypes.UPDATE_CAR_OF_CHARGING_SUCCESS,
  payload,
});

export const updateCarOfChargingFailure = (payload) => ({
  type: AppActionTypes.UPDATE_CAR_OF_CHARGING_FAILURE,
  payload,
});

export const removeHistoryStart = (...props) =>
  fetchWrapper(AppActionTypes.REMOVE_HISTORY_START, 'history/remove', ...props);

export const removeHistorySuccess = (payload) => ({
  type: AppActionTypes.REMOVE_HISTORY_SUCCESS,
  payload,
});

export const removeHistoryFailure = (payload) => ({
  type: AppActionTypes.REMOVE_HISTORY_FAILURE,
  payload,
});

export const fetchHistoryStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_HISTORY_START, 'history', ...props);

export const fetchHistoryPage = (offset) =>
  fetchHistoryStart({ params: { offset } });

export const fetchHistorySuccess = (payload) => ({
  type: AppActionTypes.FETCH_HISTORY_SUCCESS,
  payload,
});

export const fetchHistoryFailure = (payload) => ({
  type: AppActionTypes.FETCH_HISTORY_FAILURE,
  payload,
});

export const fetchStatStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_STAT_START, 'history', ...props);

export const fetchStatSuccess = (payload) => ({
  type: AppActionTypes.FETCH_STAT_SUCCESS,
  payload,
});

export const fetchStatFailure = (payload) => ({
  type: AppActionTypes.FETCH_STAT_FAILURE,
  payload,
});

export const fetchUsersStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_USERS_START, 'users', ...props);

export const fetchUsersSuccess = (payload) => ({
  type: AppActionTypes.FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (payload) => ({
  type: AppActionTypes.FETCH_USERS_FAILURE,
  payload,
});

export const fetchChargersStart = (...props) =>
  fetchWrapper(AppActionTypes.FETCH_CHARGERS_START, 'chargers', ...props);

export const fetchChargersSuccess = (payload) => ({
  type: AppActionTypes.FETCH_CHARGERS_SUCCESS,
  payload,
});

export const fetchChargersFailure = (payload) => ({
  type: AppActionTypes.FETCH_CHARGERS_FAILURE,
  payload,
});

export const chargingStart = (
  chargerId,
  limit,
  scheduling,
  restarting,
  car,
  soc,
  connector = 0
) => ({
  type: AppActionTypes.CHARGING_START,
  payload: {
    endpoint: 'request',
    options: {
      params: {
        chargerId,
        scheduling,
        restarting,
        consumptionLimit: limit || 0,
        car,
        soc,
        connector,
      },
    },
  },
});

export const chargingStartSuccess = (payload) => ({
  type: AppActionTypes.CHARGING_START_SUCCESS,
  payload,
});

export const chargingStartFailure = (payload) => ({
  type: AppActionTypes.CHARGING_START_FAILURE,
  payload,
});

export const chargingStop = (chargerId, force, scheduling, pausing) => ({
  type: AppActionTypes.CHARGING_STOP,
  payload: {
    endpoint: 'stop',
    options: {
      params: {
        scheduling,
        pausing,
        chargerId,
        force,
      },
    },
  },
});

export const chargingStopSuccess = (payload) => ({
  type: AppActionTypes.CHARGING_STOP_SUCCESS,
  payload,
});

export const chargingStopFailure = (payload) => ({
  type: AppActionTypes.CHARGING_STOP_FAILURE,
  payload,
});
