import { IS_DEV } from './app.utils';

const type = IS_DEV ? 'DEV' : 'APP';

const AppActionTypes = {
  SET_UUID: `${type}/SET_UUID`,
  SET_LANGUAGE: `${type}/SET_LANGUAGE`,
  SET_CAR: `${type}/SET_CAR`,
  SET_SELECTED_CHART: `${type}/SET_SELECTED_CHART`,
  SET_CHART_RANGE: `${type}/SET_CHART_RANGE`,

  RESET_HISTORY: `${type}/RESET_HISTORY`,
  ADD_HISTORY_ITEM: `${type}/ADD_HISTORY_ITEM`,
  FILTER_HISTORY: `${type}/FILTER_HISTORY`,
  PRE_FILTER_HISTORY: `${type}/PRE_FILTER_HISTORY`,

  FETCH_CANCELED: `${type}/FETCH_CANCELED`,

  FETCH_STATUS_START: `${type}/FETCH_STATUS_START`,
  FETCH_STATUS_SUCCESS: `${type}/FETCH_STATUS_SUCCESS`,
  FETCH_STATUS_FAILURE: `${type}/FETCH_STATUS_FAILURE`,

  FETCH_CHARGERS_START: `${type}/FETCH_CHARGERS_START`,
  FETCH_CHARGERS_SUCCESS: `${type}/FETCH_CHARGERS_SUCCESS`,
  FETCH_CHARGERS_FAILURE: `${type}/FETCH_CHARGERS_FAILURE`,

  FETCH_HISTORY_START: `${type}/FETCH_HISTORY_START`,
  FETCH_HISTORY_SUCCESS: `${type}/FETCH_HISTORY_SUCCESS`,
  FETCH_HISTORY_FAILURE: `${type}/FETCH_HISTORY_FAILURE`,

  FETCH_STAT_START: `${type}/FETCH_STAT_START`,
  FETCH_STAT_SUCCESS: `${type}/FETCH_STAT_SUCCESS`,
  FETCH_STAT_FAILURE: `${type}/FETCH_STAT_FAILURE`,

  FETCH_USERS_START: `${type}/FETCH_USERS_START`,
  FETCH_USERS_SUCCESS: `${type}/FETCH_USERS_SUCCESS`,
  FETCH_USERS_FAILURE: `${type}/FETCH_USERS_FAILURE`,

  UPDATE_START: `${type}/UPDATE_START`,
  UPDATE_SUCCESS: `${type}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${type}/UPDATE_FAILURE`,

  UPDATE_CAR_OF_CHARGING_START: `${type}/UPDATE_CAR_OF_CHARGING_START`,
  UPDATE_CAR_OF_CHARGING_SUCCESS: `${type}/UPDATE_CAR_OF_CHARGING_SUCCESS`,
  UPDATE_CAR_OF_CHARGING_FAILURE: `${type}/UPDATE_CAR_OF_CHARGING_FAILURE`,

  REMOVE_HISTORY_START: `${type}/REMOVE_HISTORY_START`,
  REMOVE_HISTORY_SUCCESS: `${type}/REMOVE_HISTORY_SUCCESS`,
  REMOVE_HISTORY_FAILURE: `${type}/REMOVE_HISTORY_FAILURE`,

  CHARGING_START: `${type}/CHARGING_START`,
  CHARGING_START_SUCCESS: `${type}/CHARGING_START_SUCCESS`,
  CHARGING_START_FAILURE: `${type}/CHARGING_START_FAILURE`,
  CHARGING_STOP: `${type}/CHARGING_STOP`,
  CHARGING_STOP_SUCCESS: `${type}/CHARGING_STOP_SUCCESS`,
  CHARGING_STOP_FAILURE: `${type}/CHARGING_STOP_FAILURE`,
};

export default AppActionTypes;
