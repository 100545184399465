import React from 'react';
import PropTypes from 'prop-types';
import Style from './NewUser.module.scss';
import { updateStart } from '../redux/user/user.actions';
import {
  selectEditProfile,
  selectIsUserLoggedIn,
} from '../redux/user/user.selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import sha1 from 'sha1';
import Form, { RadioButtons } from '../containers/form/Form';
import { passwordsMatch, requiredIf } from '../containers/form/Form.validators';
import { Redirect } from 'react-router-dom';
import { getInitialValues } from '../containers/form/Form.utils';
import { difference } from '../utils';
import { isEmpty } from 'lodash';
import { translate } from './LocalizedText';

const passwordValidator = passwordsMatch('newUser_newPassword');
const requiredIfPassword = requiredIf([
  'newUser_newPassword',
  'newUser_newPasswordVerification',
]);

class NewUser extends React.Component {
  shouldComponentUpdate(prevProps, prevState) {
    return !isEmpty(difference(prevProps, this.props));
  }

  onSubmit(values) {
    const body = {
      username: values.newUser_username,
      admin: values.newUser_admin,
      id: values.newUser_id,
    };

    if (values.newUser_newPassword && values.newUser_newPasswordVerification) {
      body.newPassword = sha1(values.newUser_newPassword);
      body.newPasswordVerification = sha1(
        values.newUser_newPasswordVerification
      );
    }

    this.props.save(body);
    this.props.callback && this.props.callback();
  }

  render() {
    if (!this.props.isAdmin) {
      return null;
    }
    if (!this.props.isUserLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <div className={Style.profile}>
        <Form
          id="newUser"
          title={translate('Add new user')}
          submitLabel={translate('Save')}
          submitButtonStyle={Style.submitButton}
          submitContainerStyle={Style.submitContainer}
          onSubmit={(...props) => this.onSubmit(...props)}
          initialValues={getInitialValues(this.props.profile, 'newUser_')}
          fields={[
            {
              id: 'username',
              autocomplete: 'new-password',
              label: translate('Username'),
              required: true,
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('Username'),
            },
            {
              id: 'newPassword',
              autocomplete: 'new-password',
              label: translate('New password'),
              type: 'password',
              validators: [requiredIfPassword],
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('New password'),
            },
            {
              id: 'newPasswordVerification',
              autocomplete: 'new-password',
              label: translate('New password verification'),
              type: 'password',
              validators: [requiredIfPassword, passwordValidator],
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('New password verification'),
            },
            {
              id: 'admin',
              autocomplete: 'new-password',
              label: translate('Admin role'),
              type: RadioButtons,
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              labelPlacement: 'start',
              options: ['No', 'Yes'],
            },
          ]}
        />
      </div>
    );
  }
}

NewUser.propTypes = {
  profile: PropTypes.object,
  save: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  callback: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  profile: selectEditProfile,
  isUserLoggedIn: selectIsUserLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  save: (body) => dispatch(updateStart({ create: true, body })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
