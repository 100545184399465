import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Style from './ButtonChooser.module.scss';
import Loader from './Loader';
import Button from './Button';
import { abbrConsumption, isTrue, scheduledOptions, colors } from '../utils';
import ComboFlyout from './ComboFlyout';
import moment from 'moment';
import AdapterDateFns from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Slider from '@material-ui/core/Slider';
import LocalizedText, { translate } from './LocalizedText';
import LabeledItem from './LabeledItem';
import Car from './Car';
import Icon from './Icon';
import LottieIcon from '../containers/lotties/LottieIcon';

class ButtonChooser extends React.Component {
  datepicker = React.createRef();

  state = {
    clicked: false,
    clicks: 0,
    pauserOpened: false,
    schedulerOpened: false,
    carOpened: false,
    scheduled: scheduledOptions.immediately,
    paused: scheduledOptions.until,
    car: null,
    socBeforeClose: [0, 100],
    soc: [0, 100],
    datepickerOpened: false,
    energyLimiterOpened: false,
    prevState: {},
  };

  clickLimit = 10;
  clicks = 0;
  clickTimeLimit = 300;
  lastClickTime = 0;

  constructor(props) {
    super(props);

    this.state.car = this.props.storedCar || null;

    this.state.soc = this.props.soc ?? [0, 100];
    this.state.socBeforeClose = [...this.state.soc];
  }

  setContinuousState(state) {
    this.setState({ prevState: { ...this.state, prevState: null }, ...state });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isLoadingCharging !== this.props.isLoadingCharging &&
      this.props.isLoadingCharging
    ) {
      this.setContinuousState({ clicked: false });
    }

    // if(prevProps.isEligibleToStart !== this.props.isEligibleToStart || prevProps.isEligibleToStop !== this.props.isEligibleToStop) {
    //   this.setContinuousState({ scheduled: null });
    // }
  }

  getScheduling() {
    const scheduling =
      this.state.scheduled === scheduledOptions.immediately
        ? null
        : [scheduledOptions.solar, scheduledOptions.byCar].includes(
            this.state.scheduled
          )
        ? this.state.scheduled
        : `${moment(this.state.scheduled)
            .utc(1)
            .format('YYYY/MM/DD HH:mm')}:00`;
    return scheduling;
  }

  getCar() {
    return this.state.car;
  }

  getSoc() {
    return this.state.soc;
  }

  start(connectorId = 0) {
    if (this.disabledStart) {
      return;
    }

    if (this.props.isEligibleToStart || !!this.props.pausedUntil) {
      this.click();
      this.props.start(
        null,
        this.getScheduling(),
        !!this.props.pausedUntil,
        this.getCar(),
        this.getSoc(),
        connectorId
      );
      this.setContinuousState({
        scheduled: scheduledOptions.immediately,
        car: null,
        soc: [0, 100],
      });
    } else {
      this.checkClicks();
    }
  }

  checkClicks() {
    clearTimeout(this.clickTick);
    if (
      this.clicks === 0 ||
      (Date.now() - this.lastClickTime <= this.clickTimeLimit &&
        this.clicks <= this.clickLimit)
    ) {
      this.clicks++;
      this.setContinuousState({ clicks: this.clicks });
      this.lastClickTime = Date.now();

      if (this.clicks === this.clickLimit) {
        this.stop(true);
        this.resetClicks();
      }
    }

    this.clickTick = setTimeout(
      () => this.resetClicks(),
      this.clickTimeLimit + 2
    );
  }

  resetClicks() {
    this.clicks = 0;
    this.lastClickTime = 0;
    this.setContinuousState({ clicks: this.clicks });
  }

  stop(force) {
    if (this.disabledStart) {
      return;
    }

    if (force) {
      this.disabledStart = true;
      setTimeout(() => (this.disabledStart = false), 2000);
    }
    this.click();
    this.props.stop(force, this.getScheduling());
    this.setContinuousState({
      scheduled: scheduledOptions.immediately,
      car: null,
      soc: [0, 100],
    });
  }

  click() {
    this.setContinuousState({ clicked: true });
  }

  onLongTap(type, callee) {
    this.disabledStart = true;
    setTimeout(() => (this.disabledStart = false), 2000);

    document.body.classList.add(Style.touchfix);
    this.setContinuousState({ [type]: callee || true });
  }

  onCarClose(e) {
    this.onClose(e);
    if (!isEqual(this.state.soc, this.state.socBeforeClose)) {
      this.setContinuousState({ socBeforeClose: this.getSoc() });
      this.props.update(this.state.car, this.getSoc());
    }
  }

  onCarChanged(e) {
    const target = (e.nativeEvent || {}).target || null;
    if (!target || !target.closest(`.${Style.socSliderContainer}`)) {
      if (this.props.isEligibleToStart) {
        const car = e.target.value;
        this.props.setCar(car);
        this.setContinuousState({ car, carOpened: false });
      }
      if (this.props.isEligibleToStop) {
        const car = e.target.value;
        this.props.setCar(car);
        this.props.update(car, this.getSoc());
        this.setContinuousState({ car, carOpened: false });
      } else {
        this.setContinuousState({ carOpened: false });
      }
    }
  }

  onEnergyLimitChange(e, connectorId) {
    if (this.props.isEligibleToStart) {
      this.setContinuousState({ clicked: true, energyLimiterOpened: false });
      this.props.start(
        e.target.value,
        this.getScheduling(),
        undefined,
        this.getCar(),
        this.getSoc(),
        connectorId
      );
    } else {
      this.setContinuousState({ energyLimiterOpened: false });
    }
  }

  onSocChange(value) {
    this.setContinuousState({ soc: value });
  }

  onPausedChange(e) {
    if (this.props.isEligibleToStop) {
      if (e.target && e.target.value === scheduledOptions.date) {
        document.body.classList.add(Style.touchfix);
        this.setContinuousState({ datepickerOpened: 'paused' });
      } else {
        this.setContinuousState({
          paused: e.target ? e.target.value : e,
          pauserOpened: false,
          datepickerOpened: false,
        });
        this.props.stop(false, false, e.target ? e.target.value : e);
      }
    } else {
      this.setContinuousState({ pauserOpened: false, datepickerOpened: false });
    }
  }

  onScheduledChange(e) {
    if (this.props.isEligibleToStart || this.props.isEligibleToStop) {
      if (e.target && e.target.value === scheduledOptions.date) {
        document.body.classList.add(Style.touchfix);
        this.setContinuousState({ datepickerOpened: 'scheduled' });
      } else {
        this.setContinuousState({
          scheduled: e.target ? e.target.value : e,
          schedulerOpened: false,
          datepickerOpened: false,
        });
      }
    } else {
      this.setContinuousState({
        schedulerOpened: false,
        datepickerOpened: false,
      });
    }
  }

  onClose(e) {
    if (!e.target.closest(`.${Style.socSliderContainer}`)) {
      document.body.classList.remove(Style.touchfix);
      this.setContinuousState({
        pauserOpened: false,
        schedulerOpened: false,
        energyLimiterOpened: false,
        carOpened: false,
      });
    }
  }

  onClosePicker(e) {
    document.body.classList.remove(Style.touchfix);
    this.setContinuousState({
      datepickerOpened: false,
    });
  }

  renderSlider() {
    return (
      <div className={Style.socSliderContainer}>
        <LocalizedText tag="span" className={Style.socLabel}>
          Starting SOC
        </LocalizedText>
        <Slider
          onChangeCommitted={(e, value) => this.onSocChange(value)}
          onChange={(e, value) => this.onSocChange(value)}
          classes={{
            root: Style.socSlider,
          }}
          valueLabelFormat={(value) => `${value}%`}
          value={this.state.soc}
          valueLabelDisplay="auto"
          marks
          min={0}
          max={100}
        />
      </div>
    );
  }

  render() {
    const {
      isRequested,
      isLoadingCharging,
      isEligibleToStop,
      isEligibleToStart,
      chargerStatus,
      children,
      chargerSchedule,
      scheduledEnd,
      scheduled,
      started,
      pausedUntil,
      cars,
      storedCar,
      connectors: givenConnectors,
    } = this.props;
    let forceClass = '';

    const isSchedulable = isTrue(chargerSchedule) && chargerStatus === 'Online';
    const canScheduledStart = isSchedulable && isEligibleToStart;
    const canScheduledEnd =
      isSchedulable &&
      isEligibleToStop &&
      !isRequested &&
      !!!scheduledEnd &&
      (!!!scheduled || !!started);
    const connectors =
      givenConnectors && givenConnectors.length
        ? givenConnectors.filter((connector) => {
            return isTrue(connector.startable);
          })
        : [{ id: 0 }];

    if (this.state.clicks >= 3) {
      forceClass = `${Style.forcing} ${Style[`f${this.state.clicks}`]}`;
    }

    if (chargerStatus === 'Connecting') {
      return null;
    }

    const hasCars =
      cars &&
      cars.length &&
      (isEligibleToStart || isEligibleToStop) &&
      chargerStatus === 'Online' &&
      !isRequested;
    const selectedCar =
      hasCars && cars.find((car) => car.id === this.state.car);

    return (
      <>
        <div className={Style.buttonWrapper}>
          {isEligibleToStart === true ||
          isEligibleToStop === false ||
          !!pausedUntil ? (
            <>
              {connectors.map((connector) => (
                <>
                  <Button
                    // eslint-disable-next-line max-len
                    className={`${Style.button} ${
                      Style[`buttons-${connectors.length}`]
                    } ${hasCars ? Style.hasCar : ''} ${Style.start} ${
                      canScheduledStart ? Style.schedulable : ''
                    } ${forceClass}`}
                    onClick={() => this.start(connector.id)}
                    onLongTap={() =>
                      this.onLongTap('energyLimiterOpened', `${connector.id}`)
                    }
                    fakeDisabled={!isEligibleToStart && !pausedUntil}
                    disabled={
                      this.state.clicked ||
                      isLoadingCharging ||
                      chargerStatus !== 'Online'
                    }
                  >
                    {connector.type ? (
                      <Icon
                        icon={`Charger/${connector.type.replace(
                          /[^a-zA-Z0-9]/g,
                          ''
                        )}`}
                        className={Style.icon}
                      />
                    ) : null}
                    <Loader
                      hidden={!isLoadingCharging}
                      className={Style.loader}
                      iconClassName={`${Style.loaderIcon} ${Style.startLoaderIcon}`}
                    />
                    <LocalizedText
                      variables={{
                        time: moment(this.state.scheduled)
                          .local()
                          .format('YYYY/MM/DD HH:mm'),
                        startingSoc: this.state.soc[0],
                        endingSoc: this.state.soc[1],
                        clicks: this.state.clicks >= 3 ? this.state.clicks : '',
                      }}
                    >
                      {chargerStatus !== 'Online'
                        ? chargerStatus === 'Connecting'
                          ? 'Connecting to charger'
                          : 'Charger is offline'
                        : isEligibleToStart
                        ? `${
                            this.state.scheduled !== scheduledOptions.byCar
                              ? `Start charging${
                                  this.state.scheduled !==
                                  scheduledOptions.immediately
                                    ? this.state.scheduled ===
                                      scheduledOptions.solar
                                      ? ' when green energy available'
                                      : ' at {{time}}'
                                    : ''
                                }`
                              : 'Car will start charging'
                          }${
                            this.state.soc[0] > 0
                              ? ' from {{startingSoc}}%'
                              : ''
                          }${
                            this.state.soc[1] < 100 ? ' to {{endingSoc}}%' : ''
                          }`
                        : pausedUntil
                        ? 'Restart paused charging'
                        : "Other's charging is already running {{clicks}}"}
                    </LocalizedText>

                    {this.props.learning < 3 ? (
                      <>
                        {isEligibleToStart && (
                          <span title={translate('Single click')}>
                            <LottieIcon
                              name="SingleTap"
                              loop
                              colors={[colors.darkGrey]}
                              className={Style.lottieIcon}
                            />
                          </span>
                        )}
                        <LabeledItem
                          className={Style.copy}
                          component={(props) => <span {...props} />}
                          icon="IoBulbOutline"
                          iconClassName={Style.icon}
                          label="Tap long to open additional options"
                        />
                      </>
                    ) : null}
                  </Button>
                </>
              ))}
              {canScheduledStart ? (
                <LabeledItem
                  className={`${Style.button} ${Style.schedule}`}
                  component={Button}
                  onClick={() => this.onLongTap('schedulerOpened', 'start')}
                  icon="MdSchedule"
                  iconClassName={Style.scheduleIcon}
                />
              ) : null}
              {hasCars ? (
                <LabeledItem
                  className={`${Style.button} ${Style.car}`}
                  component={Button}
                  onClick={() => this.onLongTap('carOpened', 'start')}
                  icon={selectedCar ? false : 'IoCarSportSharp'}
                  iconComponent={
                    selectedCar ? (
                      <Car data={selectedCar} soc={this.state.soc} />
                    ) : null
                  }
                  iconClassName={Style.carIcon}
                />
              ) : null}
            </>
          ) : null}
          {isEligibleToStop === true && !pausedUntil ? (
            <>
              <Button
                // eslint-disable-next-line max-len
                className={`${Style.button} ${hasCars ? Style.hasCar : ''} ${
                  Style.stop
                } ${canScheduledEnd ? Style.schedulable : ''}`}
                onDoubleClick={() => this.stop()}
                onLongTap={() => this.onLongTap('pauserOpened')}
                disabled={this.state.clicked || isLoadingCharging}
              >
                <Loader
                  hidden={!isLoadingCharging && !isRequested}
                  className={Style.loader}
                  iconClassName={Style.loaderIcon}
                />
                <LocalizedText
                  variables={{
                    time: moment(this.state.scheduled)
                      .local()
                      .format('YYYY/MM/DD HH:mm'),
                  }}
                >
                  {isRequested
                    ? 'Waiting for starting'
                    : `Stop charging${
                        this.state.scheduled !== scheduledOptions.immediately
                          ? ' at {{time}}'
                          : ''
                      }`}
                </LocalizedText>
                {this.props.learning < 3 ? (
                  <>
                    <span title={translate('Double click')}>
                      <LottieIcon
                        name="DoubleTap"
                        loop
                        colors={[colors.ownWhite]}
                        className={Style.lottieIcon}
                      />
                    </span>
                    <LabeledItem
                      className={Style.copy}
                      component={(props) => <span {...props} />}
                      icon="IoBulbOutline"
                      label="Tap long to open additional options"
                    />
                  </>
                ) : null}
              </Button>
              {canScheduledEnd ? (
                <LabeledItem
                  className={`${Style.button} ${Style.stop} ${Style.schedule}`}
                  component={Button}
                  onClick={() => this.onLongTap('schedulerOpened', 'stop')}
                  icon="MdSchedule"
                  iconClassName={Style.scheduleIcon}
                />
              ) : null}
              {hasCars ? (
                <LabeledItem
                  className={`${Style.button} ${Style.stop} ${Style.car}`}
                  component={Button}
                  onClick={() => this.onLongTap('carOpened', 'stop')}
                  icon={selectedCar ? false : 'IoCarSportSharp'}
                  iconComponent={
                    selectedCar ? (
                      <Car data={selectedCar} soc={this.state.soc} />
                    ) : null
                  }
                  iconClassName={Style.carIcon}
                />
              ) : null}
            </>
          ) : null}
          <div className={Style.flyouts}>
            {chargerStatus === 'Online' && isEligibleToStart ? (
              <>
                <ComboFlyout
                  className={`${
                    !!this.state.energyLimiterOpened
                      ? Style.formControlOpened
                      : ''
                  }`}
                  paperClassName={Style.paper}
                  menuClassName={Style.menuItem}
                  opened={!!this.state.energyLimiterOpened}
                  id="energyLimiter"
                  label={translate('Energy limit')}
                  value={false}
                  onClose={(e) => this.onClose(e)}
                  onChange={(e) =>
                    this.onEnergyLimitChange(e, this.state.energyLimiterOpened)
                  }
                  options={{
                    null: translate('Unlimited'),
                    5000: abbrConsumption(5000),
                    10000: abbrConsumption(10000),
                    20000: abbrConsumption(20000),
                    50000: abbrConsumption(50000),
                  }}
                />
              </>
            ) : null}
            {chargerStatus === 'Online' &&
            (isEligibleToStart === true || isEligibleToStop === true) ? (
              <>
                {isEligibleToStop === true ? (
                  <ComboFlyout
                    className={`${
                      !!this.state.pauserOpened ? Style.formControlOpened : ''
                    }`}
                    paperClassName={
                      this.state.pauserOpened === 'stop' ||
                      this.state.prevState.pauserOpened === 'stop'
                        ? Style.paper
                        : Style.paperRed
                    }
                    menuClassName={Style.menuItem}
                    opened={!!this.state.pauserOpened}
                    id="pauser"
                    label={translate('Pauser')}
                    value={false}
                    onClose={(e) => this.onClose(e)}
                    onChange={(e) => this.onPausedChange(e)}
                    options={{
                      '-': {
                        disabled: true,
                        label: translate('Pause until'),
                      },
                      [scheduledOptions.until]: translate('Restarted manually'),
                      [scheduledOptions.date]: translate('Pick a time'),
                    }}
                  />
                ) : null}
                {hasCars ? (
                  <ComboFlyout
                    className={`${
                      !!this.state.carOpened ? Style.formControlOpened : ''
                    }`}
                    paperClassName={
                      this.state.carOpened === 'start' ||
                      this.state.prevState.carOpened === 'start'
                        ? Style.paper
                        : Style.paperRed
                    }
                    menuClassName={Style.menuItem}
                    opened={this.state.carOpened}
                    id="carChooser"
                    label={translate('Cars')}
                    value={parseInt(storedCar, 10) ? storedCar : false}
                    onClose={(e) => this.onCarClose(e)}
                    onChange={(e) => this.onCarChanged(e)}
                    options={{
                      null: {
                        value: null,
                        label: (
                          <>
                            <LocalizedText>No set</LocalizedText>
                            {this.renderSlider()}
                          </>
                        ),
                      },
                      ...cars.reduce(
                        (acc, car) => ({
                          ...acc,
                          [car.id]: {
                            value: car.id,
                            label: (
                              <>
                                {car.name}
                                <Car data={car} className={Style.carListItem} />
                                {this.renderSlider()}
                              </>
                            ),
                          },
                        }),
                        {}
                      ),
                    }}
                  />
                ) : null}
                <ComboFlyout
                  className={`${
                    !!this.state.schedulerOpened ? Style.formControlOpened : ''
                  }`}
                  paperClassName={
                    this.state.schedulerOpened === 'start' ||
                    this.state.prevState.schedulerOpened === 'start'
                      ? Style.paper
                      : Style.paperRed
                  }
                  menuClassName={Style.menuItem}
                  opened={!!this.state.schedulerOpened}
                  id="scheduler"
                  label={translate('Scheduler')}
                  value={false}
                  onClose={(e) => this.onClose(e)}
                  onChange={(e) => this.onScheduledChange(e)}
                  options={{
                    '-': {
                      disabled: true,
                      label: translate(
                        `Scheduled ${this.state.schedulerOpened}`
                      ),
                    },
                    [scheduledOptions.immediately]: translate('Immediately'),
                    [scheduledOptions.date]: translate('Pick a time'),
                    ...(isEligibleToStart === true && isEligibleToStop === false
                      ? {
                          [scheduledOptions.solar]: translate(
                            'Green energy available'
                          ),
                          [scheduledOptions.byCar]: {
                            label: translate(`Managed by car`),
                          },
                        }
                      : {}),
                  }}
                />
                <MuiPickersUtilsProvider utils={AdapterDateFns} locale="en">
                  <DateTimePicker
                    open={!!this.state.datepickerOpened}
                    className={Style.datepicker}
                    innerRef={this.datepicker}
                    label={translate('Date picker')}
                    // minutesStep={5}
                    minDate={moment().add(5, 'm').toDate()}
                    maxDate={moment().add(2, 'd').toDate()}
                    value={null}
                    MenuProps={{
                      classes: {
                        paper: this.datepickerPaper,
                      },
                    }}
                    onClose={(e) => this.onClosePicker(e)}
                    onChange={(e) => {
                      this.state.datepickerOpened === 'paused'
                        ? this.onPausedChange(e)
                        : this.onScheduledChange(e);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            ) : null}
          </div>
        </div>
        {children}
      </>
    );
  }
}

ButtonChooser.defaultProps = {
  start: () => {},
  stop: () => {},
  update: () => {},
};

ButtonChooser.propTypes = {
  children: PropTypes.any,
  start: PropTypes.func,
  stop: PropTypes.func,
  update: PropTypes.func,
  isLoadingCharging: PropTypes.bool,
  isRequested: PropTypes.bool,
  isEligibleToStop: PropTypes.bool,
  isEligibleToStart: PropTypes.bool,
  chargerStatus: PropTypes.any,
  chargerSchedule: PropTypes.any,
  scheduledEnd: PropTypes.any,
  scheduled: PropTypes.any,
  started: PropTypes.any,
  connectors: PropTypes.array,
  pausedUntil: PropTypes.any,
  learning: PropTypes.number,
  cars: PropTypes.array,
  setCar: PropTypes.func,
  storedCar: PropTypes.any,
  soc: PropTypes.array,
};

export default ButtonChooser;
