import React from 'react';
import PropTypes from 'prop-types';
import Style from './Main.module.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  selectError,
  selectIsAppLoading,
  selectIsChargingLoading,
  selectClientId,
  selectEligibleToStart,
  selectIsRequested,
  selectEligibleToStop,
  selectTotalConsumption,
  selectConsumptionLimit,
  selectStarted,
  selectStopped,
  selectConsumptionAtStart,
  selectCharger,
  selectHistory,
  selectCharging,
  selectHistoryTotal,
  selectScheduled,
  selectScheduledEnd,
  selectPaused,
  selectLearning,
  selectStat,
  selectSolar,
  selectSolarStat,
  selectTotalReactiveConsumption,
  selectSolarBoost,
  selectDeviceStat,
  selectDeviceTemperatureStat,
} from '../redux/app/app.selectors';
import {
  chargingStart,
  chargingStop,
  fetchStatusStart,
  setUUID,
  updateCarOfChargingStart,
} from '../redux/app/app.actions';
import Loader from './Loader';
import Charging from './Charging';
import Charger from './Charger';
import Error from './Error';
import History from './History';
import ButtonChooser from './ButtonChooser';
import SumChart from '../containers/charts/SumChart';
import { translate } from './LocalizedText';
import { isTrue } from '../utils';
import { selectDefaultCar, selectLastSOC } from '../redux/user/user.selectors';
import { setDefaultCar } from '../redux/user/user.actions';
import { isEmpty } from 'lodash';
// import { selectForm } from '../redux/form/form.selectors';

class Main extends React.Component {
  state = {
    notification: true,
  };

  componentDidMount() {
    // authentication required checkolasa toltobol
    this.chargerId = this.props.match.params.chargerId;

    // !this.props.isAdmin && this.props.fetch({
    //   params: {
    //     chargerId: this.chargerId,
    //   },
    // });
  }

  start(limit, scheduling, restarting, car, soc, connectorId = 0) {
    return (
      (this.props.eligibleToStart || this.props.pausedUntil) &&
      this.props.start(
        this.chargerId,
        limit,
        scheduling,
        restarting,
        car,
        soc,
        connectorId
      )
    );
  }

  stop(force = false, scheduling, pausing) {
    return (
      (force || this.props.eligibleToStop) &&
      this.props.stop(this.chargerId, force ? 1 : 0, scheduling, pausing)
    );
  }

  update(carId, soc) {
    return (
      this.props.eligibleToStop && this.props.update(this.chargerId, carId, soc)
    );
  }

  getSoc() {
    const { startingSOC, endingSOC } = this.props.charging;

    const starting = parseInt(startingSOC);
    const ending = parseInt(endingSOC);

    const lastSocReceived = parseInt(this.props.lastSoc) ?? 0;

    return [
      isNaN(starting) || !starting
        ? !isNaN(lastSocReceived)
          ? lastSocReceived
          : 0
        : starting,
      isNaN(ending) || !ending ? 100 : ending,
    ];
  }

  render() {
    const {
      className,
      children,
      eligibleToStart,
      eligibleToStop,
      isLoadingCharging,
      isLoading,
      error,
      isRequested,
      charger,
      totalConsumption,
      totalReactiveConsumption,
      consumptionLimit,
      total,
      isAdmin,
      scheduledEnd,
      scheduled,
      pausedUntil,
      started,
      learning,
      stat,
      deviceStat,
      deviceTemperatureStat,
      solarStat,
      solar,
      solarBoost,
      car,
      // formValues = {},
    } = this.props;

    return (
      <div className={`Main ${Style.Main} ${className}`}>
        {children}
        {isLoading || !charger ? (
          <Loader label={translate('Loading')} />
        ) : (
          <div
            className={`${Style.controller} ${
              eligibleToStart === true ? Style.canStarted : ''
            }`}
          >
            <Charger
              data={charger}
              consumption={totalConsumption}
              reactiveConsumption={totalReactiveConsumption}
              limit={consumptionLimit}
              location={charger.location}
              total={total}
              isAdmin={isAdmin}
              // adminConnectors={isAdmin ? formValues.connectors : []}
            />
            <Error>{error}</Error>
            {charger.autoStart !== '2' ? (
              <ButtonChooser
                isRequested={isRequested}
                isEligibleToStop={eligibleToStop}
                isEligibleToStart={eligibleToStart}
                isLoadingCharging={isLoadingCharging}
                start={(limit, scheduling, restarting, car, soc, connector) => {
                  this.start(
                    limit,
                    scheduling,
                    restarting,
                    car,
                    soc,
                    connector
                  );
                }}
                stop={(force, scheduling, pausing) =>
                  this.stop(force, scheduling, pausing)
                }
                update={(carId, soc) => this.update(carId, soc)}
                setCar={(carId) => this.props.setCar(carId)}
                chargerStatus={charger.status}
                chargerSchedule={charger.schedule}
                connectors={charger.connectors}
                scheduledEnd={scheduledEnd}
                pausedUntil={pausedUntil}
                scheduled={scheduled}
                started={started}
                learning={learning}
                cars={charger.cars}
                storedCar={car}
                soc={this.getSoc()}
              >
                <Charging {...this.props} />
              </ButtonChooser>
            ) : (
              <Charging {...this.props} />
            )}
            <History
              data={this.props.history}
              match={this.props.match}
              isAdmin={isAdmin}
              solar={solar}
              solarBoost={solarBoost}
            >
              <SumChart
                match={this.props.match}
                data={stat}
                solarData={solarStat}
                deviceData={deviceStat}
                deviceTemperatureData={deviceTemperatureStat}
                ready={!isEmpty(this.props.history)}
                green={isTrue(solar)}
                solarBoost={solarBoost}
                title="Statistics"
              />
            </History>
          </div>
        )}
      </div>
    );
  }
}

Main.propTypes = {
  children: PropTypes.object,
  match: PropTypes.object,
  fetch: PropTypes.func,
  eligibleToStop: PropTypes.bool,
  eligibleToStart: PropTypes.bool,
  isRequested: PropTypes.bool,
  update: PropTypes.func,
  start: PropTypes.func,
  stop: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingHidden: PropTypes.bool,
  isLoadingCharging: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  charger: PropTypes.object,
  totalConsumption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalReactiveConsumption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  consumptionLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  solar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  solarBoost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  history: PropTypes.array,
  stat: PropTypes.array,
  solarStat: PropTypes.array,
  deviceStat: PropTypes.array,
  deviceTemperatureStat: PropTypes.array,
  className: PropTypes.string,
  isAdmin: PropTypes.bool,
  pausedUntil: PropTypes.any,
  scheduledEnd: PropTypes.any,
  scheduled: PropTypes.any,
  started: PropTypes.any,
  learning: PropTypes.number,
  setCar: PropTypes.func,
  car: PropTypes.any,
  lastSoc: PropTypes.any,
  charging: PropTypes.object,
  // formValues: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsAppLoading,
  isLoadingCharging: selectIsChargingLoading,
  clientId: selectClientId,
  isRequested: selectIsRequested,
  eligibleToStart: selectEligibleToStart,
  eligibleToStop: selectEligibleToStop,
  error: selectError,
  totalConsumption: selectTotalConsumption,
  totalReactiveConsumption: selectTotalReactiveConsumption,
  consumptionLimit: selectConsumptionLimit,
  solar: selectSolar,
  solarBoost: selectSolarBoost,
  consumption: selectConsumptionAtStart,
  charger: selectCharger,
  charging: selectCharging,
  history: selectHistory,
  total: selectHistoryTotal,
  scheduled: selectScheduled,
  scheduledEnd: selectScheduledEnd,
  pausedUntil: selectPaused,
  started: selectStarted,
  stopped: selectStopped,
  learning: selectLearning,
  stat: selectStat,
  solarStat: selectSolarStat,
  deviceStat: selectDeviceStat,
  deviceTemperatureStat: selectDeviceTemperatureStat,
  car: selectDefaultCar,
  lastSoc: selectLastSOC,
  // formValues: (state) => (selectForm(state, { id: 'updateCharger' }) || {}).values,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options = {}, hidden = false) =>
    dispatch(fetchStatusStart(options, hidden)),
  update: (charger, car, soc) =>
    dispatch(updateCarOfChargingStart(charger, car, soc)),
  start: (charger, limit, scheduling, restarting, car, soc, connector) => {
    dispatch(
      chargingStart(charger, limit, scheduling, restarting, car, soc, connector)
    );
  },
  stop: (charger, force = 0, scheduling, pausing) =>
    dispatch(chargingStop(charger, force, scheduling, pausing)),
  setUUID: (uuid) => dispatch(setUUID(uuid)),
  setCar: (id) => dispatch(setDefaultCar(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
