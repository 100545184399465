import { createStore as createReduxStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import createSagaMiddleware from 'redux-saga';

import rootDSaga from './root-saga';

import rootReducer from './root-reducer';

const sagaMiddleware = createSagaMiddleware();

const MIDDLEWARES = [sagaMiddleware];

if (/localhost/.test(window.location.hostname)) {
  MIDDLEWARES.push(logger);
}

const createStore = (initialState) => {
  const store = createReduxStore(
    rootReducer,
    {
      ...initialState,
    },
    applyMiddleware(...MIDDLEWARES)
  );
  sagaMiddleware.run(rootDSaga);

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export default createStore;
