import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  // ReferenceArea,
} from 'recharts';
import { maxBy, minBy } from 'lodash';
import {
  chartColors,
  getDoc,
  isMobileWidth,
  colors,
  rangeLabels,
  moment,
  abbrValue,
  abbrPower,
} from '../../utils';

// import { range as lRange } from 'lodash';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

const minMax = [2, 5];
export default class TemperatureLine extends CommonChart {
  container = 'temperature';

  prepare(isActive = false) {
    const days = this.props.data ? Object.keys(this.props.data) : [];

    if (days.length) {
      const filter = this.props.filter;

      const data = {};
      const labels = {};
      const labelValues = {};

      const range = 'day';

      days.forEach((day) => {
        const stat = this.props.data[day];
        const temperatureData = getDoc(stat);
        if (temperatureData) {
          const name = moment(day).local();
          const label = moment(name).format('YYYY/MM/DD');

          labelValues[label] = translate(rangeLabels[range], {
            year: name.format('YYYY'),
            month: name.format('MMMM'),
            week: name.isoWeek(),
            quarter: name.quarter(),
            day: name.format('Do'),
          });

          labels[label] =
            labels[label] ||
            (filter[this.container] &&
            filter[this.container][label] !== undefined
              ? filter[this.container][label]
              : false);

          data[label] = {
            name: label,
            exactName: label,
            highLightColor:
              chartColors[
                (Object.keys(labels).indexOf(label) + 4) % chartColors.length
              ],
            payload: temperatureData ? Object.values(temperatureData) : [],
            hidden: true,
          };
        }
      });

      const labelObjectValues = Object.values(labels);
      let selectedLabel = labelObjectValues.findIndex(Boolean);
      selectedLabel =
        selectedLabel > -1 ? selectedLabel : labelObjectValues.length - 1;
      const selectedLabelKey = Object.keys(labels)[selectedLabel];
      this.setState({
        data: data[selectedLabelKey],
        selectedLabel,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  render() {
    const data = this.state.data.payload;
    const min = parseFloat((minBy(data, 'degree') || {}).degree, 10);
    const max = parseFloat((maxBy(data, 'degree') || {}).degree, 10);
    // const min = parseFloat((minBy(data, 'degree') || {}).degree, 10) - minMax[0];

    const veryLowDegree = (1 - (0 - min) / (max - min)) * 100;
    const lowDegree = (1 - (10 - min) / (max - min)) * 100;
    const highDegree = (1 - (25 - min) / (max - min)) * 100;
    const veryHighDegree = (1 - (30 - min) / (max - min)) * 100;

    const color =
      chartColors[(this.state.selectedLabel + 3) % chartColors.length];

    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">
                Actual device temperature
              </LocalizedText>
            </h3>
            <ResponsiveContainer className={Style.responsiveContainer}>
              <AreaChart
                className={Style.mainChart}
                width="80%"
                height="100%"
                data={data}
                stackOffset="sign"
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                // onMouseMove={() => this.props.onHoverChart(this.container, this.state.data)}
              >
                <defs>
                  <linearGradient id="colorDegree" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset={`${veryHighDegree - 10}%`}
                      stopColor={colors.red}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${veryHighDegree + 10}%`}
                      stopColor={colors.orange}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${highDegree - 10}%`}
                      stopColor={colors.orange}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${highDegree + 10}%`}
                      stopColor={colors.green}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${lowDegree - 10}%`}
                      stopColor={colors.green}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${lowDegree + 10}%`}
                      stopColor={colors.blue}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${veryLowDegree - 10}%`}
                      stopColor={colors.blue}
                      stopOpacity={1}
                    />
                    <stop
                      offset={`${veryLowDegree + 10}%`}
                      stopColor={colors.ownWhite}
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis
                  dataKey="degree"
                  yAxisId="degree"
                  hide={isMobileWidth()}
                  tickFormatter={(value) => {
                    return abbrValue(value, 1, 'C°');
                  }}
                  domain={[`dataMin - ${minMax[0]}`, `dataMax + ${minMax[1]}`]}
                  axisLine={false}
                  tickLine={false}
                  orientation="left"
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label) =>
                    translate('Actual device temperature {{label}}', { label })
                  }
                  itemStyle={{ textAlign: 'left' }}
                  content={<CustomTooltip />}
                  formatter={(value, name, payload) => {
                    if (name === 'degree') {
                      return [
                        abbrValue(value, 1, 'C°'),
                        translate('Actual device temperature'),
                      ];
                    }

                    return [
                      translate('Yes ({{power}})', {
                        power: abbrPower(
                          payload.payload && payload.payload.charging
                        ),
                      }),
                      translate('Charging in progress'),
                    ];
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '1em auto auto',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.entries(this.state.labelValues || {}).map(
                    (entry, index) => ({
                      label: entry[1],
                      value: entry[0],
                      type: 'square',
                      color: chartColors[(index + 3) % chartColors.length],
                      container: this.container,
                    })
                  )}
                  onClick={(o) =>
                    this.props.onClick(o, this.state.labels, true, false)
                  }
                  // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, container, label } = entry;
                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                          opacity: !this.props.isChecked(
                            value,
                            container,
                            true,
                            this.state.labelValues
                          )
                            ? 0.3
                            : 1,
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                />
                {/* <ReferenceArea 
                yAxisId='degree' 
                y1={0} 
                y2={25}
                label={{
                  fill: colors.ownWhite, 
                  stroke: colors.darkGrey,
                  strokeWidth: 3,
                  paintOrder: 'stroke',
                  value: translate('High'),
                }} 
                strokeWidth={2}
                strokeDasharray='10 10'
                opacity={.5} 
                fill={colors.orange} /> */}
                <Area
                  strokeWidth={0}
                  yAxisId="degree"
                  type="monotone"
                  dataKey={(item) => {
                    if (!item.charging || parseFloat(item.charging, 10) <= 0) {
                      return null;
                    }

                    return max + minMax[1];
                  }}
                  activeDot={false}
                  fill="#ffffff44"
                />
                <Area
                  strokeWidth={2}
                  type="monotone"
                  dataKey="degree"
                  yAxisId="degree"
                  stroke={color}
                  fill="url(#colorDegree)"
                  activeDot={{ r: 8, opacity: 1 }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

TemperatureLine.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

TemperatureLine.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  chargings: PropTypes.array,
  onClick: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
};
