import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Style from './App.module.scss';
import Header from './components/Header';
import Chargers from './components/Chargers';
import Admin from './components/Admin';
import Profile from './components/Profile';
import { withNamespaces } from 'react-i18next';
import { Context } from './utils';
import Shared from './components/Shared';

class App extends React.Component {
  state = {
    isAdmin: false,
    setAdmin: (value) => this.setAdmin(value),
  };

  setAdmin(value) {
    this.setState({ isAdmin: !!value });
  }

  render() {
    return (
      <div className={Style.App}>
        <Router>
          <Header />
          <Context.Provider value={this.state}>
            <Context.Consumer>
              {({ setAdmin }) => {
                return (
                  <Switch>
                    <Route
                      path="/profile"
                      render={(matchProps) => <Profile {...matchProps} />}
                    />
                    <Route
                      path="/charging"
                      render={(matchProps) => <Chargers {...matchProps} />}
                    />
                    <Route
                      path="/:chargerId/admin"
                      render={(matchProps) => (
                        <Admin isAdmin setAdmin={setAdmin} {...matchProps} />
                      )}
                    />
                    <Route
                      path="/:chargerId/smartStart/:username/:key"
                      render={(matchProps) => (
                        <Admin
                          setAdmin={setAdmin}
                          {...matchProps}
                          smart="start"
                        />
                      )}
                    />
                    <Route
                      path="/:chargerId/smartStop/:username/:key"
                      render={(matchProps) => (
                        <Admin
                          setAdmin={setAdmin}
                          {...matchProps}
                          smart="stop"
                        />
                      )}
                    />
                    <Route
                      path="/:chargerId/smartCheck/:username/:key"
                      render={(matchProps) => (
                        <Admin
                          setAdmin={setAdmin}
                          {...matchProps}
                          smart="check"
                        />
                      )}
                    />
                    <Route
                      path="/:chargerId/shared/:chargingHash"
                      render={(matchProps) => <Shared {...matchProps} />}
                    />
                    <Route
                      path="/:chargerId"
                      render={(matchProps) => (
                        <Admin setAdmin={setAdmin} {...matchProps} />
                      )}
                    />
                    {/* <Route path='/:chargerId' render={ (matchProps) => <Main {...matchProps} /> } /> */}
                    <Route
                      path="/"
                      render={(matchProps) => <Chargers {...matchProps} />}
                    />
                  </Switch>
                );
              }}
            </Context.Consumer>
          </Context.Provider>
        </Router>
      </div>
    );
  }
}

export default withNamespaces()(App);
