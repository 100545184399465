import React from 'react';
import PropTypes from 'prop-types';
import Style from './Profile.module.scss';
import { updateStart } from '../redux/user/user.actions';
import {
  selectIsUserLoggedIn,
  selectUserProfile,
} from '../redux/user/user.selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import sha1 from 'sha1';
import Form, { RadioButtons } from '../containers/form/Form';
import { passwordsMatch, requiredIf } from '../containers/form/Form.validators';
import { Redirect } from 'react-router-dom';
import { getInitialValues } from '../containers/form/Form.utils';
import { colors, difference, isTrue } from '../utils';
import { isEmpty } from 'lodash';
import LocalizedText, { translate } from './LocalizedText';
import { SITE_URL } from '../redux/app/app.utils';
import Icon from './Icon';

const passwordValidator = passwordsMatch('profile_newPassword');
const requiredIfPassword = requiredIf([
  'profile_newPassword',
  'profile_newPasswordVerification',
]);
const requiredIfSmart = requiredIf(['profile_smart'], true);

class Profile extends React.Component {
  shouldComponentUpdate(prevProps, prevState) {
    return !isEmpty(difference(prevProps, this.props));
  }

  onSubmit(values) {
    const body = {
      username: values.profile_username,
    };

    if (values.profile_newPassword && values.profile_newPasswordVerification) {
      body.newPassword = sha1(values.profile_newPassword);
      body.newPasswordVerification = sha1(
        values.profile_newPasswordVerification
      );
    }

    body.smartStartStop =
      values.profile_smart === '1' && values.profile_smartKey ? true : false;
    body.smartKey = values.profile_smartKey;

    this.props.save(body);
    this.props.callback && this.props.callback();
  }

  getSmartUrl(action) {
    return `${SITE_URL}${this.props.chargerId}/${action}/${this.props.profile.username}/${this.props.profile.smartKey}`;
  }

  render() {
    if (!this.props.isUserLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <div className={Style.profile}>
        <Form
          id="profile"
          title={translate('Profile')}
          submitLabel={translate('Save')}
          submitButtonStyle={Style.submitButton}
          submitContainerStyle={Style.submitContainer}
          onSubmit={(...props) => this.onSubmit(...props)}
          initialValues={getInitialValues(this.props.profile, 'profile_')}
          fields={[
            {
              id: 'username',
              label: translate('Username'),
              required: true,
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('Username'),
            },
            // {
            //   id: 'oldPassword',
            //   label: 'Old password',
            //   type: 'password',
            //   validators: [requiredIfPassword],
            //   style: {
            //     container: Style.container,
            //     label: Style.label,
            //     element: Style.element,
            //   },
            //   placeholder: 'Old password',
            // },
            {
              id: 'newPassword',
              label: translate('New password'),
              type: 'password',
              validators: [requiredIfPassword],
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('New password'),
            },
            {
              id: 'newPasswordVerification',
              label: translate('New password verification'),
              type: 'password',
              validators: [requiredIfPassword, passwordValidator],
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('New password verification'),
            },
            {
              id: 'smart',
              label: translate('Enable smart charging'),
              type: RadioButtons,
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              options: ['No', 'Yes'],
            },
            {
              id: 'smartKey',
              label: translate('Smart key'),
              type: 'text',
              validators: [requiredIfSmart],
              style: {
                container: Style.container,
                label: Style.label,
                element: Style.element,
              },
              placeholder: translate('Smart key'),
            },
          ]}
        />
        <div>
          <LocalizedText tag="h1">About Smart Charging</LocalizedText>
          <LocalizedText tag="div">
            Smart charging makes you able to start/stop an individual charging
            session only by one click and on any device.
          </LocalizedText>
          {isTrue(this.props.profile.smart) ? (
            <div>
              <div className={Style.title}>
                <Icon
                  icon="BiLink"
                  className={Style.icon}
                  style={{ background: colors.orange }}
                />
                <LocalizedText tag="h2">Smart links</LocalizedText>
              </div>
              <ul>
                <LocalizedText
                  tag="li"
                  variables={{
                    link: this.getSmartUrl('smartStart'),
                  }}
                  children="Smart start {{link}}"
                />
                <LocalizedText
                  tag="li"
                  variables={{
                    link: this.getSmartUrl('smartCheck'),
                  }}
                  children="Smart check {{link}}"
                />
                <LocalizedText
                  tag="li"
                  variables={{
                    link: this.getSmartUrl('smartStop'),
                  }}
                  children="Smart stop {{link}}"
                />
              </ul>
            </div>
          ) : null}
          <div>
            <div className={Style.title}>
              <Icon icon="Others/Shortcuts" className={Style.icon} />
              <LocalizedText tag="h2">iOS Shortcuts</LocalizedText>
            </div>
            <LocalizedText>
              Import these shortcuts on your iPhone and answer the import
              questions
            </LocalizedText>
            <ol>
              <li>
                <LocalizedText>What's the ID of the charger?</LocalizedText>-
                <i>
                  <b>{this.props.chargerId}</b>
                </i>
              </li>
              <li>
                <LocalizedText>What's your username?</LocalizedText>-
                <i>
                  <b>{this.props.profile.username}</b>
                </i>
              </li>
              <li>
                <LocalizedText>What's your key?</LocalizedText>-
                <i>
                  <b>{this.props.profile.smartKey}</b>
                </i>
              </li>
            </ol>
            <div className={Style.iconList}>
              <a
                href="workflow://shortcuts/d2cc04fe8d2c412da508e70ebb4c6eed"
                className={Style.iconLink}
              >
                <Icon
                  icon="Others/EVCar"
                  className={Style.icon}
                  style={{
                    background: 'linear-gradient(180deg, #54cd6a, #36c553)',
                  }}
                />
                <LocalizedText
                  tag="span"
                  className={Style.iconLabel}
                  children="Smart start"
                />
              </a>
              <a
                href="workflow://shortcuts/fa97e155c7f8426f9bd20e4ba0f06de9"
                className={Style.iconLink}
              >
                <Icon
                  icon="Others/EVCar"
                  className={Style.icon}
                  style={{
                    background: 'linear-gradient(180deg, #26baf6, #02aff6)',
                  }}
                />
                <LocalizedText
                  tag="span"
                  className={Style.iconLabel}
                  children="Smart check"
                />
              </a>
              <a
                href="workflow://shortcuts/9fe29c481bb4400bae09f425d2359b2e"
                className={Style.iconLink}
              >
                <Icon
                  icon="Others/EVCar"
                  className={Style.icon}
                  style={{
                    background: 'linear-gradient(180deg, #f46f73, #f1585e)',
                  }}
                />
                <LocalizedText
                  tag="span"
                  className={Style.iconLabel}
                  children="Smart stop"
                />
              </a>
            </div>
            <LocalizedText>Add shortcut manually</LocalizedText>
            <ol>
              <li>
                <LocalizedText>Open the </LocalizedText>
                <a href="shortcuts://create-shortcut">Shortcuts</a>
                <LocalizedText> app on your iPhone</LocalizedText>
              </li>
              <LocalizedText tag="li">Add action</LocalizedText>
              <LocalizedText tag="li">Search for Safari</LocalizedText>
              <LocalizedText tag="li">Select Open URLs</LocalizedText>
              <LocalizedText tag="li">
                Copy and paste your smart link
              </LocalizedText>
              <LocalizedText tag="li">
                The name you set for the new Shortcut is your command that Siri
                can run
              </LocalizedText>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.object,
  save: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  callback: PropTypes.func,
  chargerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = createStructuredSelector({
  profile: selectUserProfile,
  isUserLoggedIn: selectIsUserLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  save: (body) => dispatch(updateStart({ body })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
