import { get } from 'lodash';
import PropTypes from 'prop-types';
import { DefaultTooltipContent } from './DefaultTooltip';

export const CustomTooltip = (props) => {
  if (!props.active) {
    return null;
  }

  const newPayload = [
    ...(props.prependPayload || []),
    ...(props.payload || []),
    ...(props.appendPayload || []),
  ].reduce((accu, current) => {
    if (!current.validator) {
      return [...accu, current];
    }

    if (!current.validator(props.payload)) {
      return accu;
    }

    return [
      ...accu,
      {
        ...current,
        payload: props.payload,
      },
    ];
  }, []);

  return (
    <DefaultTooltipContent
      {...props}
      payload={newPayload.map((item) => {
        return {
          ...item,
          color:
            item.color ||
            item.fill ||
            get(item, 'payload.color') ||
            get(item, 'payload.fill') ||
            get(item, 'payload.highLightColor'),
        };
      })}
    />
  );
};

CustomTooltip.propTypes = {
  label: PropTypes.any,
  active: PropTypes.bool,
  payload: PropTypes.array,
  prependPayload: PropTypes.array,
  appendPayload: PropTypes.array,
  validator: PropTypes.func,
};
