import React from 'react';
import PropTypes from 'prop-types';
import Style from './Error.module.scss';
import LocalizedText from './LocalizedText';

class Error extends React.Component {
  render() {
    return this.props.children ? (
      <div className={Style.error}>
        <LocalizedText>{this.props.children}</LocalizedText>
      </div>
    ) : null;
  }
}

Error.propTypes = {
  children: PropTypes.any,
};

export default Error;
