import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Cell,
  ReferenceLine,
} from 'recharts';

import {
  abbrConsumption,
  chartColors,
  getDoc,
  isMobileWidth,
  colors,
  rangeLabels,
  getSvg,
  moment,
  inRange,
} from '../../utils';

import { find, findKey, get, maxBy, sumBy, range as lRange } from 'lodash';
import ComboFlyout from '../../components/ComboFlyout';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

export default class SolarBar extends CommonChart {
  container = 'solar';

  getChargingsFromRange(chargings, range) {
    const keys = Object.keys(chargings);
    window.asd = true;
    const filteredKeys = keys.filter(inRange(range));
    window.asd = false;

    const newChargings = [];
    filteredKeys.forEach((key) => {
      newChargings.push(chargings[key]);
    });

    return newChargings.flat();
  }

  prepare(isActive = false, givenRange = false) {
    const dataValues = this.props.data ? Object.values(this.props.data) : [];
    if (dataValues.length) {
      const chargings = {};
      if (this.props.chargings.length) {
        this.props.chargings.forEach((stat) => {
          const datum = getDoc(stat);
          const date = moment(datum.actual * 1000).format('YYYY/MM/DD');

          if (!chargings[date]) {
            chargings[date] = [];
          }

          if (datum.reactiveConsumption > 0) {
            chargings[date].push(datum);
          }
        });
      }

      const filter = this.props.filter;
      const givenData = dataValues.map((stat) => {
        const datum = getDoc(stat);
        datum.time = moment(datum.day).utc().valueOf();
        datum.total = parseFloat(datum.max, 10) - parseFloat(datum.min, 10);

        return datum;
      });
      const data = [];
      const labels = {};
      const labelValues = {};

      // fake
      // const givenData = docata.reduce((acc, curr) => {
      //   const prev2020 = { ...curr };
      //   const prev2019 = { ...curr };

      //   prev2019.time =
      //     moment(prev2020.time).year(2019).subtract(2, 'days').unix() * 1000;
      //   prev2019.day = moment(prev2019.time)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .format('YYYY-MM-DD');
      //   prev2020.time =
      //     moment(prev2020.time).year(2020).subtract(3, 'days').unix() * 1000;
      //   prev2020.day = moment(prev2019.time)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .format('YYYY-MM-DD');
      //   return [...acc, curr, prev2019, prev2020];
      //   // return [...acc, curr];
      // }, []);

      givenData.sort((a, b) => {
        if (a.time < b.time) {
          return 1;
        }
        if (a.time > b.time) {
          return -1;
        }
        return 0;
      });

      let currentMonth = findKey(filter[this.container], (item) => !!item);
      const year = this.getYear();
      const range = givenRange || this.getRange();
      const firstSolarDay = moment(
        givenData[givenData.length - 1].time
      ).startOf(range);
      const lastSolarDay = moment(givenData[0].time).endOf(range);

      const diffDays =
        lastSolarDay.diff(firstSolarDay, this.ranges[range][0]) + 1;

      const diffYears = [
        firstSolarDay.format('YYYY'),
        lastSolarDay.format('YYYY'),
      ];

      currentMonth =
        currentMonth ||
        (`${year}` !== diffYears[1] ? `${year}/12/31` : Date.now());

      if (moment(currentMonth).endOf(range).diff(lastSolarDay, 'days') > 0) {
        currentMonth = lastSolarDay.clone();
      }

      const firstDay = moment(currentMonth)
        .year(year)
        .startOf(range === 'all' ? 'year' : range);
      const lastDay = moment(currentMonth)
        .year(year)
        .endOf(range === 'all' ? 'year' : range);

      const chargingRange = moment.range(firstDay, lastDay);

      const years = lRange(diffYears[0], parseInt(diffYears[1], 10) + 1);

      for (let i = 0; i < diffDays; i++) {
        const name = firstSolarDay.local();
        if (name.format('YYYY') === `${year}`) {
          const label = moment(name).startOf(range).format('YYYY/MM/DD');

          labelValues[label] = translate(rangeLabels[range], {
            year: name.format('YYYY'),
            month: name.format('MMMM'),
            week: name.isoWeek(),
            quarter: name.quarter(),
          });

          labels[label] =
            labels[label] ||
            (filter[this.container] &&
            filter[this.container][label] !== undefined
              ? filter[this.container][label]
              : false);

          // eslint-disable-next-line max-len
          const nameOfRange = `${moment(name)
            .startOf(this.ranges[range][1])
            .format('YYYY/MM/DD')}-${moment(name)
            .endOf(this.ranges[range][1])
            .format('YYYY/MM/DD')}`;

          this.getChargingsFromRange(chargings, nameOfRange);
          const current = {
            name: label,
            exactName:
              this.ranges[range][0] === 'days'
                ? name.format('YYYY/MM/DD')
                : nameOfRange,
            highLightColor:
              chartColors[
                (Object.keys(labels).indexOf(label) + 4) % chartColors.length
              ],
            stacks: [],
            payload:
              (this.ranges[range][0] === 'days'
                ? chargings[name.format('YYYY/MM/DD')]
                : this.getChargingsFromRange(chargings, nameOfRange)) || [],
            // payload: chargings[name.format('YYYY/MM/DD')] || [],
            payloadItems: [],
            hidden: true,
          };

          if (chargingRange.contains(firstSolarDay)) {
            current.hidden = false;
          }

          data.push(current);
        }

        firstSolarDay.add(1, this.ranges[range][0]);
      }

      const keys = [];
      givenData.reverse().forEach((item) => {
        const { time, total } = item;
        const name = moment(time).local().format('YYYY/MM/DD');

        const current = find(data, inRange(name));

        if (current) {
          const dataKey = 's0';
          if (!keys.includes(dataKey)) {
            keys.push(dataKey);
          }
          current.stacks.push(dataKey);
          current[dataKey] = (current[dataKey] || 0) + total;
          current[`c${dataKey}`] = current.payload.reduce(
            (accu, curr) => accu - curr.reactiveConsumption,
            0
          );

          item.dataKey = dataKey;

          current.payloadItems.push(item);
        }
      });

      if (isActive) {
        this.props.onHoverChart(this.container, data, range);
      }

      this.setState({
        data,
        range,
        years,
        year: year || years[years.length - 1],
        keys,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  render() {
    const data = this.state.data.filter((datum) => !datum.hidden);
    const max = maxBy(data, (datum) => {
      return datum.payloadItems.reduce((accu, curr) => accu + curr.total, 0);
    });

    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">
                Green energy production/usage
              </LocalizedText>
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                disabled={this.state.years?.length <= 1}
                id="range"
                label={translate('Year')}
                value={this.state.year}
                onChange={(e) => this.onYearChange(e)}
                options={this.state.years}
              />
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                autoOpening
                id="range"
                label={translate('Range')}
                value={this.state.range}
                onChange={(e) => this.onRangeChange(e)}
                options={{
                  isoWeek: translate('Weekly'),
                  month: translate('Monthly'),
                  quarter: translate('Quarterly'),
                  year: translate('Yearly'),
                  all: translate('All'),
                }}
              />
            </h3>
            <ResponsiveContainer className={Style.responsiveContainer}>
              <BarChart
                className={Style.mainChart}
                width="80%"
                height="100%"
                data={data}
                stackOffset="sign"
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                // onMouseMove={() => this.props.onHoverChart(this.container, this.state.data)}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="exactName" />
                <YAxis
                  hide={isMobileWidth()}
                  tickFormatter={(value) => {
                    return abbrConsumption(value);
                  }}
                  axisLine={false}
                  tickLine={false}
                  orientation="left"
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label) =>
                    translate('Producted/used green energy {{label}}', {
                      label,
                    })
                  }
                  itemStyle={{ textAlign: 'left' }}
                  content={<CustomTooltip />}
                  formatter={(value, name, entry) => {
                    const entryPayload = find(
                      get(entry, 'payload.payloadItems'),
                      (payload) => {
                        return payload.dataKey === name;
                      }
                    );

                    if (entryPayload) {
                      const usedEntryPayload = -sumBy(
                        get(entry, 'payload.payload'),
                        'reactiveConsumption'
                      );
                      return [
                        <>
                          {getSvg(entry.payload.highLightColor)}
                          {abbrConsumption(value)}
                          {usedEntryPayload < 0
                            ? [
                                ' / ',
                                getSvg(colors.ownBlack),
                                <span
                                  style={{ color: colors.ownBlack }}
                                  key="subvalue"
                                >
                                  {abbrConsumption(usedEntryPayload)}
                                </span>,
                              ]
                            : ''}
                        </>,
                      ];
                    }

                    return [];
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '1em auto auto',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.entries(this.state.labelValues || {}).map(
                    (entry, index) => ({
                      label: entry[1],
                      value: entry[0],
                      type: 'square',
                      color: chartColors[(index + 4) % chartColors.length],
                      container: this.container,
                    })
                  )}
                  onClick={(o) =>
                    this.props.onClick(o, this.state.labels, true)
                  }
                  // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, container, label } = entry;
                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                          opacity: !this.props.isChecked(
                            value,
                            container,
                            true,
                            this.state.labelValues
                          )
                            ? 0.3
                            : 1,
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                />
                <ReferenceLine y={0} stroke={colors.ownWhite} />
                {this.state.keys.map((key, index) => (
                  <Bar
                    key={`c${key}`}
                    dataKey={`c${key}`}
                    stackId="reactiveConsumption"
                    opacity={1 - index / (this.state.keys.length * 2)}
                    label={{
                      angle: -90,
                      dataKey: `c${key}`,
                      position: 'center',
                      fill: colors.ownWhite,
                      stroke: colors.darkGrey,
                      strokeWidth: 3,
                      paintOrder: 'stroke',
                      formatter: (value, entry) => {
                        if (
                          !value ||
                          value >
                            -(
                              max.payloadItems.reduce((accu, curr) => {
                                return accu + curr.total;
                              }, 0) / (isMobileWidth() ? 3 : 3.5)
                            )
                        ) {
                          return '';
                        }

                        return abbrConsumption(value);
                      },
                    }}
                    cursor="pointer"
                  >
                    {data.map((datum, cellIndex) => {
                      return (
                        <Cell
                          key={`cell-${cellIndex}`}
                          fill={colors.ownBlack}
                        />
                      );
                    })}
                  </Bar>
                ))}
                {this.state.keys.map((key, index) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    stackId="reactiveConsumption"
                    opacity={1 - index / (this.state.keys.length * 2)}
                    label={{
                      angle: -90,
                      dataKey: key,
                      position: 'center',
                      fill: colors.ownWhite,
                      stroke: colors.darkGrey,
                      strokeWidth: 3,
                      paintOrder: 'stroke',
                      formatter: (value, entry) => {
                        if (
                          !value ||
                          value <
                            max.payloadItems.reduce((accu, curr) => {
                              return accu + curr.total;
                            }, 0) /
                              (isMobileWidth() ? 3 : 3.5)
                        ) {
                          return '';
                        }

                        return abbrConsumption(value);
                      },
                    }}
                    cursor="pointer"
                  >
                    {data.map((datum, cellIndex) => {
                      return (
                        <Cell
                          key={`cell-${cellIndex}`}
                          fill={datum.highLightColor}
                        />
                      );
                    })}
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

SolarBar.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

SolarBar.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  chargings: PropTypes.array,
  onClick: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
};
