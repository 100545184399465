import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  Tooltip,
} from 'recharts';
import {
  abbrConsumption,
  chartColors,
  getCost,
  getDoc,
  colors,
  getSvg,
} from '../../utils';
import { find, range, uniqBy } from 'lodash';
import LocalizedText, { translate } from '../../components/LocalizedText';
import moment from 'moment';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';
import ComboFlyout from '../../components/ComboFlyout';

const RINGS = {
  INNER: 'Inner',
  MIDDLE1: 'Middle1',
  MIDDLE2: 'Middle2',
  OUTER: 'Outer',
};

const maxRadius = 80;
// const minRadius = 35;

const radiuses = {
  [RINGS.INNER]: [0, 20],
  [RINGS.MIDDLE1]: [25, 40],
  [RINGS.MIDDLE2]: [45, 60],
  [RINGS.OUTER]: [65, 80],
};
export default class UserPie extends CommonChart {
  state = {
    data: [],
    rendered: false,
    activeIndexInner: -1,
    activeIndexOuter: -1,
    activeIndexMiddle1: -1,
    activeIndexMiddle2: -1,
  };

  container = 'user';

  prepare(isActive = false) {
    if (this.props.data.length) {
      const year = this.getYear();
      const filter = this.props.filter;
      const givenData = uniqBy(
        this.props.data.map((stat) => getDoc(stat)),
        'id'
      );
      const data = [];
      const labels = {};

      // fake;
      // const givenData = docata.reduce((acc, curr) => {
      //   const prev2020 = { ...curr };
      //   const prev2019 = { ...curr };

      //   prev2019.actual = moment(prev2020.actual * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.started = moment(prev2020.started * 1000)
      //     .year(2019)
      //     .subtract(2, 'days')
      //     .unix();
      //   prev2019.ended = moment(prev2020.ended * 1000)
      //     .year(2019)
      //     .subtract(1, 'days')
      //     .unix();
      //   prev2020.actual = moment(prev2020.actual * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.started = moment(prev2020.started * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   prev2020.ended = moment(prev2020.ended * 1000)
      //     .year(2020)
      //     .subtract(3, 'days')
      //     .unix();
      //   return [...acc, curr, prev2019, prev2020];
      //   // return [...acc, curr];
      // }, []);

      givenData.sort((a, b) => {
        // if (a.total > b.total) {
        //   return 1;
        // }
        // if (a.total < b.total) {
        //   return -1;
        // }

        if (a.started > b.started) {
          return 1;
        }
        if (a.started < b.started) {
          return -1;
        }

        return 0;
      });

      const firstChargingDay = moment(givenData[0].started * 1000);
      const lastChargingDay = moment(
        givenData[givenData.length - 1].started * 1000
      );
      const diffYears = [
        firstChargingDay.format('YYYY'),
        lastChargingDay.format('YYYY'),
      ];
      const years = range(diffYears[0], parseInt(diffYears[1], 10) + 1);

      givenData.forEach((item) => {
        const {
          total,
          reactiveTotal,
          user,
          costUnit,
          costPerMinute,
          costPerConsumption,
          started,
          ended,
        } = item;
        if (moment(started * 1000).format('YYYY') === `${year}`) {
          const name = user;
          const time = (ended - started) * 1000;
          const cost = getCost(
            time / 60000,
            total / 1000,
            costPerMinute,
            costPerConsumption,
            reactiveTotal / 1000
          );

          labels[name] =
            labels[name] ||
            (filter[this.container] &&
            filter[this.container][name] !== undefined
              ? filter[this.container][name]
              : true);

          let current = find(data, (datum) => datum.name === name);

          if (!current) {
            current = {
              name,
              total: 0,
              reactiveTotal: 0,
              cost: 0,
              costUnit,
              time: 0,
              fill: chartColors[
                Object.keys(labels).indexOf(name) % chartColors.length
              ],
              payload: [],
            };

            data.push(current);
          }

          current.payload.push(item);

          if (labels[name] !== false) {
            current.total += total;
            current.reactiveTotal += reactiveTotal;
            current.cost += cost;
            current.time += time;
          }
        }
      });

      if (isActive) {
        this.props.onHoverChart(this.container, data);
      }

      this.setState({
        data,
        years,
        year: year || years[years.length - 1],
        labels,
        rendered: true,
      });
    }
  }

  renderCustomizedLabel(
    {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
      value,
      payload,
    },
    ring
  ) {
    const RADIAN = Math.PI / 180;
    // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    // const x = (cx + radius * Math.cos(-midAngle * RADIAN)) * 0.99;
    // const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const labels = this.getFormattedValues(value, payload);

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={colors.ownWhite}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={colors.ownWhite} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          fill={colors.ownWhite}
          stroke={colors.darkGrey}
          strokeWidth={3}
          paintOrder="stroke"
          textAnchor={textAnchor}
        >
          {labels[ring]}
        </text>
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} 
          textAnchor={textAnchor} 
          fill={colors.ownWhite}
          stroke={colors.darkGrey} 
          strokeWidth={3}
          paintOrder='stroke' 
        >
          {translate('(Rate {{value}}%)', { value: (percent * 100).toFixed(2) })}
        </text> */}
      </g>
    );
  }

  getFormattedValues(value, payload, raw = false) {
    const values = {
      raw: {
        [RINGS.INNER]: abbrConsumption(value),
        [RINGS.MIDDLE1]: abbrConsumption(value),
        [RINGS.MIDDLE2]: moment(value).local().format('HH:mm:ss'),
        [RINGS.OUTER]: translate('{{unit}} {{value}}', {
          value: value <= 0 ? 0 : value.toFixed(value % 1 ? 2 : 0),
          unit: payload.costUnit,
        }),
      },
    };

    values.raw.total = values.raw[RINGS.INNER];
    values.raw.reactiveTotal = values.raw[RINGS.MIDDLE1];
    values.raw.time = values.raw[RINGS.MIDDLE2];
    values.raw.cost = values.raw[RINGS.OUTER];

    values[RINGS.INNER] = translate('Energy charged {{value}}', {
      value: raw ? '' : values.raw[RINGS.INNER],
    });
    values[RINGS.MIDDLE1] = translate('Green energy used {{value}}', {
      value: raw ? '' : values.raw[RINGS.MIDDLE1],
    });
    values[RINGS.MIDDLE2] = translate('Time {{value}}', {
      value: raw ? '' : values.raw[RINGS.MIDDLE2],
    });
    values[RINGS.OUTER] = translate('Cost {{unit}} {{value}}', {
      value: raw ? '' : values.raw[RINGS.OUTER],
      unit: raw ? '' : payload.costUnit,
    });

    values.total = values[RINGS.INNER];
    values.reactiveTotal = values[RINGS.MIDDLE1];
    values.time = values[RINGS.MIDDLE2];
    values.cost = values[RINGS.OUTER];

    return values;
  }

  renderActiveShape(props, ring) {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius: outerRadiusProp,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const values = this.getFormattedValues(value, payload);
    const ringContents = {
      [RINGS.INNER]: {
        radius: (outerRadiusProp / radiuses[RINGS.INNER][1]) * maxRadius,
        label: values[RINGS.INNER],
        opacity: 0.6,
      },
      [RINGS.MIDDLE1]: {
        radius: (outerRadiusProp / radiuses[RINGS.MIDDLE1][1]) * maxRadius,
        label: values[RINGS.MIDDLE1],
        opacity: 0.6,
      },
      [RINGS.MIDDLE2]: {
        radius: (outerRadiusProp / radiuses[RINGS.MIDDLE2][1]) * maxRadius,
        label: values[RINGS.MIDDLE2],
        opacity: 0.8,
      },
      [RINGS.OUTER]: {
        radius: outerRadiusProp,
        label: values[RINGS.OUTER],
        opacity: 1,
      },
    };

    const outerRadius = ringContents[ring].radius;
    const sx = cx + (outerRadiusProp + 10) * cos;
    const sy = cy + (outerRadiusProp + 10) * sin;
    const mx = cx + (outerRadiusProp + 30) * cos;
    const my = cy + (outerRadiusProp + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const label = ringContents[ring].label;

    return (
      <g id="activeShape">
        <Sector
          cx={cx}
          cy={cy}
          outerRadius={outerRadius * 2}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={colors.backgroundGrey}
        />
        <Sector
          id="activeSector"
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadiusProp}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          opacity={ringContents[ring].opacity}
          onMouseMove={(...props) => this.onPieEnter(props, ring)}
          onMouseOut={(...props) => this.onPieLeave(props, ring)}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadiusProp + 6}
          outerRadius={outerRadiusProp + 10}
          fill={fill}
          opacity={ringContents[ring].opacity}
        />
        <g id="activeLabel" style={{ pointerEvents: 'none' }}>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            opacity={ringContents[ring].opacityv}
            fill="none"
          />
          <circle
            cx={ex}
            cy={ey}
            r={2}
            fill={fill}
            opacity={ringContents[ring].opacity}
            stroke="none"
          />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill={colors.ownWhite}
          >
            {label}
          </text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill={colors.lightGray}
          >
            {translate('(Rate {{value}}%)', {
              value: (percent * 100).toFixed(2),
            })}
          </text>

          <g stroke={colors.darkGrey} strokeWidth={3} paintOrder="stroke">
            <text
              x={cx}
              y={cy}
              dy={0}
              textAnchor="middle"
              fill={colors.ownWhite}
            >
              {label}
            </text>
            <text
              x={cx}
              y={cy}
              dy={20}
              textAnchor="middle"
              fill={colors.ownWhite}
            >
              {translate('(Rate {{value}}%)', {
                value: (percent * 100).toFixed(2),
              })}
            </text>

            <text
              x={cx}
              y={cy}
              dy={-15}
              textAnchor="middle"
              fill={colors.ownWhite}
            >
              {translate(
                payload.name.indexOf('x:') >= 0 ? 'User' : payload.name
              )}
            </text>
          </g>
        </g>
      </g>
    );
  }

  onPieCheck = ([_, e]) => {
    if (e.target.getAttribute('xlink:href') === '#activeShape') {
      this.onPieLeave(true);
    }
  };

  onPieEnter([_, index, e], ring) {
    clearTimeout(this.setLeave);
    if (
      e.target.classList.contains('recharts-sector') &&
      e.target.getAttribute('fill') === colors.darkGrey
    ) {
      return this.onPieLeave([_, index, e], ring);
    }
    const ringKey = `activeIndex${ring}`;
    if (index !== this.state[ringKey]) {
      this.setState({
        [`activeIndex${RINGS.INNER}`]: -1,
        [`activeIndex${RINGS.MIDDLE1}`]: -1,
        [`activeIndex${RINGS.MIDDLE2}`]: -1,
        [`activeIndex${RINGS.OUTER}`]: -1,
        [ringKey]: index,
      });
    }
  }

  onPieLeave(force = false) {
    const doLeave = () => {
      this.setState({
        [`activeIndex${RINGS.INNER}`]: -1,
        [`activeIndex${RINGS.MIDDLE1}`]: -1,
        [`activeIndex${RINGS.MIDDLE2}`]: -1,
        [`activeIndex${RINGS.OUTER}`]: -1,
      });
    };
    if (force) {
      return doLeave();
    }

    this.setLeave = setTimeout(doLeave, 200);
  }

  render() {
    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText>Users' charging</LocalizedText>
              <ComboFlyout
                className={Style.formControl}
                labelClassName={Style.label}
                selectClassName={Style.select}
                shownLabel
                disabled={this.state.years?.length <= 1}
                autoOpening
                id="range"
                label={translate('Year')}
                value={this.state.year}
                onChange={(e) => this.onYearChange(e)}
                options={this.state.years}
              />
            </h3>
            <ResponsiveContainer className={Style.pieResponsiveContainer}>
              <PieChart
                className={Style.mainChart}
                onMouseMove={(...props) => this.onPieCheck(props)}
                // onMouseOut={(...props) => this.onPieLeave(props)}
              >
                <Pie
                  data={this.state.data}
                  activeIndex={this.state.activeIndexOuter}
                  activeShape={(props) =>
                    this.renderActiveShape(props, RINGS.OUTER)
                  }
                  onMouseMove={(...props) =>
                    this.onPieEnter(props, RINGS.OUTER)
                  }
                  onMouseOut={(...props) => this.onPieLeave(props, RINGS.OUTER)}
                  dataKey="cost"
                  cx="50%"
                  cy="50%"
                  innerRadius={`${radiuses[RINGS.OUTER][0]}%`}
                  outerRadius={`${radiuses[RINGS.OUTER][1]}%`}
                  stroke="transparent"
                  // label
                  labelLine={false}
                  label={(props) =>
                    this.renderCustomizedLabel(props, RINGS.OUTER)
                  }
                  animationDuration={300}
                />
                <Pie
                  data={this.state.data}
                  activeIndex={this.state.activeIndexMiddle2}
                  activeShape={(props) =>
                    this.renderActiveShape(props, RINGS.MIDDLE2)
                  }
                  onMouseMove={(...props) =>
                    this.onPieEnter(props, RINGS.MIDDLE2)
                  }
                  onMouseOut={(...props) =>
                    this.onPieLeave(props, RINGS.MIDDLE2)
                  }
                  dataKey="time"
                  cx="50%"
                  cy="50%"
                  innerRadius={`${radiuses[RINGS.MIDDLE2][0]}%`}
                  outerRadius={`${radiuses[RINGS.MIDDLE2][1]}%`}
                  stroke="transparent"
                  // label
                  labelLine={false}
                  label={(props) =>
                    this.renderCustomizedLabel(props, RINGS.MIDDLE2)
                  }
                  animationDuration={300}
                >
                  {this.state.data.map((datum, cellIndex) => {
                    return (
                      <Cell
                        key={`cell-${cellIndex}`}
                        fill={datum.fill}
                        opacity={0.8}
                      />
                    );
                  })}
                </Pie>
                <Pie
                  data={this.state.data}
                  activeIndex={this.state.activeIndexMiddle1}
                  activeShape={(props) =>
                    this.renderActiveShape(props, RINGS.MIDDLE1)
                  }
                  onMouseMove={(...props) =>
                    this.onPieEnter(props, RINGS.MIDDLE1)
                  }
                  onMouseOut={(...props) =>
                    this.onPieLeave(props, RINGS.MIDDLE1)
                  }
                  dataKey="reactiveTotal"
                  cx="50%"
                  cy="50%"
                  innerRadius={`${radiuses[RINGS.MIDDLE1][0]}%`}
                  outerRadius={`${radiuses[RINGS.MIDDLE1][1]}%`}
                  stroke="transparent"
                  // label
                  labelLine={false}
                  label={(props) =>
                    this.renderCustomizedLabel(props, RINGS.MIDDLE1)
                  }
                  animationDuration={300}
                >
                  {this.state.data.map((datum, cellIndex) => {
                    return (
                      <Cell
                        key={`cell-${cellIndex}`}
                        fill={datum.fill}
                        opacity={0.6}
                      />
                    );
                  })}
                </Pie>
                <Pie
                  data={this.state.data}
                  activeIndex={this.state.activeIndexInner}
                  activeShape={(props) =>
                    this.renderActiveShape(props, RINGS.INNER)
                  }
                  onMouseMove={(...props) =>
                    this.onPieEnter(props, RINGS.INNER)
                  }
                  onMouseOut={(...props) => this.onPieLeave(props, RINGS.INNER)}
                  dataKey="total"
                  cx="50%"
                  cy="50%"
                  outerRadius={`${radiuses[RINGS.INNER][1]}%`}
                  stroke="transparent"
                  labelLine={false}
                  label={(props) =>
                    this.renderCustomizedLabel(props, RINGS.INNER)
                  }
                  animationDuration={300}
                >
                  {this.state.data.map((datum, cellIndex) => {
                    return (
                      <Cell
                        key={`cell-${cellIndex}`}
                        fill={datum.fill}
                        opacity={0.6}
                      />
                    );
                  })}
                </Pie>
                <Tooltip
                  hide
                  itemStyle={{ textAlign: 'left' }}
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label, [payload]) => {
                    const values = this.getFormattedValues(
                      false,
                      payload,
                      true
                    );
                    return values[payload.dataKey];
                  }}
                  content={<CustomTooltip />}
                  formatter={(value, name, entry) => {
                    const values = this.getFormattedValues(
                      value,
                      entry.payload
                    );
                    return [
                      values.raw[entry.dataKey],
                      translate(name.indexOf('x:') >= 0 ? 'User' : name),
                      getSvg(entry.color),
                    ];
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: 'auto auto 1.5em',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.keys(this.state.labels || []).map(
                    (label, index) => ({
                      value: label,
                      type: 'square',
                      color: chartColors[index % chartColors.length],
                      container: this.container,
                    })
                  )}
                  onClick={(o) => this.props.onClick(o, this.state.labels)}
                  onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, container } = entry;

                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                          opacity: !this.props.isChecked(
                            value,
                            container,
                            this.state.labelValues
                          )
                            ? 0.3
                            : 1,
                        }}
                      >
                        <LocalizedText>
                          {value.indexOf('x:') >= 0 ? 'User' : value}
                        </LocalizedText>
                      </span>
                    );
                  }}
                />
                <use xlinkHref="#activeShape" />
                <use xlinkHref="#activeSector" />
                <use xlinkHref="#activeLabel" />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

UserPie.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

UserPie.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
};
