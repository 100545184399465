import React from 'react';
import PropTypes from 'prop-types';
import Style from './Charging.module.scss';
import {
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Area,
  ReferenceArea,
  ReferenceLine,
} from 'recharts';
import moment from 'moment';
import { get, isEmpty, maxBy, minBy } from 'lodash';
import * as utils from '../../utils';
import { translate } from '../../components/LocalizedText';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

const { chartColors: defColors, colors: styleColors, isMobileWidth } = utils;

const minMax = {
  Voltage: [0.001, 0.01],
  Power: [0, 0.05],
  Consumption: [0, 0],
};

const colors = {
  Voltage: defColors[4],
  Power: defColors[3],
  ReactivePower: defColors[2],
  Consumption: defColors[1],
  ReactiveConsumption: defColors[0],
  Boosted: defColors[7],
};

export default class Chart extends CommonChart {
  state = {
    labels: {
      voltagePercent: true,
      powerPercent: true,
      reactivePowerPercent: true,
      consumptionPercent: true,
      reactiveConsumptionPercent: true,
    },

    clicks: 0,
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: -1,
    refAreaRight: -1,
    animation: true,
  };

  enabledStartingSocInChart = true;

  bottomVoltage = `dataMin - ${minMax.Voltage[0]}`;
  topVoltage = `dataMax + ${minMax.Voltage[1]}`;
  bottomPower = `dataMin - ${minMax.Power[0]}`;
  topPower = `dataMax + ${minMax.Power[1]}`;
  bottomConsumption = `dataMin - ${minMax.Consumption[0]}`;
  topConsumption = `dataMax + ${minMax.Consumption[0]}`;

  componentDidUpdate(prevProps) {
    if (
      this.props.data.length &&
      prevProps.data &&
      prevProps.data.length < this.props.data.length
    ) {
      this.zoomIn(null, true);
    }
  }

  toggleLine(id) {
    if (
      !this.state.labels[id] ||
      (this.state.labels[id] &&
        Object.values(this.state.labels).filter(Boolean).length > 1)
    ) {
      this.setState({
        labels: {
          ...this.state.labels,
          [id]: !this.state.labels[id],
        },
      });
    }
  }

  getAxisYDomain = (from, to, ref, givenPrepared) => {
    let prepared = givenPrepared;

    if (!givenPrepared) {
      const { data: stateData } = this.state;
      const data = !isEmpty(stateData) ? stateData.data : this.props.data;
      // const from = this.props.data.findIndex((datum) => datum.time === givenFrom);
      // const to = this.props.data.findIndex((datum) => datum.time === givenTo);
      const refData = data.slice(from - 1 < 0 ? 0 : from - 1, to);
      prepared = this.prepare(refData);
    }

    return [
      prepared[`min${ref}`] - prepared[`min${ref}`] * minMax[ref][0],
      prepared[`max${ref}`] + prepared[`max${ref}`] * minMax[ref][1],
      prepared,
    ];
  };

  getLabel(name) {
    const labels = {
      voltagePercent: translate('Voltage'),
      powerPercent: translate('Power'),
      reactivePowerPercent: translate('Green power'),
      consumptionPercent: translate('Energy charged'),
      reactiveConsumptionPercent: translate('Green energy used'),
    };

    return labels[name] || name;
  }

  prepare(givenData) {
    const givenBoostPower = this.props.boostPower;
    const data = givenData || this.props.data;
    if (!data || !data.length) {
      return {};
    }

    const startingSoc = this.enabledStartingSocInChart
      ? this.props.startingSOC / 100
      : 0;
    const maxVoltage = parseFloat(
      maxBy(data, (n) => parseFloat(n.voltage, 10)).voltage || 0,
      10
    );
    const minVoltage = parseFloat(
      minBy(data, (n) => parseFloat(n.voltage, 10)).voltage || 0,
      10
    );
    const maxPower = parseFloat(
      maxBy(data, (n) => parseFloat(n.power, 10)).power || 0,
      10
    );
    const minPower = parseFloat(
      minBy(data, (n) => parseFloat(n.power, 10)).power || 0,
      10
    );
    const maxReactivePower = parseFloat(
      maxBy(data, (n) => parseFloat(n.reactivePower, 10)).reactivePower || 0,
      10
    );
    const minReactivePower = parseFloat(
      minBy(data, (n) => parseFloat(n.reactivePower, 10)).reactivePower || 0,
      10
    );
    const maxConsumption = parseFloat(
      maxBy(data, (n) => parseFloat(n.consumption, 10)).consumption || 0,
      10
    );
    const minConsumption = parseFloat(
      minBy(data, (n) => parseFloat(n.consumption, 10)).consumption || 0,
      10
    );
    const maxReactiveConsumption = parseFloat(
      maxBy(data, (n) => parseFloat(n.reactiveConsumption, 10))
        .reactiveConsumption || 0,
      10
    );
    const minReactiveConsumption = parseFloat(
      minBy(data, (n) => parseFloat(n.reactiveConsumption, 10))
        .reactiveConsumption || 0,
      10
    );

    const boostPower =
      this.props.boost && givenBoostPower && givenBoostPower > 0
        ? parseFloat(givenBoostPower, 10)
        : false;

    let maxAllPower = maxPower;
    if (maxAllPower < maxReactivePower) {
      maxAllPower = maxReactivePower;
    }

    let maxAllConsumption = maxConsumption;
    if (maxAllConsumption < maxReactiveConsumption) {
      maxAllConsumption = maxReactiveConsumption;
    }

    if (this.props.maxConsumption > maxAllConsumption) {
      maxAllConsumption = parseFloat(this.props.maxConsumption, 10) * 1.05;
    }

    let minAllConsumption = minConsumption;
    if (minAllConsumption > minReactiveConsumption) {
      minAllConsumption = minReactiveConsumption;
    }

    const mesaures = {
      startingSoc,
      maxVoltage: maxVoltage,
      minVoltage: minVoltage,
      maxPower: maxAllPower,
      minPower: minPower,
      maxReactivePower: maxReactivePower,
      minReactivePower: minReactivePower,
      maxConsumption: maxAllConsumption,
      minConsumption: minAllConsumption,
      maxReactiveConsumption: maxReactiveConsumption,
      minReactiveConsumption: minReactiveConsumption,
      boostPower,
      boostPowerPercent: boostPower ? boostPower / maxAllPower : 0,
      data: data.map((datum, index) => {
        const retDatum = {
          ...datum,
          voltagePercent: maxVoltage ? datum.voltage / maxVoltage : 0,
          powerPercent: maxAllPower ? datum.power / maxAllPower : 0,
          reactivePowerPercent: maxAllPower
            ? datum.reactivePower / maxAllPower
            : 0,
          consumptionPercent: maxAllConsumption
            ? (datum.consumption - minAllConsumption) /
              (maxAllConsumption - minAllConsumption)
            : 0,
          reactiveConsumptionPercent: maxAllConsumption
            ? (datum.reactiveConsumption - minAllConsumption) /
              (maxAllConsumption - minAllConsumption)
            : 0,
          startingConsumptionPercent: 0,
        };

        if (startingSoc > 0) {
          retDatum.startingConsumptionPercent = startingSoc;
        }

        const isBoosted = this.isBoosted(datum, data, index);
        if (!!isBoosted) {
          retDatum.boostedPercent = 1;
          retDatum.prevBoosted = isBoosted === 2;
        }

        return retDatum;
      }),
    };

    return mesaures;
  }

  isBoosted(datum, data = false, index = false) {
    if (datum && utils.isTrue(datum.boosted)) {
      return true;
    }

    if (data && index !== undefined) {
      if (data[index] && utils.isTrue(data[index].boosted)) {
        return true;
      }

      if (
        data[index] &&
        data[index - 1] &&
        utils.isTrue(data[index - 1].boosted)
      ) {
        return 2;
      }

      // if(data[index] && data[index + 1] && utils.isTrue(data[index + 1].boosted)) {
      //   // data[index].nextBoosted = true;
      //   return true;
      // }
    }

    return false;
  }

  zoomIn(e, move = false) {
    if (move && !this.state.zoomed) {
      return;
    }

    let { refAreaLeft, refAreaRight } = this.state;
    const { left, right } = this.state;

    if (move) {
      refAreaLeft = left + 1;
      refAreaRight = right + 1;
    }

    if (refAreaLeft === refAreaRight || refAreaRight === -1) {
      this.setState(() => ({
        refAreaLeft: -1,
        refAreaRight: -1,
      }));
      return;
    }

    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // eslint-disable-next-line no-unused-vars
    const [bottomVoltage, topVoltage, refData] = this.getAxisYDomain(
      refAreaLeft,
      refAreaRight,
      'Voltage'
    );

    this.setState(() => ({
      zoomed: true,
      data: refData,
      refAreaLeft: -1,
      refAreaRight: -1,
      left: refAreaLeft,
      right: refAreaRight,
    }));
  }

  zoomOut() {
    this.setState(() => ({
      zoomed: false,
      data: null,
      refAreaLeft: -1,
      refAreaRight: -1,
    }));
  }

  onDoubleClick() {
    this.zoomOut();
  }

  render() {
    const { green, boost, maxConsumption } = this.props;
    const { data } = this.state;
    const preparedData = !isEmpty(data) ? data : this.prepare();

    if (preparedData.data.length < 1) {
      return null;
    }

    return (
      <div ref={this.chart} className={Style.Chart}>
        <ResponsiveContainer>
          <ComposedChart
            height="30vh"
            data={preparedData.data}
            ref={this.chartComp}
            onMouseDown={(e) => {
              if (e) {
                document.body.classList.add(Style.touchfix);
                this.setState({ refAreaLeft: e.activeTooltipIndex });
              }
            }}
            onMouseMove={(e) =>
              e &&
              this.state.refAreaLeft > -1 &&
              this.setState({ refAreaRight: e.activeTooltipIndex })
            }
            onMouseUp={(e) => {
              this.zoomIn(e);
              document.body.classList.remove(Style.touchfix);
            }}
          >
            <defs>
              <linearGradient id="colorConsumption" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="50%"
                  stopColor={colors.Consumption}
                  stopOpacity={0.5}
                />
                <stop
                  offset="100%"
                  stopColor={colors.Consumption}
                  stopOpacity={0.2}
                />
              </linearGradient>
              <linearGradient
                id="colorReactiveConsumption"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="50%"
                  stopColor={colors.ReactiveConsumption}
                  stopOpacity={0.5}
                />
                <stop
                  offset="100%"
                  stopColor={colors.ReactiveConsumption}
                  stopOpacity={0.2}
                />
              </linearGradient>
              <linearGradient id="fadeGrad" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={styleColors.ownWhite}
                  stopOpacity={0.2}
                />
                <stop
                  offset="50%"
                  stopColor={styleColors.ownWhite}
                  stopOpacity={0.5}
                />
              </linearGradient>

              <mask id="fade" maskContentUnits="objectBoundingBox">
                <rect width="1" height="1" fill="url(#fadeGrad)" />
              </mask>

              <pattern
                id="colorStartingConsumption"
                x="0"
                y="0"
                patternUnits="userSpaceOnUse"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                {utils.getPolygons(colors.Consumption, 1000)}
              </pattern>
            </defs>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke={colors.transparentWhite}
            />
            <XAxis
              domain={[this.state.left, this.state.right]}
              dataKey="time"
              tickFormatter={(value) => moment(value).format('HH:mm')}
            />
            <YAxis
              hide={isMobileWidth()}
              yAxisId="voltage"
              tickFormatter={(value) => {
                return utils.abbrVoltage(value * preparedData.maxVoltage);
              }}
              domain={[this.bottomVoltage, this.topVoltage]}
              orientation="left"
              tick={{ fill: [colors.Voltage] }}
            />
            <YAxis
              width={70}
              hide={isMobileWidth()}
              yAxisId="power"
              tickFormatter={(value) => {
                const power = value * preparedData.maxPower;
                return utils.abbrPower(power > 0 ? power : 0);
              }}
              domain={[this.bottomPower, this.topPower]}
              orientation="right"
              tick={{ fill: [colors.Power] }}
            />
            <YAxis
              hide={isMobileWidth()}
              yAxisId="consumption"
              tickFormatter={(value) => {
                const consumption =
                  value *
                    (preparedData.maxConsumption -
                      preparedData.minConsumption) +
                  parseFloat(preparedData.minConsumption, 10);
                return utils.abbrConsumption(consumption > 0 ? consumption : 0);
              }}
              axisLine={false}
              tickLine={false}
              domain={[this.bottomConsumption, this.topConsumption]}
              orientation="right"
              tick={{ fill: colors.Consumption }}
            />
            <Tooltip
              hide
              labelStyle={{
                textAlign: 'left',
                color: styleColors.backgroundGrey,
              }}
              labelFormatter={(label) => {
                return translate('Time {{time}}', {
                  time: moment(label).format('HH:mm'),
                });
              }}
              content={<CustomTooltip />}
              itemStyle={{ textAlign: 'left' }}
              formatter={(value, name, entry) => {
                if (!this.state.labels[name]) {
                  return [];
                }

                let validName = `${name.substr(0, 1).toUpperCase()}${name
                  .substr(1)
                  .toLowerCase()}`.replace(/percent$/, '');
                const boosted =
                  boost &&
                  validName === 'Power' &&
                  (utils.isTrue(get(entry, 'payload.boosted')) ||
                    utils.isTrue(get(entry, 'payload.prevBoosted')));

                if (validName === 'Reactiveconsumption') {
                  validName = 'Consumption';
                } else if (validName === 'Reactivepower') {
                  validName = 'Power';
                }

                const abbr = utils[`abbr${validName}`];
                let fixVal;
                if (validName === 'Consumption') {
                  fixVal =
                    value *
                      (preparedData[`max${validName}`] -
                        preparedData[`min${validName}`]) +
                    parseFloat(preparedData[`min${validName}`], 10);
                }

                const toolTipValue = abbr(
                  fixVal ? fixVal : value * preparedData[`max${validName}`]
                );

                return [
                  `${toolTipValue}${
                    boosted ? ` (${translate('Boosted')})` : ''
                  }`,
                  `${this.getLabel(name)}`,
                  utils.getSvg(entry.color),
                ];
              }}
            />
            <Legend
              onClick={(o) => this.toggleLine(o.dataKey)}
              payload={[
                {
                  dataKey: 'consumptionPercent',
                  color: colors.Consumption,
                  type: 'line',
                },
                {
                  dataKey: 'reactiveConsumptionPercent',
                  color: colors.ReactiveConsumption,
                  type: 'line',
                },
                {
                  dataKey: 'powerPercent',
                  color: colors.Power,
                  type: 'line',
                },
                {
                  dataKey: 'reactivePowerPercent',
                  color: colors.ReactivePower,
                  type: 'line',
                },
                {
                  dataKey: 'voltagePercent',
                  color: colors.Voltage,
                  type: 'line',
                },
              ]}
              formatter={(value, entry) => {
                const { color, dataKey } = entry;

                if (entry.dataKey === 'boostedPercent') {
                  return false;
                }

                return (
                  <span
                    className={`${Style.legend} legendClick`}
                    style={{
                      color,
                      opacity: this.state.labels[dataKey] ? 1 : 0.3,
                    }}
                  >
                    {this.getLabel(dataKey)}
                  </span>
                );
              }}
            />

            {boost
              ? preparedData.data.map((datum, index) => {
                  if (datum.boosted !== '1' || !preparedData.data[index - 1]) {
                    return null;
                  }

                  return (
                    <ReferenceArea
                      key={`prepDatum-${index}`}
                      yAxisId="voltage"
                      x1={preparedData.data[index - 1].time}
                      x2={datum.time}
                      fill={colors.Boosted}
                      opacity={
                        this.state.labels.reactiveConsumptionPercent ? 0.3 : 0
                      }
                    />
                  );
                })
              : null}
            {/* {boost ? (
              <Area
                dot={false}
                strokeWidth={2}
                yAxisId="consumption"
                // stackId="consumption"
                type="monotone"
                dataKey="boostedPercent"
                stroke="transparent"
                activeDot={false}
                fill={colors.Boosted}
                fillOpacity={
                  this.state.labels.reactiveConsumptionPercent ? 0.3 : 0
                }
              />
            ) : null} */}

            <Area
              dot={false}
              strokeWidth={0}
              yAxisId="consumption"
              stackId="consumption"
              type="monotone"
              dataKey="startingConsumptionPercent"
              stroke="transparent"
              activeDot={false}
              fill="url(#colorStartingConsumption)"
              mask="url(#fade)"
              strokeOpacity={this.state.labels.consumptionPercent ? 1 : 0}
              fillOpacity={this.state.labels.consumptionPercent ? 1 : 0}
            />
            <Area
              dot={false}
              strokeWidth={2}
              yAxisId="consumption"
              stackId="consumption"
              type="monotone"
              dataKey="consumptionPercent"
              stroke={colors.Consumption}
              activeDot={{
                r: 8,
                opacity: this.state.labels.consumptionPercent ? 1 : 0,
              }}
              fill="url(#colorConsumption)"
              strokeOpacity={this.state.labels.consumptionPercent ? 1 : 0}
              fillOpacity={this.state.labels.consumptionPercent ? 1 : 0}
            />
            {green ? (
              <Area
                dot={false}
                strokeWidth={2}
                yAxisId="consumption"
                type="monotone"
                dataKey="reactiveConsumptionPercent"
                stroke={colors.ReactiveConsumption}
                activeDot={{
                  r: 8,
                  opacity: this.state.labels.reactiveConsumptionPercent ? 1 : 0,
                }}
                fill="url(#colorReactiveConsumption)"
                strokeOpacity={
                  this.state.labels.reactiveConsumptionPercent ? 1 : 0
                }
                fillOpacity={
                  this.state.labels.reactiveConsumptionPercent ? 1 : 0
                }
              />
            ) : null}
            <Line
              yAxisId="power"
              dot={false}
              strokeWidth={2}
              type="monotone"
              dataKey="powerPercent"
              stroke={colors.Power}
              activeDot={{
                r: 8,
                opacity: this.state.labels.powerPercent ? 1 : 0,
              }}
              strokeOpacity={this.state.labels.powerPercent ? 1 : 0}
            />
            {green ? (
              <Line
                yAxisId="power"
                dot={false}
                strokeWidth={2}
                type="monotone"
                dataKey="reactivePowerPercent"
                stroke={colors.ReactivePower}
                activeDot={{
                  r: 8,
                  opacity: this.state.labels.reactivePowerPercent ? 1 : 0,
                }}
                strokeOpacity={this.state.labels.reactivePowerPercent ? 1 : 0}
              />
            ) : null}
            <Line
              yAxisId="voltage"
              dot={false}
              strokeWidth={2}
              type="monotone"
              dataKey="voltagePercent"
              stroke={colors.Voltage}
              activeDot={{
                r: 8,
                opacity: this.state.labels.voltagePercent ? 1 : 0,
              }}
              strokeOpacity={this.state.labels.voltagePercent ? 1 : 0}
            />
            {preparedData.boostPowerPercent ? (
              <ReferenceLine
                yAxisId="power"
                y={preparedData.boostPowerPercent}
                label={{
                  fill: styleColors.ownWhite,
                  stroke: styleColors.darkGrey,
                  strokeWidth: 3,
                  paintOrder: 'stroke',
                  value: translate('Green boost over {{value}}', {
                    value: utils.abbrPower(preparedData.boostPower),
                  }),
                }}
                strokeWidth={2}
                strokeDasharray="10 10"
                opacity={0.5}
                stroke={colors.ReactivePower}
              />
            ) : null}
            {this.enabledStartingSocInChart && maxConsumption ? (
              <ReferenceLine
                yAxisId="consumption"
                y={1}
                strokeWidth={2}
                alwaysShow
                stroke="transparent"
              />
            ) : null}

            {this.state.refAreaLeft > -1 && this.state.refAreaRight > -1 ? (
              <ReferenceArea
                yAxisId="voltage"
                x1={preparedData.data[this.state.refAreaLeft].time}
                x2={preparedData.data[this.state.refAreaRight].time}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

Chart.defaultProps = {
  data: [],
};

Chart.propTypes = {
  maxConsumption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startingSOC: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.array,
  green: PropTypes.bool,
  boost: PropTypes.bool,
  boostPower: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
