import React from 'react';
import PropTypes from 'prop-types';
import Style from './Charger.module.scss';
import Icon, { Gradient } from './Icon';
import Map from './Map';
import { Field } from 'redux-form';
import { Select } from '../containers/form/Form';
import LocalizedText, { translate } from './LocalizedText';
import { abbrConsumption, abbrPower, colors, isFalse, isTrue } from '../utils';
import LabeledItem from './LabeledItem';
import { isArray, isEmpty, isFunction } from 'lodash';
import { ConnectorTypes } from '../redux/app/app.utils';

const weathers = [
  {
    index: 0,
    text: 'Now',
    icon: 'dayZeroIcon',
    temperature: 'dayZeroTemperature',
  },
  {
    index: 1,
    text: 'Later',
    icon: 'dayOneIcon',
    temperature: 'dayOneTemperature',
  },
  {
    index: 2,
    text: 'Tomorrow',
    icon: 'dayTwoIcon',
    temperature: 'dayTwoTemperature',
  },
];
class Charger extends React.Component {
  weatherRef = React.createRef();
  chargerDetails = React.createRef();

  state = {
    videoLoaded: false,
    weather: weathers[0],
    connectors: [],
  };

  activeIcon = 0;

  componentDidMount() {
    // if(get(this.props, 'data.connectors')) {
    //   this.setState({
    //     connectors: this.props.adminConnectors,
    //   });
    // }
    this.changeDay(10000);
    // this.changeIcon(3000);
  }

  shouldComponentUpdate() {
    return !window.ignore;
  }

  changeIcon(delay = 1000, index = 0) {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.chargerDetails.current) {
          const { current: ref } = this.chargerDetails;

          const icons = Array.from(
            ref.querySelectorAll(
              `.${Style.detail}:not(.${Style.groupContainer}):not(.${Style.shown})`
            )
          )
            .filter((child) => child.parentElement === ref)
            .map((child, childIndex) => {
              child.classList.remove(Style.active);

              if (index === childIndex) {
                child.classList.add(Style.active);
              }

              return child;
            });

          let newIndex = index + 1;
          if (newIndex > icons.length) {
            newIndex = 0;
          }

          if (icons.length) {
            resolve(newIndex);
          } else {
            reject();
          }
        } else {
          resolve(index);
        }
      }, delay);
    }).then((newIndex) => {
      this.changeIcon(delay, newIndex);
    });
  }

  changeDay(delay = 5000) {
    new Promise((resolve) => {
      setTimeout(() => {
        if (this.weatherRef.current) {
          const { current: ref } = this.weatherRef;

          if (ref) {
            ref.classList.add(Style.weatherPrevContainer);
          }

          resolve();
        }
      }, delay);
    })
      .then(() => {
        return new Promise((resolve) => {
          setTimeout(() => {
            const { current: ref } = this.weatherRef;

            if (ref) {
              ref.classList.remove(Style.weatherPrevContainer);
              ref.classList.add(Style.weatherNextContainer);
            }

            resolve();
          }, 550);
        });
      })
      .then(() => {
        const { current: ref } = this.weatherRef;

        let newIndex = this.state.weather.index + 1;
        if (newIndex > 2) {
          newIndex = 0;
        }

        this.setState({
          weather: {
            ...weathers[newIndex],
          },
        });

        setTimeout(() => {
          if (ref) {
            ref.classList.remove(Style.weatherNextContainer);
          }
          this.changeDay(newIndex === 0 ? 10000 : 5000);
        }, 550);
      });
  }

  showDetails(e) {
    if (!this.props.isAdmin) {
      const details = e.target.closest(`.${Style.details}`);
      const more = details.querySelector(`.${Style.more}`);

      if (more) {
        this.shownMore = true;
        more.classList.add(Style.hidden);
        const children = Array.from(more.parentElement.children);
        children.forEach((child) => {
          child.classList.add(Style.shown);
          child.classList.remove(Style.active);
        });

        setTimeout(() => window.dispatchEvent(new Event('resize')), 120);
      }
    }
  }

  isActiveClass(value, key = null, defaultClasses = null) {
    let checker = (val) => !isFalse(val);

    if (isFunction(value)) {
      checker = value;
    } else if (isFunction(key)) {
      checker = key;
    }

    let activeClass = '';
    switch (key) {
      case 'cost': {
        activeClass =
          value && value.length === 1 && value[0] === translate('Free')
            ? Style.active
            : '';
        break;
      }
      default: {
        activeClass = checker(value) ? Style.active : '';
        break;
      }
    }

    if (isArray(defaultClasses)) {
      activeClass = defaultClasses.map(
        (defaultClass) => `${defaultClass || ''} ${activeClass}`
      );
    } else {
      activeClass = `${defaultClasses || ''} ${activeClass}`;
    }

    return activeClass;
  }

  render() {
    const {
      consumption,
      reactiveConsumption,
      limit,
      data,
      total,
      location,
      isAdmin,
      // adminConnectors,
    } = this.props;

    const adminConnectors = isAdmin && [...(data.connectors || [])];

    const shownMore =
      !!this.shownMore || this.props.isAdmin
        ? `${Style.shown} ${this.props.isAdmin ? Style.shownAlways : ''}`
        : '';

    const cost = [];

    if (isFalse(data.costPerMinute) && isFalse(data.costPerConsumption)) {
      cost.push(translate('Free'));
    } else {
      if (!!data.costPerMinute && data.costPerMinute !== '0') {
        cost.push(
          translate('{{cost}} {{unit}}/min', {
            cost: data.costPerMinute,
            unit: data.costUnit,
          })
        );
      }
      if (!!data.costPerConsumption && data.costPerConsumption !== '0') {
        cost.push(
          translate('{{cost}} {{unit}}/kWh', {
            cost: data.costPerConsumption,
            unit: data.costUnit,
          })
        );
      }
    }

    const hasWeather =
      !isEmpty(data.weatherData) &&
      data.weatherData.dayZeroIcon &&
      data.weatherData.dayOneIcon &&
      data.weatherData.dayTwoIcon;
    const greenPower = (data.solarMeter || {}).power || 0;

    return (
      <>
        <div
          className={`${Style.details} ${Style.detailsMapAndVideo} ${
            isAdmin ? Style.isAdmin : ''
          }`}
        >
          {/* {data.videos && data.videos.length ? (
            <VideoPlayer className={`${Style.video} ${this.state.videoLoaded ? Style.videoLoaded : ''}`} 
              onLoaded={(e) => {
                this.setState({ videoLoaded: canPlayVideo(e.target).mpeg4 });
              }}
              width={500} 
              muted 
              autoPlay 
              playsInline 
              controls 
              loop 
              sources={data.videos} />
          ) : null} */}
          <Map
            className={Style.maps}
            {...location}
            interactive
            navigateTo
            isAdmin={isAdmin}
          />
        </div>
        <div
          ref={this.chargerDetails}
          className={`${Style.details} ${Style.chargerDetails} ${
            isAdmin ? Style.isAdmin : ''
          }`}
        >
          <div className={`${Style.detail} ${Style.groupContainer}`}>
            <LabeledItem
              className={`${Style.detail} ${
                hasWeather ? Style.detailHalf : ''
              } ${Style.status}`}
              icon={
                data.status === 'Connecting'
                  ? ['BiWifi0', 'BiWifi1', 'BiWifi2', 'BiWifi']
                  : data.status === 'Online'
                  ? 'BiWifi'
                  : 'BiWifiOff'
              }
              label={data.status || 'Offline'}
            ></LabeledItem>
            {hasWeather ? (
              <LabeledItem
                forwardRef={this.weatherRef}
                className={`${Style.detail} ${Style.detailHalf} ${Style.weatherContainer} ${this.state.weather.statusClass}`}
                icon={`Weather${data.weatherData[this.state.weather.icon]}`}
                label="{{unit}} {{value}}"
                variables={{
                  unit: 'C°',
                  value: data.weatherData[this.state.weather.temperature],
                }}
              >
                <LocalizedText tag="span">
                  {this.state.weather.text}
                </LocalizedText>
              </LabeledItem>
            ) : null}
          </div>
          {!isEmpty(data.connectors) ? (
            <div
              className={`${Style.detail} ${Style.groupContainer} ${Style.iconsContainer}`}
            >
              {data.connectors.map((connector) => (
                <LabeledItem
                  key={`connector-${connector.id}`}
                  className={Style.label}
                  icon={`Charger/${connector.type.replace(
                    /[^a-zA-Z0-9]/g,
                    ''
                  )}`}
                  iconClassName={Style.icon}
                  label={connector.type}
                  noTranslate
                >
                  <Icon
                    className={`${Style.icon} ${Style.supIcon}`}
                    icon={`Charger/${connector.current}`}
                  />
                  {abbrPower(connector.power)}
                </LabeledItem>
              ))}
            </div>
          ) : null}
          {adminConnectors
            ? adminConnectors.map((connector, index) => {
                return (
                  <LabeledItem
                    key={`connect-${connector.id}`}
                    className={`${Style.detail} ${Style.shown}`}
                    iconProps={{
                      useInRow: true,
                      iconClassName: [null, Style.supIcon],
                    }}
                    icons={
                      connector && connector.current && connector.type
                        ? [
                            `Charger/${connector.type.replace(
                              /[^a-zA-Z0-9]/g,
                              ''
                            )}`,
                            `Charger/${connector.current}`,
                          ]
                        : []
                    }
                    label={
                      connector
                        ? connector.current &&
                          connector.type &&
                          `${connector.current} - ${connector.type}`
                        : 'New connector'
                    }
                    noTranslate={!!connector}
                  >
                    <Field
                      name={`connectors[${index}].power`}
                      component={'input'}
                      type="number"
                      className={Style.arrayInput}
                    />
                    <Field
                      name={`connectors[${index}].type`}
                      component={Select}
                      options={
                        !!connector
                          ? { '!': translate('Remove'), ...ConnectorTypes }
                          : ConnectorTypes
                      }
                    />
                    <Field
                      name={`connectors[${index}].startable`}
                      component={Select}
                      options={['Dumb', 'Smart']}
                    />
                  </LabeledItem>
                );
              })
            : null}
          <LabeledItem
            className={`${Style.detail} ${Style.shown}`}
            icon="FcDepartment"
            label="Address"
          >
            <LabeledItem.Admin>
              <Field name="address" component={'input'} type="text" />
            </LabeledItem.Admin>
            <LabeledItem.User children={data.address} />
          </LabeledItem>
          {isAdmin ? (
            <>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="FcManager"
                label="Authentication"
              >
                <Field
                  name="authRequired"
                  component={Select}
                  labelPlacement="start"
                  options={['No', 'Yes']}
                />
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="FcClock"
                label="Scheduled start"
              >
                <Field
                  name="schedule"
                  component={Select}
                  labelPlacement="start"
                  options={['No', 'Yes']}
                />
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="GiPowerButton"
                label="Anyone can stop with button"
              >
                <Field
                  name="anonymusStop"
                  component={Select}
                  labelPlacement="stop"
                  options={['No', 'Yes']}
                />
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="FcCurrencyExchange"
                label="Cost per minute"
              >
                <Field name="costPerMinute" component={'input'} type="number" />
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="FcCurrencyExchange"
                label="Cost per kWh"
              >
                <Field
                  name="costPerConsumption"
                  component={'input'}
                  type="number"
                />
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${Style.shown}`}
                icon="FcCurrencyExchange"
                label="Cost unit"
              >
                <Field name="costUnit" component={'input'} type="text" />
              </LabeledItem>
            </>
          ) : (
            <LabeledItem
              className={`${Style.detail} ${Style.shown}`}
              icon="FcCurrencyExchange"
              iconClassName={this.isActiveClass(cost, 'cost')}
              label="Cost"
            >
              {cost.join(' + ')}
            </LabeledItem>
          )}
          <LabeledItem
            className={`${Style.detail} ${shownMore}`}
            onClick={(e) => this.showDetails(e)}
            icon="FcFlashAuto"
            iconClassName={this.isActiveClass(data.autoStart)}
            label="Auto start"
          >
            <LabeledItem.Admin>
              <Field
                name="autoStart"
                component={Select}
                labelPlacement="start"
                options={['No', 'Yes', 'Hardware']}
              />
            </LabeledItem.Admin>
            <LabeledItem.User>
              <LocalizedText tag="span">
                {data.autoStart === '1'
                  ? 'Yes'
                  : data.autoStart === '2'
                  ? 'Hardware'
                  : 'No'}
              </LocalizedText>
            </LabeledItem.User>
          </LabeledItem>
          <LabeledItem
            className={`${Style.detail} ${shownMore}`}
            onClick={(e) => this.showDetails(e)}
            icon="FaLeaf"
            label="Green energy"
            iconClassName={`${Style.green} ${this.isActiveClass(data.solar)}`}
          >
            <LabeledItem.Admin>
              <Field
                name="solar"
                component={Select}
                labelPlacement="start"
                options={['No', 'Yes']}
              />
            </LabeledItem.Admin>
            <LabeledItem.User>
              <LocalizedText tag="span">
                {isTrue(data.solar) ? 'Yes' : 'No'}
              </LocalizedText>
            </LabeledItem.User>
          </LabeledItem>
          {isTrue(data.solar) ? (
            <>
              <LabeledItem
                className={`${Style.detail} ${shownMore}`}
                onClick={(e) => this.showDetails(e)}
                icons={['FcFlashOn', 'FaLeaf']}
                label="Actual green power"
                iconProps={{
                  useSups: true,
                  iconClassName: this.isActiveClass(greenPower, null, [
                    null,
                    Style.green,
                  ]),
                }}
              >
                {abbrPower(greenPower)}
              </LabeledItem>
              <LabeledItem
                className={`${Style.detail} ${shownMore}`}
                onClick={(e) => this.showDetails(e)}
                icons={['GiUpgrade', 'FaLeaf']}
                label="Green boost"
                iconProps={{
                  useSups: true,
                  iconClassName: this.isActiveClass(
                    greenPower > parseFloat(data.solarBoost, 10),
                    null,
                    [Style.yellowRed, Style.green]
                  ),
                  children: (
                    <Gradient
                      colors={{ '0%': colors.red, '100%': colors.orange }}
                    />
                  ),
                }}
              >
                <LabeledItem.Admin>
                  <Field name="solarBoost" component={'input'} type="number" />
                </LabeledItem.Admin>
                <LabeledItem.User>
                  {data.solarBoost <= 0 ? (
                    <LocalizedText tag="span">No</LocalizedText>
                  ) : (
                    <LocalizedText
                      tag="span"
                      className={
                        greenPower > parseInt(data.solarBoost, 10)
                          ? Style.boosted
                          : ''
                      }
                      variables={{ value: abbrPower(data.solarBoost) }}
                      children="Over {{value}}"
                    />
                  )}
                </LabeledItem.User>
              </LabeledItem>
            </>
          ) : null}
          <LabeledItem
            className={`${Style.detail} ${shownMore}`}
            onClick={(e) => this.showDetails(e)}
            icon="FcHighBattery"
            iconClassName={this.isActiveClass(limit <= 0)}
            label="Energy limit"
          >
            <LabeledItem.Admin>
              <Field
                name="consumptionLimit"
                component={'input'}
                type="number"
              />
            </LabeledItem.Admin>
            <LabeledItem.User>
              <LocalizedText tag="span">
                {limit <= 0 ? 'Unlimited' : abbrConsumption(limit)}
              </LocalizedText>
            </LabeledItem.User>
          </LabeledItem>
          <LabeledItem
            className={`${Style.detail} ${shownMore}`}
            onClick={(e) => this.showDetails(e)}
            icon="FcChargeBattery"
            iconClassName={this.isActiveClass(consumption)}
            label="Total energy charged"
          >
            {abbrConsumption(consumption || 0)}
          </LabeledItem>
          {isTrue(data.solar) ? (
            <LabeledItem
              className={`${Style.detail} ${shownMore}`}
              onClick={(e) => this.showDetails(e)}
              icons={['FcChargeBattery', 'FaLeaf']}
              label="Total green energy used"
              iconProps={{
                useSups: true,
                // iconClassName: Style.green,
                iconClassName: this.isActiveClass(reactiveConsumption, null, [
                  null,
                  Style.green,
                ]),
              }}
            >
              {abbrConsumption(reactiveConsumption || 0)}
            </LabeledItem>
          ) : null}
          <LabeledItem
            className={`${Style.detail} ${shownMore}`}
            onClick={(e) => this.showDetails(e)}
            icon="FcList"
            iconClassName={this.isActiveClass(total)}
            label="Total charging sessions"
          >
            {total ? total : 0}
          </LabeledItem>
          {!isAdmin ? (
            <>
              <div
                // eslint-disable-next-line max-len
                className={`${Style.detail} ${Style.groupContainer} ${
                  Style.iconsContainer
                } ${Style.more} ${Style.shown} ${
                  shownMore ? Style.hidden : ''
                }`}
                onClick={(e) => this.showDetails(e)}
              >
                <LabeledItem
                  className={Style.label}
                  iconClassName={Style.icon}
                  icon="CgDetailsMore"
                  label="More details"
                />
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }
}

Charger.propTypes = {
  consumption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reactiveConsumption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.object,
  total: PropTypes.number,
  location: PropTypes.object,
  isAdmin: PropTypes.bool,
  // adminConnectors: PropTypes.array,
};

export default Charger;
