import { call, all, put, takeLatest, select, delay } from 'redux-saga/effects';

import AppActionTypes from './app.types';
import * as AppActions from './app.actions';
import {
  fetchAPI,
  getCallbackName,
  IS_ADMIN,
  IS_DEBUG,
  postAPI,
} from './app.utils';
import {
  selectChargerId,
  selectClientId,
  // selectHistory,
  selectIsLoadingAny,
  selectIsLoadingHiddenAny,
} from './app.selectors';
import { v4 as uuidv4 } from 'uuid';
import Error from '../../components/Error';
import { selectIsUserLoggedIn, selectUserToken } from '../user/user.selectors';
import { isEqual, random } from 'lodash';
import { getAuthBody, getAuthHeader } from '../user/user.utils';
import { selectForm } from '../form/form.selectors';
// import UserActionTypes from '../user/user.types';

function* fetch({ type, payload, requests = 0 }, callbacks = {}) {
  const {
    endpoint,
    options: { headers = {}, params = {} },
  } = payload;
  try {
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActions.setUUID(clientId));
    }

    // if(IS_ADMIN) {
    const isUserLoggedIn = yield select(selectIsUserLoggedIn);
    const token = yield select(selectUserToken);

    if (isUserLoggedIn && token) {
      headers.Authorization = getAuthHeader(token, true);
    }
    // }

    const passingParams = {
      run: true,
      ts: IS_ADMIN ? Date.now() : `${Date.now()}${random(1, 9)}`,
      clientId,
      requests,
      ...params,
    };

    if (IS_DEBUG) {
      passingParams.debug = 'on';
    }

    if (isUserLoggedIn && token) {
      passingParams.Authorization = getAuthBody(token, true);
    }

    const response = yield call(fetchAPI, endpoint, {
      headers,
      params: passingParams,
    });

    if (response.status !== 200) {
      throw new Error(
        `${response.status} ${response.statusText}. Please try again.`
      );
    }

    callbacks.success && (yield put(callbacks.success({ endpoint, response })));

    if (endpoint === 'request') {
      yield put(
        AppActions.fetchStatusStart(
          {
            params: passingParams,
          },
          true
        )
      );
    } else if (endpoint === 'stop') {
      response.charging &&
        (yield put(AppActions.addHistoryItem(response.charging)));
    } else if (endpoint === 'status') {
      // const history = yield select(selectHistory);
      // if(!history || !history.length) {
      //   yield put(AppActions.fetchHistoryStart({
      //     params: passingParams,
      //   }));
      // }
    }
  } catch (error) {
    callbacks.failure &&
      (yield put(
        callbacks.failure({
          endpoint,
          error: typeof error === 'string' ? error : error.message,
        })
      ));
  }
}

// function* onFetchHistory() {
//   const chargerId = yield select(selectChargerId);

//   yield put(AppActions.resetHistory());

//   yield put(AppActions.fetchHistoryStart({
//     params: {
//       chargerId,
//     },
//   }, true));
// }

const requests = {};
function* onFetch(action) {
  const callbackName = getCallbackName(
    action.type,
    action.type === AppActionTypes.CHARGING_START ? 'Start' : ''
  );
  const callbacks = {
    success: AppActions[`${callbackName}Success`],
    failure: AppActions[`${callbackName}Failure`],
  };

  if (callbacks) {
    if (!requests[action.type]) {
      requests[action.type] = 0;
    }

    yield call(
      fetch,
      {
        ...action,
        requests: requests[action.type]++,
      },
      callbacks
    );
  } else {
    throw new Error('Invalid action');
  }
}

function* post({ type, payload, requests = 0 }, callbacks = {}) {
  const {
    endpoint,
    options: { body = {}, headers = {}, params = {} },
  } = payload;
  try {
    const isUserLoggedIn = yield select(selectIsUserLoggedIn);
    const token = yield select(selectUserToken);
    const chargerId = yield select(selectChargerId);
    let clientId = yield select(selectClientId);

    if (!clientId) {
      clientId = uuidv4();
      yield put(AppActions.setUUID(clientId));
    }

    if (isUserLoggedIn && token) {
      const passingParams = {
        run: true,
        clientId,
        chargerId,
        ...params,
        ...getAuthBody(token),
      };

      if (IS_DEBUG) {
        passingParams.debug = 'on';
      }

      const response = yield call(
        postAPI,
        endpoint,
        {
          headers: {
            ...headers,
            ...getAuthHeader(token),
          },
          params: passingParams,
        },
        { ...body }
      );
      callbacks.success &&
        (yield put(callbacks.success({ endpoint, response })));
    } else {
      throw new Error('Unaauthorized');
    }
  } catch (error) {
    callbacks.failure &&
      (yield put(callbacks.failure({ endpoint, error: error.message })));
  }
}

const posts = {};
function* onPost(action) {
  const callbackName = getCallbackName(action.type);
  const callbacks = {
    success: AppActions[`${callbackName}Success`],
    failure: AppActions[`${callbackName}Failure`],
  };

  if (callbacks) {
    if (!posts[action.type]) {
      posts[action.type] = 0;
    }

    yield call(
      post,
      {
        ...action,
        requests: posts[action.type]++,
      },
      callbacks
    );
  } else {
    throw new Error('Invalid action');
  }
}

export function* check() {
  const updateForm = yield select(selectForm, { id: 'updateCharger' }) || {};
  const isLoading = yield select(selectIsLoadingAny);
  const isLoadingHidden = yield select(selectIsLoadingHiddenAny);

  let timeout = 10000;
  if (
    isLoading ||
    isLoadingHidden ||
    !document.hasFocus() ||
    (updateForm.initial && !isEqual(updateForm.values, updateForm.initial))
  ) {
    timeout = 2000;
  } else {
    const chargerId = yield select(selectChargerId);

    yield put(
      AppActions.fetchStatusStart(
        {
          params: {
            chargerId,
          },
        },
        true
      )
    );
  }

  yield delay(timeout);
  yield call(check);
}

function* onPostInit() {
  yield takeLatest(
    [AppActionTypes.REMOVE_HISTORY_START, AppActionTypes.UPDATE_START],
    onPost
  );
}

function* onFetchInit() {
  yield takeLatest(
    [
      AppActionTypes.FETCH_STATUS_START,
      AppActionTypes.FETCH_HISTORY_START,
      AppActionTypes.FETCH_STAT_START,
      AppActionTypes.FETCH_USERS_START,
      AppActionTypes.FETCH_CHARGERS_START,
      AppActionTypes.UPDATE_CAR_OF_CHARGING_START,
      AppActionTypes.CHARGING_START,
      AppActionTypes.CHARGING_STOP,
    ],
    onFetch
  );
}

// function* onHistoryCheck() {
//   yield takeLatest([UserActionTypes.LOGIN_SUCCESS, UserActionTypes.LOGOUT_FAILURE], onFetchHistory);
// }

function* onIntervalStatusInit() {
  yield delay(2000);
  yield call(check);
}

export function* appSagas() {
  yield all([
    call(onFetchInit),
    call(onPostInit),
    call(onIntervalStatusInit),
    // call(onHistoryCheck),
  ]);
}
