import React from 'react';
import PropTypes from 'prop-types';
import Style from './Shared.module.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Loader from './Loader';
import {
  selectClearCharger,
  selectCharging,
  selectSolarBoost,
  selectSolar,
  selectIsSharedLoading,
} from '../redux/app/app.selectors';
import { fetchSharedStatusStart } from '../redux/app/app.actions';
import { isEmptyOrNull } from '../utils';
import LocalizedText, { translate } from './LocalizedText';
import Charging from './Charging';

class Shared extends React.Component {
  componentDidMount() {
    this.chargerId = this.props.match.params.chargerId;
    this.chargerHash = this.props.match.params.chargingHash;

    this.props.fetch({
      params: {
        chargerId: this.chargerId,
        chargerHash: this.chargerHash,
      },
    });
  }

  render() {
    const { isAppLoading, charging } = this.props;

    return (
      <div className={`Main ${Style.Main} ${Style.Shared}`}>
        {isAppLoading ? (
          <Loader label={translate('Loading')} />
        ) : isEmptyOrNull(charging) ? (
          <div className={Style.error}>
            <LocalizedText tag="h2">404 Not found</LocalizedText>
            <LocalizedText tag="div">
              The charging session is not found
            </LocalizedText>
          </div>
        ) : (
          <Charging {...this.props} isShared />
        )}
      </div>
    );
  }
}

Shared.propTypes = {
  charging: PropTypes.object,
  solar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  solarBoost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  match: PropTypes.object,
  isAppLoading: PropTypes.bool,
  fetch: PropTypes.func,
  charger: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isAppLoading: selectIsSharedLoading,
  charger: selectClearCharger,
  charging: selectCharging,
  solar: selectSolar,
  solarBoost: selectSolarBoost,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options = {}, hidden = false) =>
    dispatch(fetchSharedStatusStart(options, hidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shared);
