import FormActionTypes from './form.types';

export const startSubmission = (data) => ({
  type: FormActionTypes.SUBMIT_START,
  payload: data,
});

export const submissionSuccess = () => ({
  type: FormActionTypes.SUBMIT_SUCCESS,
});

export const submissionFailure = (errorMessage) => ({
  type: FormActionTypes.SUBMIT_FAILURE,
  payload: errorMessage,
});

export const setFormError = (payload) => ({
  type: FormActionTypes.SET_FORM_ERROR,
  payload,
});
