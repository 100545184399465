import UserActionTypes from './user.types';

export const logout = () => ({
  type: UserActionTypes.LOGOUT_START,
});

export const logoutSuccess = () => ({
  type: UserActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = (errorMessage) => ({
  type: UserActionTypes.LOGOUT_FAILURE,
  payload: errorMessage,
});

export const login = (payload) => ({
  type: UserActionTypes.LOGIN_START,
  payload,
});

export const loginSuccess = (payload) => ({
  type: UserActionTypes.LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (errorMessage) => ({
  type: UserActionTypes.LOGIN_FAILURE,
  payload: errorMessage,
});

export const updateStart = (payload) => ({
  type: UserActionTypes.UPDATE_START,
  payload,
});

export const updateSuccess = (payload) => ({
  type: UserActionTypes.UPDATE_SUCCESS,
  payload,
});

export const updateFailure = (payload) => ({
  type: UserActionTypes.UPDATE_FAILURE,
  payload,
});

export const removeStart = (payload) => ({
  type: UserActionTypes.REMOVE_START,
  payload,
});

export const removeSuccess = (payload) => ({
  type: UserActionTypes.REMOVE_SUCCESS,
  payload,
});

export const removeFailure = (payload) => ({
  type: UserActionTypes.REMOVE_FAILURE,
  payload,
});

export const setEdit = (payload) => ({
  type: UserActionTypes.SET_EDIT,
  payload,
});

export const setDefaultCar = (payload) => ({
  type: UserActionTypes.SET_DEFAULT_CAR,
  payload: {
    body: {
      defaultCarId: payload,
    },
  },
});

export const setDefaultLang = (payload) => ({
  type: UserActionTypes.SET_DEFAULT_LANG,
  lang: payload,
  payload: {
    body: {
      defaultLang: payload,
    },
  },
});
