import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import Style from './Dialog.module.scss';
import Icon from './Icon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  onClose,
  onOk,
  okText,
  title,
  children,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        classes: {
          root: Style.background,
        },
      }}
    >
      <AppBar className={Style.dialog}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Icon icon="IoCloseOutline" />
          </IconButton>
          <Typography variant="h6" className={Style.title}>
            {title}
          </Typography>
          {okText !== false ? (
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                onOk();
                onClose();
              }}
            >
              {okText}
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

FullScreenDialog.defaultProps = {
  onClose: () => {},
  onOk: () => {},
};

FullScreenDialog.propTypes = {
  okText: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.object,
};
