/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/**
 * @fileOverview Default Tooltip Content
 */
import _ from 'lodash';
import React, { PureComponent } from 'react';
import classNames from 'classnames';

export const isNumOrStr = (value) =>
  (_.isNumber(value) && !_.isNaN(value)) || _.isString(value);

function defaultFormatter(value) {
  return _.isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1])
    ? value.join(' ~ ')
    : value;
}
export class DefaultTooltipContent extends PureComponent {
  renderContent() {
    const { payload, separator, formatter, itemStyle, itemSorter } = this.props;
    if (payload && payload.length) {
      const listStyle = { padding: 0, margin: 0 };
      const items = (itemSorter ? _.sortBy(payload, itemSorter) : payload).map(
        (entry, i) => {
          if (entry.type === 'none') {
            return null;
          }

          const finalItemStyle = Object.assign(
            {
              display: 'block',
              paddingTop: 4,
              paddingBottom: 4,
              color: entry.color || '#000',
            },
            itemStyle
          );
          const finalFormatter =
            entry.formatter || formatter || defaultFormatter;
          let { name, value, icon } = entry;
          if (finalFormatter) {
            const formatted = finalFormatter(value, name, entry, i, payload);
            if (Array.isArray(formatted)) {
              [value, name, icon] = formatted;
            } else {
              value = formatted;
            }
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement(
              'li',
              {
                className: 'recharts-tooltip-item',
                key: `tooltip-item-${i}`,
                style: finalItemStyle,
              },
              icon
                ? React.createElement(
                    'span',
                    { className: 'recharts-tooltip-item-icon' },
                    icon
                  )
                : null,
              React.createElement(
                'span',
                { className: 'recharts-tooltip-item-name' },
                name
              ),
              isNumOrStr(name)
                ? React.createElement(
                    'span',
                    { className: 'recharts-tooltip-item-separator' },
                    separator
                  )
                : null,
              React.createElement(
                'span',
                { className: 'recharts-tooltip-item-value' },
                value
              ),
              React.createElement(
                'span',
                { className: 'recharts-tooltip-item-unit' },
                entry.unit || ''
              )
            )
          );
        }
      );
      return React.createElement(
        'ul',
        { className: 'recharts-tooltip-item-list', style: listStyle },
        items
      );
    }
    return null;
  }
  render() {
    const {
      wrapperClassName,
      contentStyle,
      labelClassName,
      labelStyle,
      label,
      labelFormatter,
      payload,
    } = this.props;
    const finalStyle = Object.assign(
      {
        margin: 0,
        padding: 10,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        whiteSpace: 'nowrap',
      },
      contentStyle
    );
    const finalLabelStyle = Object.assign({ margin: 0 }, labelStyle);
    const hasLabel = !_.isNil(label);
    let finalLabel = hasLabel ? label : '';
    const wrapperCN = classNames('recharts-default-tooltip', wrapperClassName);
    const labelCN = classNames('recharts-tooltip-label', labelClassName);
    if (labelFormatter) {
      finalLabel = labelFormatter(label, payload);
    }
    return React.createElement(
      'div',
      { className: wrapperCN, style: finalStyle },
      React.createElement(
        'p',
        { className: labelCN, style: finalLabelStyle },
        React.isValidElement(finalLabel) ? finalLabel : `${finalLabel}`
      ),
      this.renderContent()
    );
  }
}
DefaultTooltipContent.displayName = 'DefaultTooltipContent';
DefaultTooltipContent.defaultProps = {
  separator: ' : ',
  contentStyle: {},
  itemStyle: {},
  labelStyle: {},
};
