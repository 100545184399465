import React from 'react';
import PropTypes from 'prop-types';
import Style from './History.module.scss';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectIsHistoryLoading,
  selectHistoryNextPage,
  selectHistoryFilter,
} from '../redux/app/app.selectors';
import Charging from './Charging';
import { fetchHistoryStart, resetHistory } from '../redux/app/app.actions';
import { getScrollTop } from '../utils';
import Loader from './Loader';
import { get, isEqual } from 'lodash';
import LocalizedText from './LocalizedText';

class History extends React.Component {
  list = React.createRef();
  state = {
    opened: false,
    activeAddon: -1,
    styles: {},
  };

  componentDidMount() {
    this.chargerId = this.props.match.params.chargerId;

    this.props.resetHistory();
    this.props.fetch({
      params: {
        chargerId: this.chargerId,
      },
    });

    this.setFilter();
    window.addEventListener('scroll', this.checkLast);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.setFilter();
    }
  }

  // shouldComponentUpdate(prevProps) {
  //   const checkPrev = {
  //     ...prevProps,
  //     children: null,
  //   };
  //   const checkCurrent = {
  //     ...this.props,
  //     children: null,
  //   };

  //   return !isEqual(checkPrev, checkCurrent);
  // }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkLast);
  }

  setFilter() {
    if (get(this.props, 'filter.data')) {
      const styles = this.props.filter.data.reduce((accu, group) => {
        const ids = group.payload.reduce((style, charging) => {
          return {
            ...style,
            [charging.id]: {
              color: group.highLightColor || group.fill,
              hidden: group.hidden,
            },
          };
        }, {});
        return {
          ...accu,
          ...ids,
        };
      }, {});

      return this.setState({ styles });
    }

    this.setState({ styles: {} });
  }

  checkLast = () => {
    if (this.list.current) {
      const last = this.list.current.querySelector(
        `.${Style.charging}:last-child`
      );

      if (last) {
        const offsetBottom = last.offsetHeight + last.offsetTop;
        if (
          !this.props.isLoading &&
          window.innerHeight + getScrollTop() > offsetBottom
        ) {
          if (this.props.nextPage) {
            this.props.fetch({
              params: {
                chargerId: this.chargerId,
                offset: this.props.nextPage,
              },
            });
          }
        }
      }
    }
  };

  toggleChart(e, id) {
    e.preventDefault();

    if (!e.target || !e.target.classList.contains('legendClick')) {
      let opened = false;
      if (this.state.opened !== id) {
        opened = id;
      }

      this.setState({ opened });
    }
  }

  render() {
    const {
      data: histories,
      children,
      isAdmin,
      solar,
      solarBoost,
    } = this.props;
    const { styles } = this.state;

    return (
      <div className={Style.History}>
        <div className={Style.addonTabs}>
          <LocalizedText
            tag="h2"
            onClick={() =>
              this.state.activeAddon !== -1 &&
              this.setState({ activeAddon: -1 })
            }
            className={this.state.activeAddon === -1 ? Style.active : null}
          >
            History
          </LocalizedText>
          {children
            ? React.Children.map(children, (child, index) => (
                <LocalizedText
                  tag="h2"
                  variables={{ index: index + 1 }}
                  onClick={() =>
                    this.state.activeAddon !== index &&
                    this.setState({ activeAddon: index })
                  }
                  className={
                    this.state.activeAddon === index ? Style.active : null
                  }
                >
                  {child.props.title || 'Tab {{index}}'}
                </LocalizedText>
              ))
            : null}
        </div>
        <div className={Style.addons}>
          {children
            ? React.Children.map(children, (child, index) => (
                <div
                  className={`${Style.addon} ${
                    this.state.activeAddon === index ? Style.active : ''
                  }`}
                >
                  {child}
                </div>
              ))
            : null}
        </div>
        <div className={Style.list} ref={this.list}>
          {histories && histories.length
            ? histories.map((charging, index) => {
                return (
                  <div
                    key={`Link${index}`}
                    className={Style.charging}
                    style={{
                      animationDelay: `${charging.index * 0.1}s`,
                      backgroundColor:
                        get(styles, `${charging.id}.color`) || null,
                      display: !!get(styles, `${charging.id}.hidden`)
                        ? 'none'
                        : null,
                      borderLeftWidth: get(styles, `${charging.id}.color`)
                        ? null
                        : 0,
                    }}
                  >
                    <Charging
                      charging={{
                        ...charging,
                        started: charging.startedAt,
                        stopped: charging.endedAt,
                      }}
                      solar={solar}
                      solarBoost={solarBoost}
                      isHistoryItem
                      isAdmin={isAdmin}
                      isOpened={this.state.opened === charging.id}
                      onOpenChart={(e) => this.toggleChart(e, charging.id)}
                    />
                  </div>
                );
              })
            : null}
        </div>
        {this.props.isLoading ? <Loader /> : null}
      </div>
    );
  }
}

History.propTypes = {
  children: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  fetch: PropTypes.func,
  nextPage: PropTypes.number,
  isAdmin: PropTypes.bool,
  filter: PropTypes.object,
  resetHistory: PropTypes.func,
  solar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  solarBoost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsHistoryLoading,
  nextPage: selectHistoryNextPage,
  filter: selectHistoryFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (options) => dispatch(fetchHistoryStart(options)),
  resetHistory: () => dispatch(resetHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
