import { createSelector } from 'reselect';
import each from 'lodash/map';

export const selectForm = (state, { id }) => state.form[id];

export const selectLoadingState = createSelector(
  [selectForm],
  (form) => form.loading
);

export const selectErrors = createSelector(
  [selectForm],
  (form) => form.syncErrors || {}
);

export const selectAsyncErrors = createSelector(
  [selectForm],
  (form) => form.asyncErrors || {}
);

export const selectValues = createSelector(
  [selectForm],
  (form) => form.values || {}
);

export const selectValue = createSelector(
  [selectValues],
  (values) => (key) => values[key]
);

export const selectCustomErrors = createSelector(
  [selectForm],
  (form) => form.customErrors || {}
);

export const selectChangedState = createSelector([selectForm], (form) => {
  if (!form.anyTouched || (form.hasOwnProperty('initial') && !form.initial)) {
    return false;
  }

  if (form.hasOwnProperty('initial')) {
    let isChanged = false;

    each(form.values, (value, key) => {
      if (value !== form.initial[key]) {
        isChanged = true;
        return false;
      }
    });

    return isChanged;
  }

  return true;
});
