export const parseJWT = (jwt) => {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const AuthInHeader = true;

export const getAuthHeader = (token, asString = false) => {
  if (asString) {
    return AuthInHeader ? `Bearer ${token}` : '';
  }

  return AuthInHeader ? { Authorization: `Bearer ${token}` } : {};
};

export const getAuthBody = (token, asString = false) => {
  if (asString) {
    return !AuthInHeader ? `Bearer ${token}` : '';
  }

  return !AuthInHeader ? { Authorization: `Bearer ${token}` } : {};
};
