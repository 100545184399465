import ReduxFormActionTypes from 'redux-form/lib/actionTypes';

const FormActionTypes = {
  ...ReduxFormActionTypes,

  SUBMIT_START: 'FORM/SUBMIT_START',
  SUBMIT_SUCCESS: 'FORM/SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'FORM/SUBMIT_FAILURE',

  SET_FORM_ERROR: 'FORM/SET_FORM_ERROR',
};

export default FormActionTypes;
