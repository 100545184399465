import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
} from 'recharts';

import {
  chartColors,
  getDoc,
  isMobileWidth,
  colors,
  rangeLabels,
  moment,
} from '../../utils';

import { isEmpty, get } from 'lodash';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

export default class DeviceStatus extends CommonChart {
  container = 'deviceStatus';

  prepare(
    isActive = false,
    givenRange = null,
    givenZoomRange = null,
    isDataChanged = false
  ) {
    if (!isEmpty(this.props.data)) {
      const days = Object.keys(this.props.data);
      const filter = this.props.filter;
      const data = {};
      const labels = {};
      const labelValues = {};

      const range = 'day';
      days.forEach((day) => {
        // datum.time = moment(datum.timeStart).utc().valueOf();

        const name = moment(day).local();
        const label = moment(name).format('YYYY/MM/DD');

        labelValues[label] = translate(rangeLabels[range], {
          year: name.format('YYYY'),
          month: name.format('MMMM'),
          week: name.isoWeek(),
          quarter: name.quarter(),
          day: name.format('Do'),
        });

        labels[label] =
          labels[label] ||
          (filter[this.container] && filter[this.container][label] !== undefined
            ? filter[this.container][label]
            : false);

        data[label] = {
          name: label,
          exactName: label,
          highLightColor:
            chartColors[
              (Object.keys(labels).indexOf(label) + 4) % chartColors.length
            ],
          payload: getDoc(this.props.data[day]),
        };
      });

      const labelObjectValues = Object.values(labels);
      let selectedLabel = labelObjectValues.findIndex(Boolean);
      selectedLabel =
        selectedLabel > -1 ? selectedLabel : labelObjectValues.length - 1;
      const selectedLabelKey = Object.keys(labels)[selectedLabel];

      this.setState({
        data: data[selectedLabelKey],
        selectedLabel,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  render() {
    const { data } = this.state;
    const devices = data.payload || {};
    const fakeName = 'FAKE';
    const deviceNames = Object.keys(devices);
    const chartSizes = (0.85 / ((deviceNames.length || 0) + 1)) * 100;

    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">Devices statuses</LocalizedText>
            </h3>
            {deviceNames.concat(fakeName).map((deviceName) => {
              return (
                <ResponsiveContainer
                  key={`device-${deviceName}`}
                  className={Style.customHeightContainer}
                  height={`${deviceName === fakeName ? 15 : chartSizes}%`}
                >
                  <BarChart
                    syncId="statusCharts"
                    className={`${Style.mainChart} ${
                      deviceName === fakeName ? 'legend-chart' : ''
                    }`}
                    width="80%"
                    height="100%"
                    data={
                      deviceName === fakeName
                        ? []
                        : Object.values(devices[deviceName])
                    }
                    stackOffset="sign"
                    margin={{
                      top: 5,
                      right: 50,
                      left: 50,
                      bottom: 0,
                    }}
                    // onMouseMove={() => this.props.onHoverChart(this.container, this.state.data)}
                  >
                    {deviceName !== fakeName && <XAxis dataKey="label" />}
                    {deviceName !== fakeName && (
                      <YAxis
                        label={{
                          value: translate(deviceName),
                          position: 'center',
                          angle: -90,
                          fill: colors.ownWhite,
                        }}
                        hide={isMobileWidth()}
                        axisLine={false}
                        tickLine={false}
                        tick={false}
                        orientation="left"
                      />
                    )}
                    {deviceName !== fakeName && (
                      <Tooltip
                        hide
                        labelStyle={{
                          textAlign: 'left',
                          color: colors.backgroundGrey,
                        }}
                        labelFormatter={(label) =>
                          translate('Status {{label}}', { label })
                        }
                        itemStyle={{ textAlign: 'left' }}
                        content={
                          <CustomTooltip
                            prependPayload={[
                              {
                                validator: (payload) => {
                                  return get(payload, '0.payload.degree');
                                },
                              },
                            ]}
                          />
                        }
                        formatter={(value, name, givenPayload) => {
                          const { payload } = givenPayload;

                          const payloadValue =
                            get(payload, '0.payload.value') || payload.value;
                          const degree = get(payload, '0.payload.degree');
                          const label =
                            get(payload, '0.payload.customLabel') ||
                            payload.customLabel;
                          return [
                            degree ? degree : translate(payloadValue || 'N/A'),
                            translate(
                              degree ? 'temperature' : label || 'availability'
                            ),
                          ];
                        }}
                      />
                    )}
                    {deviceName === fakeName ? (
                      <Legend
                        iconSize={10}
                        layout="vertical"
                        verticalAlign="bottom"
                        wrapperStyle={{
                          display: 'inline-block',
                          margin: '1em auto auto',
                          fontWeight: 'bold',
                          fontSize: '1.2em',
                          cursor: 'pointer',
                        }}
                        payload={Object.entries(
                          this.state.labelValues || {}
                        ).map((entry, index) => ({
                          label: entry[1],
                          value: entry[0],
                          type: 'square',
                          color: chartColors[(index + 4) % chartColors.length],
                          container: this.container,
                        }))}
                        onClick={(o) =>
                          this.props.onClick(o, this.state.labels, true, false)
                        }
                        // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                        formatter={(value, entry) => {
                          const { color, container, label } = entry;
                          return (
                            <span
                              className={`${Style.legend} legendClick`}
                              style={{
                                color,
                                opacity: !this.props.isChecked(
                                  value,
                                  container,
                                  true,
                                  this.state.labelValues
                                )
                                  ? 0.3
                                  : 1,
                              }}
                            >
                              {label}
                            </span>
                          );
                        }}
                      />
                    ) : null}
                    {deviceName !== fakeName && (
                      <Bar dataKey="size" cursor="pointer" />
                    )}
                    {/* {deviceName === 'temperatureStatus' &&  <Line 
                    type="monotone" 
                    dataKey="degree" 
                    stroke={'white'} />} */}
                  </BarChart>
                </ResponsiveContainer>
              );
            })}
          </div>
        </div>
      )
    );
  }
}

DeviceStatus.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

DeviceStatus.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  chargings: PropTypes.array,
  onClick: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
};
