import React from 'react';
import PropTypes from 'prop-types';
import i18n, { getCurrentLocale, isFallbackLanguage } from '../i18n';
import { withI18n } from 'react-i18next';
import { pickBy } from 'lodash';
// import { Trans } from 'react-i18next';

function LocalizedText({ tag, children, variables = {}, ...props }) {
  if (tag) {
    return React.createElement(tag, {
      ...pickBy(props, (obj, key) => {
        return !['i18nOptions', 'defaultNS', 'reportNS', 't'].includes(key);
      }),
      children: translate(children, variables),
    });
  }

  //   return <Trans i18nKey={`[! ${children}]`} defaults={`[! ${children}]`}>{content}</Trans>;
  return translate(children, variables);
}

LocalizedText.propTypes = {
  children: PropTypes.any,
  tag: PropTypes.string,
  variables: PropTypes.object,
};

const addMissing = (translation, missing) => {
  if (!window.missingTranslations) {
    window.missingTranslations = {};
  }

  const locale = getCurrentLocale();

  if (!window.missingTranslations[locale]) {
    window.missingTranslations[locale] = {};
  }

  window.missingTranslations[locale][translation] = missing;

  clearTimeout(window.missingTranslationLog);
  window.missingTranslationLog = setTimeout(() => {
    // eslint-disable-next-line no-console
    console.warn(
      'There are some missing translations',
      window.missingTranslations
    );
  }, 100);
};

export const translate = (lng, options) => {
  const defaultValue = !isFallbackLanguage() ? `[! ${lng}]` : lng;
  const translation = i18n.t(lng, {
    defaultValue,
    ...options,
  });

  if (translation === defaultValue && !isFallbackLanguage()) {
    addMissing(lng, defaultValue);
  }

  return translation;
};

export default withI18n()(LocalizedText);
