import { call, all, put, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import FormActionTypes from './form.types';
import { submissionSuccess } from './form.actions';

function* submitToServer({ payload }) {
  try {
    yield payload.api(payload.values);

    yield put(submissionSuccess());
  } catch (error) {
    throw new SubmissionError({ _error: 'Some error message' });
  }
}

function* callSubmit(action) {
  yield call(submitToServer, action);
}

function* onFormSubmit() {
  yield takeEvery(FormActionTypes.SUBMIT_START, callSubmit);
}

export function* formSagas() {
  yield all([call(onFormSubmit)]);
}
