import React from 'react';
import PropTypes from 'prop-types';
import Style from './Common.module.scss';
import LocalizedText, { translate } from '../../components/LocalizedText';
import {
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ReferenceLine,
} from 'recharts';

import {
  chartColors,
  getDoc,
  isMobileWidth,
  colors,
  rangeLabels,
  moment,
  abbrPower,
} from '../../utils';

// import { range as lRange } from 'lodash';
import { CustomTooltip } from './CustomTooltip';
import CommonChart from './CommonChart';

export default class SolarPowerLine extends CommonChart {
  container = 'solarPower';

  prepare(isActive = false) {
    const dataValues = this.props.data ? Object.values(this.props.data) : [];
    if (dataValues.length) {
      const filter = this.props.filter;
      // const givenData = this.props.data.reduce((acc, stat) => {
      //   const datum = getDoc(stat);

      //   if(datum.data) {
      //     datum.time = moment(datum.day).utc().valueOf();
      //     datum.total = parseFloat(datum.max, 10) - parseFloat(datum.min, 10);

      //     return [...acc, datum];
      //   }

      //   return acc;
      // }, []);

      const data = {};
      const labels = {};
      const labelValues = {};

      // givenData.sort((a, b) => {
      //   if(a.time < b.time) {
      //     return -1;
      //   }
      //   if(a.time > b.time) {
      //     return 1;
      //   }
      //   return 0;
      // });

      const range = 'day';
      dataValues.forEach((stat) => {
        const datum = getDoc(stat);
        if (datum.data) {
          const name = moment(datum.day).local();
          const label = moment(name).format('YYYY/MM/DD');

          labelValues[label] = translate(rangeLabels[range], {
            year: name.format('YYYY'),
            month: name.format('MMMM'),
            week: name.isoWeek(),
            quarter: name.quarter(),
            day: name.format('Do'),
          });

          labels[label] =
            labels[label] ||
            (filter[this.container] &&
            filter[this.container][label] !== undefined
              ? filter[this.container][label]
              : false);

          data[label] = {
            name: label,
            exactName: label,
            highLightColor:
              chartColors[
                (Object.keys(labels).indexOf(label) + 4) % chartColors.length
              ],
            payload: datum.data ? Object.values(datum.data) : [],
            // lRange(60 * 24).map((min) => {
            //   const power = datum.data[min] && datum.data[min].power;
            //   return {
            //     time: name.startOf('day').add(min, 'minutes').local().format('HH:mm'),
            //     power: power > 0 ? parseFloat(power, 10) : null,
            //   };
            // }),
            hidden: true,
          };
        }
      });

      const labelObjectValues = Object.values(labels);
      let selectedLabel = labelObjectValues.findIndex(Boolean);
      selectedLabel =
        selectedLabel > -1 ? selectedLabel : labelObjectValues.length - 1;
      const selectedLabelKey = Object.keys(labels)[selectedLabel];
      this.setState({
        data: data[selectedLabelKey],
        selectedLabel,
        labels,
        labelValues,
        rendered: true,
      });
    }
  }

  render() {
    const data = this.state.data.payload;
    const color =
      chartColors[(this.state.selectedLabel + 4) % chartColors.length];
    const boostPower =
      this.props.boostPower > 0 ? parseFloat(this.props.boostPower, 10) : null;

    return (
      super.render() || (
        <div className={this.props.containerClassName}>
          <div ref={this.chart}>
            <h3 className={Style.chartTitle}>
              <LocalizedText tag="span">Actual green power</LocalizedText>
            </h3>
            <ResponsiveContainer className={Style.responsiveContainer}>
              <AreaChart
                className={Style.mainChart}
                width="80%"
                height="100%"
                data={data}
                stackOffset="sign"
                margin={{
                  top: 20,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                // onMouseMove={() => this.props.onHoverChart(this.container, this.state.data)}
              >
                <defs>
                  <linearGradient id="colorPower" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="50%" stopColor={color} stopOpacity={0.5} />
                    <stop offset="100%" stopColor={color} stopOpacity={0.2} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis
                  dataKey="power"
                  hide={isMobileWidth()}
                  tickFormatter={(value) => {
                    return abbrPower(value);
                  }}
                  domain={['dataMin', 'dataMax + 100']}
                  axisLine={false}
                  tickLine={false}
                  orientation="left"
                />
                <Tooltip
                  hide
                  labelStyle={{
                    textAlign: 'left',
                    color: colors.backgroundGrey,
                  }}
                  labelFormatter={(label) =>
                    translate('Actual green power {{label}}', { label })
                  }
                  itemStyle={{ textAlign: 'left' }}
                  content={<CustomTooltip />}
                  formatter={(value) => {
                    return [abbrPower(value), translate('Actual green power')];
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: 'inline-block',
                    margin: '1em auto auto',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    cursor: 'pointer',
                  }}
                  payload={Object.entries(this.state.labelValues || {}).map(
                    (entry, index) => ({
                      label: entry[1],
                      value: entry[0],
                      type: 'square',
                      color: chartColors[(index + 4) % chartColors.length],
                      container: this.container,
                    })
                  )}
                  onClick={(o) =>
                    this.props.onClick(o, this.state.labels, true, false)
                  }
                  // onMouseEnter={(o) => this.props.onHover(o, this.state.labels)}
                  formatter={(value, entry) => {
                    const { color, container, label } = entry;
                    return (
                      <span
                        className={`${Style.legend} legendClick`}
                        style={{
                          color,
                          opacity: !this.props.isChecked(
                            value,
                            container,
                            true,
                            this.state.labelValues
                          )
                            ? 0.3
                            : 1,
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                />
                <Area
                  strokeWidth={2}
                  type="monotone"
                  dataKey="power"
                  stroke={color}
                  fill="url(#colorPower)"
                  activeDot={{ r: 8, opacity: 1 }}
                />

                {boostPower ? (
                  <ReferenceLine
                    y={boostPower}
                    label={{
                      fill: colors.ownWhite,
                      stroke: colors.darkGrey,
                      strokeWidth: 3,
                      paintOrder: 'stroke',
                      value: translate('Green boost over {{value}}', {
                        value: abbrPower(boostPower),
                      }),
                    }}
                    strokeWidth={2}
                    strokeDasharray="10 10"
                    opacity={0.5}
                    stroke={chartColors[2]}
                  />
                ) : null}
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      )
    );
  }
}

SolarPowerLine.defaultProps = {
  data: [],
  onClick: () => {},
  onHover: () => {},
  onHoverChart: () => {},
  isChecked: () => false,
};

SolarPowerLine.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
  chargings: PropTypes.array,
  onClick: PropTypes.func,
  onHoverChart: PropTypes.func,
  isChecked: PropTypes.func,
  filter: PropTypes.object,
  boostPower: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
